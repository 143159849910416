















import { defineComponent, watch, ref } from '@vue/composition-api'
import { useCountTime } from '../hooks/useCountTime'
import { Progress } from "element-ui";
export default defineComponent({
    components: {
        [Progress.name]: Progress,
    },
    props: {
        time: {
            type: Number,
            default: 0
        },
        totalTime: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const countTime = useCountTime({ totalTime: props.time * 1000 })
        const countdown = countTime.countdown
        countTime.onStart()
        watch(
            () => props.time,
            (newTime) => {
                if (newTime > 0) {
                    if (Math.abs(newTime - countdown.value) > 1) {
                        countTime.initTime(newTime * 1000)
                    }
                }
            }
        )

        return { countdown }
    }
})
