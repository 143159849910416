import _ from 'lodash'


export const getPercentageVarietyStyle = (percentage: number) => {
    if (_.isNumber(percentage)) {
        if (percentage >= 100) {
            return `background: rgba(102, 228, 102)`
        } else if (percentage > 55) {
            return `background: rgba(102, 228, 102,0.${percentage})`
        } else if (percentage <= 55 && percentage >= 45) {
            return 'border: 1px solid #F2F1EF'
        } else if (percentage < 45) {
            return `background: rgba(237, 102, 102,0.${percentage < 10 ? 10 : percentage})`
        }
    }
    return `background: #F2F1EF`
}


export const getPercentageStyle = (percentage: number, color: string = '227,194,76') => {
    if (_.isNumber(percentage)) {
        if (percentage > 10 && percentage < 100) {
            return `background: rgba(${color},0.${percentage})`
        }
        if (percentage >= 100) {
            return `background: rgba(${color},1)`
        }
        return `background: rgba(${color},0.1)`
    }
    return `background: #F2F1EF`
}
