






















































import { defineComponent, inject, reactive, Ref, ref, set, nextTick } from '@vue/composition-api'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        SettingBox
    },
    setup(props, { refs }) {
        const isCollapse = ref(true)
        const setIsCollapse = ref(true)

        const itemObj: any = inject('itemObj')

        const addOption = (index?: number) => {
            const item = {
                text: ''
            }
            let location = 0
            if (index! >= 0) {
                location = index! + 1
                itemObj.message_entity.options.insert(location, item)
            } else {

                const options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                location = options.length
                itemObj.message_entity.options.insert(location, item)
            }
            nextTick(() => (refs.optionInputRef as any)[location].focus())
        }

        const onDeleteOption = async (item: any, index: number) => {
            if (!item.id) {
                itemObj.message_entity.options.splice(index, 1)
                return
            }
            try {
                itemObj.message_entity.options.splice(index, 1)
            } catch (error) {}
        }

        return {
            itemObj,
            isCollapse,
            setIsCollapse,
            addOption,
            onDeleteOption
        }
    }
})
