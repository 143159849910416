













































import { moveSectionApi } from '@/api/project'
import { showError } from '@/utils/common'
import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import Draggable from 'vuedraggable'

export default defineComponent({
    components: {
        Draggable
    },
    setup(props, { root, emit }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const sectionSortInfo = reactive({
            show: false,
            list: [] as any[],
            loading: false,
            onOpen: (list: []) => {
                sectionSortInfo.list = JSON.parse(JSON.stringify(list))
                sectionSortInfo.show = true
            },
            onStart: (e: any) => {
                console.log(e)
            },
            onEnd: (e: any) => {
                console.log(e)
            },
            onSave: async () => {
                if (sectionSortInfo.list.length <= 1) {
                    emit('success')
                    sectionSortInfo.show = false
                    return
                }
                const sortData: any = {}
                sectionSortInfo.list.forEach((item, index) => {
                    sortData[item.id] = index
                })
                sectionSortInfo.loading = true
                try {
                    await moveSectionApi({
                        uuid: projectUuId.value,
                        data: sortData
                    })
                    emit('success')
                    sectionSortInfo.show = false
                } catch (error) {
                    showError(error)
                }
                sectionSortInfo.loading = false
            }
        })
        return {
            ...toRefs(sectionSortInfo)
        }
    }
})
