



import { defineComponent, watch, ref } from '@vue/composition-api'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { verifyMessageEntity } from '@/utils/verify-message-entity'

export default defineComponent({
    components: {
        ErrorMessage
    },
    props: {
        itemObj: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props, { emit }) {
        const errorMessage = ref('')
        watch(
            () => props.itemObj,
            () => {
                errorMessage.value = verifyMessageEntity(props.itemObj)
                emit('errorMessage', errorMessage.value)
            },
            {
                deep: true
            }
        )
        errorMessage.value = verifyMessageEntity(props.itemObj)
        return {
            errorMessage
        }
    }
})
