






































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi } from '@/api/project'
import { OrderType } from '@/api/project/model'
import { getPercentage } from '@/utils/common'
import { computed, defineComponent, inject, onMounted, reactive, ref, set, watch } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { isNil } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { root, emit }) {
        const showDrawer = ref(props.value)
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )
        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)

        const segments: any = inject('segments')
        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        watch(
            () => {
                return props.item
            },
            (value, old) => {
                analyzeObj.segments.forEach((segment: any) => {
                    setCollection(segment)
                })
                analyzeObj.orderObj.refreshOrder()
            }
        )

        const analyzeObj: any = reactive({
            loading: false,
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                participants: 0,
                orderType: OrderType.None,
                categorys: [] as any[],
                collection: props.item?.collection
            },
            categorys: computed(() => {
                return props.item?.message?.message_options ?? []
            }),
            selecteds: [] as any[],
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            segments: [] as any[],
            orderObj: {
                curOrderSegments: {},
                setType: (index: number, orderType: OrderType) => {
                    set(analyzeObj.orderObj.curOrderSegments, index, orderType)
                },
                getType: (index: number) => {
                    let orderType = analyzeObj.orderObj.curOrderSegments[index] ?? OrderType.None
                    analyzeObj.orderObj.curOrderSegments[index] = orderType
                    return orderType
                },
                clearAllOrder: () => {
                    analyzeObj.curOrderSegments = {}
                    analyzeObj.orderObj.refreshOrder()
                },
                changeOrder: (index: number, orderType?: OrderType) => {
                    if (isNil(index)) {
                        return
                    }
                    const type = analyzeObj.orderObj.getType(index)

                    if (type == OrderType.None) {
                        orderType = OrderType.DESC
                    } else if (type == OrderType.DESC) {
                        orderType = OrderType.ASC
                    } else if (type == OrderType.ASC) {
                        orderType = OrderType.None
                    }
                    analyzeObj.orderObj.setType(index, orderType)
                    console.log(`orderType: ${analyzeObj.orderObj.getType(index)}`)
                    analyzeObj.orderObj.curOrderSegments = { ...analyzeObj.orderObj.curOrderSegments }
                },
                refreshOrder: () => {
                    analyzeObj.segments.forEach((segment: any) => {
                        analyzeObj.orderObj.setType(segment, analyzeObj.orderObj.getType(segment))
                    })
                }
            },
            collapseObj: {
                collapseMap: {},
                isCollapse: (op: any, segIndex: number) => {
                    return analyzeObj.collapseObj.collapseMap[`${segIndex}-${op.id}`] ?? false
                },
                handleChange: (op: any, segIndex: number) => {
                    set(analyzeObj.collapseObj.collapseMap, `${segIndex}-${op.id}`, !analyzeObj.collapseObj.isCollapse(op, segIndex))
                }
            },
            getCategorys: (segment: any, index: number = 0) => {
                let originCategorys: any[] = []
                if (!segment.collection) {
                    return originCategorys
                }
                const categoryObj = segment.collection.result?.category

                if (categoryObj) {
                    originCategorys = Object.values(categoryObj).filter((category: any) => {
                        return analyzeObj.selecteds.includes(category.id)
                    })
                    originCategorys.forEach((category: any) => {
                        category.key = uuidv4()
                        category.percentage = getPercentage(category.participants, analyzeObj.getParticipants(segment))
                    })

                    const orderType = analyzeObj.orderObj.getType(index)
                    switch (orderType) {
                        case OrderType.None:
                            originCategorys.sort((a: any, b: any) => {
                                return a.sequence - b.sequence
                            })
                            break
                        case OrderType.DESC:
                            originCategorys.sort((a: any, b: any) => {
                                if (a.participants === b.participants) {
                                    return a.sequence - b.sequence
                                } else {
                                    return b.participants - a.participants
                                }
                            })
                            break
                        case OrderType.ASC:
                            originCategorys.sort((a: any, b: any) => {
                                if (a.participants === b.participants) {
                                    return a.sequence - b.sequence
                                } else {
                                    return a.participants - b.participants
                                }
                            })
                            break
                    }
                }
                return originCategorys
            },
            getParticipants: (segment: any) => {
                if (!segment?.collection) {
                    return 0
                }
                return segment.collection.result.participants
            },
            handleSelect: (option: any) => {

                if (analyzeObj.selecteds.includes(option.id)) {
                    analyzeObj.selecteds = analyzeObj.selecteds.filter((id: any) => {
                        return id !== option.id
                    })
                } else {
                    analyzeObj.selecteds.push(option.id)
                }
            }
        })

        onMounted(() => {
            console.log(`onMounted：初始化`)

            analyzeObj.segments = analyzeObj.originSegments.slice(0, 2)
            if (analyzeObj.segments.length < 2) {
                const diff = 2 - analyzeObj.segments.length
                for (let i = 0; i < diff; i++) {
                    analyzeObj.segments.push({
                        name: '--',
                        orderType: OrderType.None,
                        collection: null
                    })
                }
            }
            analyzeObj.segments.forEach((segment: any) => {
                setCollection(segment)
            })

            analyzeObj.selecteds = analyzeObj.categorys.map((category: any) => {
                return category.id
            })
            console.log(analyzeObj.selecteds)
        })

        const setCollection = async (item: any) => {
            if (item.id == 0) {
                item.collection = props.item?.collection
            } else if (item.id > 0) {
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: item.id,
                    driver: driver.value,
                    observer: observer
                })
                if (res.length > 0) {
                    item.collection = res[0].collection
                }
            } else if (item.id == -1) {
                if (item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res: any = await getMessageOptionsAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        mid: props.item?.message.message_identifier,
                        options: optionIds,
                        driver: driver.value,
                        observer: observer
                    })
                    item.collection = res[0].collection
                } else {
                    item.collection = null
                }
            }
        }


        const handleCommand = async (item: any) => {

            const segmentItem = analyzeObj.originSegments.find((segment: any) => {
                return segment.id === item.segment.id
            })
            const segment = JSON.parse(JSON.stringify(segmentItem))
            analyzeObj.segments.splice(item.index, 1, segment)
            await setCollection(segment)
        }

        return {
            OrderType,
            showDrawer,
            showFilters,
            analyzeObj,
            handleCommand,
            onClosed
        }
    }
})
