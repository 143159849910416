























































































































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi } from '@/api/project'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentage } from '@/utils/common'
import { computed, defineComponent,nextTick, inject, onMounted, onUnmounted, reactive, ref, watch } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem, Progress, Table,
  TableColumn, } from 'element-ui'
import { isEqual, cloneDeep } from 'lodash'
import Filters from './components/Filters.vue'
import  CommonTooltip  from "./components/CommonTooltip.vue";
export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Progress.name]: Progress,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        Filters,
        CommonTooltip
    },
    props: {
        value: {
            type: Boolean,
            default: true
        },
        item: {
            type: Object
        }
    },
    setup(props, { root, emit }) {
        const showDrawer = ref(props.value)
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )
        const tooltipRef=ref()
        const handleDropdownVisibleChange=(visible:any)=> {
          if (visible) {
            nextTick(() => {
              // 确保 CommonTooltip 已经渲染且可见
              if (tooltipRef.value) {
                const tooltipElement =tooltipRef.value; // 假设 CommonTooltip 传递了 ref 到内部元素
                tooltipRef.value.map((item:any)=>{
                  item.checkOverflowAndHandle()
                })
              }
            });
          }
      };
        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)
        const isCollapse = ref(true)

        const segments: any = inject('segments')

        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        const projectStore = useProjectStore()
        const { getAnalyzeSegments, updateAnalyzeSegments, getFilterSegments, updateFilterSegments } = projectStore


        watch(
            () => {
                return props.item
            },
            (value, old) => {
                analyzeObj.loading = !isEqual(value, old)
                analyzeObj.segments.forEach((segment: any) => {
                    setCollection(segment)
                })
            }
        )

        const getRankScore: any = inject('getRankScore')

        const analyzeObj: any = reactive({
            loading: true,
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                collection: props.item?.collection
            },
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            options: [] as any[],
            segments: [] as any[],
            getParticipants: (item: any) => {
                if (!item?.collection) {
                    return 0
                }
                return item.collection.result.participants
            },
            getOptions: (segment: any) => {
                if (!segment.collection) {
                    return []
                }
                const options: any[] = []
                const ops = segment.collection.result.ops
                const keys = Object.keys(ops)
                keys.forEach((key: string) => {
                    const op = ops[key]
                    options.push({
                        id: op.id,
                        text: op.text,
                        sequence: op.sequence,
                        score: getRankScore(ops, key),
                        votes: op.votes
                    })
                })
                options.sort((a: any, b: any) => {
                    return b.score - a.score
                })
                return options
            },
            getVoteParticipants: (votes: any[], rank: number) => {
                if (!votes) {
                    return 0
                }
                return votes.filter((vote: any) => {
                    return vote.rank == rank
                }).length
            },
            getVotePercentage: (votes: any[], rank: number) => {
                let num = 0
                let total = 0
                if (votes && votes.length > 0) {
                    num = votes.filter((vote: any) => {
                        return vote.rank == rank
                    }).length
                    total = votes.length
                }
                return getPercentage(num, total)
            },
            filterSegment: {
                formIndex: 0,
                segments: [] as any[],
                curFilter: computed(() => {
                    if (analyzeObj.filterSegment.segments.length == 0) {
                        return null
                    }
                    return analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    console.log(`handleChange`)

                    analyzeObj.filterSegment.curFilter.selected = options
                    analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(options)
                    await setCollection(analyzeObj.filterSegment.curFilter)
                    console.log(analyzeObj.filterSegment.curFilter)
                },
                reset: async () => {
                    console.log(`reset`)
                    analyzeObj.filterSegment.curFilter.selected = []
                    analyzeObj.filterSegment.curFilter.name = '--'
                    setCollection(analyzeObj.filterSegment.curFilter)
                }
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            }
        })

        onMounted(() => {
            initData()
        })

        onUnmounted(() => {
            updateAnalyzeSegments(props.item?.message.project_uuid, analyzeObj.segments)
            updateFilterSegments(props.item?.message.project_uuid, analyzeObj.filterSegment.segments)
        })

        const initData = () => {
            const segs = getAnalyzeSegments(props.item?.message.project_uuid)
            analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 3)
             analyzeObj.options = props.item?.message.message_options.map((option: any) => {
                return {
                    id: option.id,
                    text: option.text,
                    link: option.link,
                    sequence: option.sequence
                }
            })

            if (analyzeObj.segments.length < 3) {
                const diff = 3 - analyzeObj.segments.length
                for (let i = 0; i < diff; i++) {
                    analyzeObj.segments.push({
                        name: '--',
                        collection: null
                    })
                }
            }

            analyzeObj.originSegments.forEach((segmentOrigin:any) => {
              let segment = analyzeObj.segments.find((s:any)=> s.oldId == segmentOrigin.oldId);
              if (!segment) {
                  // segment = segmentOrigin;
              } else {
                if(segment.id>-1){
                  segment.name =  analyzeObj.originSegments.filter((item:any)=> segment.id==item.id)[0].name;
                }
              }
            });

            const filterSegments = getFilterSegments(props.item?.message.project_uuid)
            analyzeObj.filterSegment.segments = filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : []
            if (analyzeObj.filterSegment.segments.length < 3) {
                for (let i = 0; i < 3; i++) {
                    analyzeObj.filterSegment.segments.push({
                        id: -1 - i,
                        name: '--',
                        selected: [],
                        collection: null
                    })
                }
            }

            const ids = segments.value.map((item: any) => item.id) ?? []
            console.log(`样本分组IDs: ${ids}`)

            analyzeObj.segments.forEach((segment: any) => {
                if (segment.id > 0 && !ids.includes(segment.id)) {
                    segment.name = '--'
                    segment.id = null
                    segment.collection = null
                    segment.selected = []
                }
                setCollection(segment)
            })
            console.log(analyzeObj,'analyzeObjanalyzeObj')
        }

        const setCollection = async (item: any) => {
            if (item.id == 0) {
                item.collection = props.item?.collection
            } else if (item.id > 0) {
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: item.id,
                    driver: driver.value,
                    observer: observer
                })
                if (res.length > 0) {
                    item.collection = res[0].collection
                }
            } else if (item.id < 0) {
                if (item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res: any = await getMessageOptionsAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        mid: props.item?.message.message_identifier,
                        options: optionIds,
                        driver: driver.value,
                        observer: observer
                    })
                    item.collection = res[0].collection
                } else {
                    item.collection = null
                }
            }
        }

        const handleCommand = async (item: any) => {

            if (!item.segment) {
                const segment = analyzeObj.filterSegment.segments[item.index]
                analyzeObj.segments[item.index] = segment
                analyzeObj.filterSegment.formIndex = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                showFilters.value = true
                await setCollection(analyzeObj.segments[item.index])


            } else {
                showFilters.value = false
                let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                    return segment.id === item.segment.id
                })
                if (segmentItem) {
                    await setCollection(segmentItem)
                    item.index = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                    analyzeObj.segments.splice(item.index, 1, JSON.parse(JSON.stringify(segmentItem)))
                }
            }
        }
        return {
            showDrawer,
            showFilters,
            isCollapse,
            analyzeObj,
            handleCommand,
            onClosed,
            handleDropdownVisibleChange,
            tooltipRef
        }
    }
})
