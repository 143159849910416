














































































import { getConversationsApi, getMessageOptionsAnalyzeApi, getOnboardingPollsApi, getSegmentJoinsByOptionsApi, updateOrCreateSegmentApi } from '@/api/project'
import { EntityType, UpdateOrCreateSegmentDto } from '@/api/project/model'
import { showError } from '@/utils/common'
import { computed, defineComponent, reactive, watch } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'

export default defineComponent({
    setup(props, { root, emit }) {
        const projectStore = useProjectStore()
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const optionObj = reactive({
            isPollsCollapse: true,
            polls: [] as any[],
            isConversationsCollapse: true,
            conversations: [] as any[]
        })

        const getOnboardingPolls = async () => {
            try {
                const { polls } = await getOnboardingPollsApi({
                    uuid: projectUuId.value
                })
                optionObj.polls = polls.filter((item: any) => [EntityType.onboarding_poll, EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi].includes(item.message_entity.entity_type))
            } catch (error) {
                showError(error)
            }
        }


        const getConversations = async () => {
            optionObj.conversations = []
            try {
                const { sections } = await getConversationsApi({
                    uuid: projectUuId.value
                })
                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if ([EntityType.poll, EntityType.picture_poll, EntityType.poll_multi, EntityType.picture_poll_multi].includes(item.message_entity.entity_type)) {
                            optionObj.conversations.push(item)
                        }
                    })
                })
            } catch (error) {
                showError(error)
            }
        }

        const initData = async () => {
            getOnboardingPolls()
            getConversations()
        }

        const segmentObj = reactive({
            allOptions: computed(() => {
                const options = [] as any[]
                optionObj.polls.forEach((item: any) => {
                    options.push(...item.message_entity.options)
                })
                optionObj.conversations.forEach((item: any) => {
                    options.push(...item.message_entity.options)
                })
                return options
            }),
            curSegmentObj: undefined as any,
            checkedStr: computed(() => {
                const checkedArr: any = segmentObj.allOptions
                    .filter((item: any) => {
                        const res = segmentObj.curSegmentObj.options.some((option: any) => {
                            return option.message_option_id === item.id
                        })
                        return res
                    })
                    .map((item: any) => item.text)
                return checkedArr.join('，')
            }),
            isChecked: (option: any) => {

                return segmentObj.curSegmentObj.options.some((item: any) => {
                    return item.message_option_id === option.id
                })
            },
            changeOption: async (item: any, option: any) => {


                if (segmentObj.isChecked(option)) {
                    const allOptionsIds = segmentObj.allOptions.map((item: any) => item.id)
                    segmentObj.curSegmentObj.options = segmentObj.curSegmentObj.options.filter((item: any) => {
                        return item.message_option_id !== option.id && allOptionsIds.includes(item.message_option_id)
                    })
                } else {

                    segmentObj.curSegmentObj.options.push({
                        message_option_id: option.id,
                        message_entity_id: option.message_entity_id
                    })
                }

                if (segmentObj.curSegmentObj.options.length > 0) {
                    const options: any[] = segmentObj.curSegmentObj.options.map((option: any) => {
                        return {
                            message_option_id: option.message_option_id,
                            message_entity_id: option.message_entity_id
                        }
                    })
                    try {
                        const res: any = await getSegmentJoinsByOptionsApi({
                            uuid: projectUuId.value,
                            options: options
                        })
                        segmentObj.curSegmentObj.join_people = res
                    } catch (error) {
                        showError(error)
                    }
                } else {
                    segmentObj.curSegmentObj.join_people = 0
                }
            },
            submitLoad: false,
            updateOrCreateSegment: async () => {

                const segment: UpdateOrCreateSegmentDto = {
                    uuid: projectUuId.value,
                    id: segmentObj.curSegmentObj.id,
                    name: segmentObj.curSegmentObj.name,
                    options: segmentObj.curSegmentObj.options.map((item: any) => {
                        return {
                            id: item.id,
                            segment_id: Number(segmentObj.curSegmentObj.id),
                            message_entity_id: Number(item.message_entity_id),
                            message_option_id: Number(item.message_option_id)
                        }
                    })
                }
                segmentObj.submitLoad = true
                try {
                    const data = await updateOrCreateSegmentApi(segment)
                    console.log(data)

                    root.$message.success(root.$t('saveSuccess').toString())
                    segmentObj.curSegmentObj = null
                    projectStore.updateProjectSegments(projectUuId.value)
                } catch (error) {
                    showError(error)
                }
                segmentObj.submitLoad = false
                emit('update')
            },
            edit: (item: any) => {
                initData()
                segmentObj.curSegmentObj = item
            },
            add: () => {
                initData()
                segmentObj.curSegmentObj = {
                    name: '',
                    options: [],
                    join_people: 0
                }
            },
            cancel: () => {
                segmentObj.curSegmentObj = null
                emit('cancel')
            }
        })

        const handleInput = (value: any) => {
            if (value.includes('*') || value.includes('_')) {
                segmentObj.curSegmentObj.name = value.replace(/[\*_]/g, '');
            } else {
                segmentObj.curSegmentObj.name = value;
            }
        }
        return {
            optionObj,
            segmentObj,
            handleInput
        }
    }
})
