











































































































































































































import { Alert, Checkbox } from 'element-ui'
import Draggable from 'vuedraggable'
import FormSessionDialogWrap from '@/components/form-common-edit-dialog/FormSessionDialogWrap.vue'
import { showError } from '@/utils/common'
import { computed, defineComponent, nextTick, onMounted, onUnmounted, provide, reactive, Ref, ref, set, toRef, unref, watch } from '@vue/composition-api'
import ShowFormWrap from '@/components/form-common-show/ShowFormWrap.vue'
import { ConversationStatusType, EntityType, ProjectFormType, ProjectStatus } from '@/api/project/model'
import ProjectLiveTalk from '@/views/project/components/ProjectLiveTalk.vue'
import { useScrollTo } from '@/hooks/useScrollTo'
import { useSetInterval } from '@/hooks/useSetInterval'
import ProjectSharePopover from '@/views/project/components/ProjectSharePopover.vue'
import ProjectGroupDrawer from '@/views/project/components/projectGroup/ProjectGroupDrawer.vue'
import InputAlter from '@/components/InputAlter.vue'
import ProjectSectionSort from '@/views/project/components/ProjectSectionSort.vue'
import ProjectItemMove from '@/views/project/components/ProjectItemMove.vue'
import ProjectTalkLoading from '@/views/project/components/ProjectTalkLoading.vue'
import ProjectSurveyError from '@/views/project/components/ProjectSurveyError.vue'
import { useProjectStore } from '@/pinia/modules/project'
import ProjectCountdown from '@/views/project/components/ProjectCountdown.vue'
import ProjectRespondents from '@/views/project/components/ProjectRespondents.vue'
import CountTo from 'vue-count-to'
import { storeToRefs } from 'pinia'
import { getShareInfoApi, getLiveOnboardingPollsApi, getLiveConversationsApi, getLiveConversationMessagesApi ,getLiveConversationRespondentsApi} from '@/api/observer'
import TitleTooltip from '@/components/TitleTooltip.vue'
import _ from 'lodash'
import { timeFormat } from '@/utils/time-format'
import workerTimer from '@/utils/worker-timer'
import ProjectDataDerive from '@/views/project/components/ProjectDataDerive.vue'
import { ARTClient, ARTEvent, ARTNotifyEventType, LoginGuard, MessageBody } from '@/services/sdk'
import LocaleSelect from '@/components/LocaleSelect.vue'
import NewMessagesBadge from '@/components/NewMessagesBadge.vue'
import VirtualScrollList from 'vue-virtual-scroll-list';
export enum LiveStatus {
    Pedding = 0,
    Ready = 1,
    Close = 2
}

export default defineComponent({
    components: {
        [Checkbox.name]: Checkbox,
        [Alert.name]: Alert,
        ShowFormWrap,
        ProjectSharePopover,
        ProjectGroupDrawer,
        InputAlter,
        ProjectSectionSort,
        ProjectItemMove,
        ProjectTalkLoading,
        FormSessionDialogWrap,
        Draggable,
        ProjectSurveyError,
        ProjectCountdown,
        ProjectRespondents,
        CountTo,
        TitleTooltip,
        ProjectDataDerive,
        LocaleSelect,
        NewMessagesBadge,
        VirtualScrollList
    },
    setup(props, { root, refs }) {

        provide('observer', true)
        const { timerStart, timerClear } = useSetInterval()

        const shareUuid = computed(() => root.$route.params.shareUuid)
        const shareObj = reactive({
            id: '',
            projectUuId: '',
            viewGuide: false,
            dataExport: false,
            keyword: false,
            nlp: false,
            ai:false,
            token: '',
            status: '',
            liveStatus: LiveStatus.Pedding,
            refuse: false
        })

        provide('observerInfo', {
            isObserver: true,
            shareInfo: shareObj
        })

        const projectUuId = computed(() => shareObj?.projectUuId)

        const projectInfo: any = reactive({
            status: ProjectStatus.None,
            online_number: 0,
            unsend_message_count: 0,
            last_message_updated_at: '',
            detail_title: ''
        })
        const projectStore = useProjectStore()

        const { dataDriver } = storeToRefs(projectStore)
        provide('driver', dataDriver)

        const head = computed(() => projectInfo.detail_moderator_cover || root.$defaultAvatar)
        provide('head', head)

        const dataDeriveInfo = reactive({
            show: false
        })

        const scrollTalkRef = ref()
        // const scrollTalkInfo = reactive({
        //     scrollTo: async (to: number, duration = 500) => {
        //         nextTick(() => {
        //             const scrollbar = unref(scrollTalkRef)
        //             if (!scrollbar) {
        //                 return
        //             }
        //             const { start } = useScrollTo({
        //                 el: scrollbar,
        //                 to,
        //                 duration
        //             })
        //             start()
        //         })
        //     },
        //     scrollBottom: () => {
        //         nextTick(() => {
        //             const scrollbar = unref(scrollTalkRef)
        //             if (scrollbar) {
        //                 const scrollHeight = scrollbar.scrollHeight
        //                 scrollTalkInfo.scrollTo(scrollHeight, 1000)
        //             }
        //         })
        //     },
        //     scrollLocation: (item: any) => {
        //         const location = document.getElementById(`talk-${item.message_entity_id}`) as any
        //         if (location) {
        //             const { offsetTop, offsetParent } = location
        //             scrollTalkInfo.scrollTo(offsetTop - 80)
        //             const className = location.getElementsByClassName('wrap-f-r')[0]
        //             className.classList.add('talk-bg-color-animation')
        //             setTimeout(() => className.classList.remove('talk-bg-color-animation'), 2000)
        //         }
        //     }
        // })

        const scrollTalkInfo = reactive({
            scrollTo: async (to: number, duration = 500) => {
                nextTick(() => {
                    const scrollbar = unref(scrollTalkRef)
                    if (!scrollbar) {
                        return
                    }
                    const { start } = useScrollTo({
                        el:  document.getElementById('talkScroll'),
                        to,
                        duration
                    })
                    start()
                })
            },
            isBottom: () => {
                
                nextTick(() => {

                    return scrollTalkRef.value.scrollHeight - scrollTalkRef.value.scrollTop === scrollTalkRef.value.clientHeight
                })
            },
            scrollBottom: () => {
                nextTick(() => {
                    // const scrollbar = unref(scrollTalkRef)
                    
                    if (scrollTalkRef.value) {
                        scrollTalkRef.value.scrollToBottom()
                        // const scrollHeight = scrollbar.scrollHeight
                        // setTimeout(() => {
                            // scrollTalkRef.value.scrollToIndex(liveConversationsTotal.value - 1)
                        // },1)
                        // scrollTalkInfo.scrollTo(scrollHeight, 500)
                    }
                })
            },
            scrollLocation: (item: any) => {
                const location = document.getElementById(`talk-${item.message_entity_id}`) as any
                if (location) {
                    const { offsetTop, offsetParent } = location
                    scrollTalkInfo.scrollTo(offsetTop - 80)
                    const className = location.getElementsByClassName('wrap-f-r')[0]
                    className.classList.add('talk-bg-color-animation')
                    setTimeout(() => className.classList.remove('talk-bg-color-animation'), 1000)
                }
            },
            scrollLocationPoll: (item: any) => {
                let index=0
                formattedConversationList.value.map((items:any,indexs:any)=>{
                    if(items.message.message_entity_id==item.message_entity_id){
                        index=indexs
                    }
                })
                if(scrollTalkRef.value){
                    scrollTalkRef.value.scrollToIndex(index)
                }
            }
        })

        const scrollFormRef = ref()
        const scrollFormInfo = reactive({
            scrollTo: async (to: number, duration = 500) => {
                nextTick(() => {
                    const scrollbar = unref(scrollFormRef)
                    if (!scrollbar) {
                        return
                    }
                    const { start } = useScrollTo({
                        el: scrollbar,
                        to,
                        duration
                    })
                    start()
                })
            },
            next: () => {
                const { sections } = conversationObj
                let nextItem: any
                for (let index = 0; index < sections.length; index++) {
                    const element = sections[index]
                    nextItem = element.items.find((v: any) => v.status === ConversationStatusType.PENDING)
                    if (nextItem) {
                        element.isCollapse = true
                        break
                    }
                }
                if (nextItem) {
                    nextTick(() => {
                        const location = document.getElementById(`form-${nextItem.id}`)
                        if (location) {
                            const { offsetTop, offsetParent } = location as any
                            if (offsetTop && offsetParent?.offsetTop) {
                                scrollFormInfo.scrollTo(offsetParent.offsetTop + offsetTop)
                                location.classList.add('form-bg-color-animation')
                                setTimeout(() => location.classList.remove('form-bg-color-animation'), 2000)
                            }
                        }
                    })
                }
            }
        })

        const outlineObj = reactive({
            isCollapse: true
        })

        const baseObj: any = reactive({
            isCollapse: true,
            id: 0,
            polls: [] as any[],
            className: computed(() => {
                if ([ProjectStatus.Start, ProjectStatus.Published, ProjectStatus.End].includes(projectInfo.status)) {
                    return 'disabled'
                } else {
                    return 'active'
                }
            })
        })
        provide('baseObj', baseObj)

        const messageObj = reactive({
            isDisabled: computed(() => [ProjectStatus.End].includes(projectInfo.value.status)),
            className: (itemObj: any) => {

                if (ProjectStatus.End === projectInfo.status) {
                    return 'disabled'
                } else if ([ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.status)) {
                    if (liveObj.timeInfo.isCollecting) {
                        if (
                            liveObj.lastMessageEntity &&
                            liveObj.lastMessageEntity.message_entity_id == itemObj.message_entity.id &&
                            ![EntityType.image, EntityType.video, EntityType.speak].includes(itemObj.message_entity.entity_type)
                        ) {
                            return 'collect'
                        }
                    }
                    if (itemObj.status === ConversationStatusType.SENDED) {
                        return 'disabled'
                    } else {
                        return 'active'
                    }
                } else {
                    return 'active'
                }
            }
        })

        const formattedConversationList = computed(() => {
            if (!liveObj) {
                return [];
            }

            const pollList = liveObj.pollList?.map((item: any) => ({
                ...item,
                id: item.message.message_identifier, // 提取 message.id 并添加到每个对象中
            })) || [];

            const conversationList = liveObj.conversationList?.map((item: any) => ({
                ...item,
                id: item.message.message_identifier, // 提取 message.id 并添加到每个对象中
            })) || [];

            return [...pollList, ...conversationList];
        });


        const getOnboardingPolls = async () => {
            try {
                const { id, polls } = await getLiveOnboardingPollsApi({
                    uuid: projectUuId.value
                })
                baseObj.id = id
                baseObj.polls = polls
            } catch (error) {
                showError(error)
            }
        }

        const getConversations = async () => {
            try {
                const { id, sections } = await getLiveConversationsApi({
                    uuid: projectUuId.value
                })
                conversationObj.id = id
                sections.forEach((item: any) => {
                    const oldItem = conversationObj.sections.find((v) => v.id == item.id)
                    item.isCollapse = oldItem ? oldItem.isCollapse : true
                })
                conversationObj.sections = sections
                statusTypeObj.refreshConversationList()
            } catch (error) {
                showError(error)
            }
        }


        const conversationObj = reactive({
            isCollapse: true,
            id: 0,
            totalNum: computed(() => {
                const { sections } = conversationObj
                let total = 0
                sections.forEach((section: any) => {
                    total += section.items.length
                })
                return total
            }),
            hasBranchIds: computed(() => {
                const { sections } = conversationObj
                const hasBranchIds: any[] = []
                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity?.parent_id) {
                            hasBranchIds.push(item.message_entity.parent_id)
                        }
                    })
                })
                return hasBranchIds
            }),
            sections: [] as any[],
            sectionList: [] as any[],
            getParentItem: (parentId: number) => {
                const { polls } = baseObj
                const { sections } = conversationObj
                let parentItem

                polls.forEach((poll: any) => {
                    if (poll.message_entity.id === parentId) {
                        parentItem = poll
                    }
                })

                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity?.id === parentId) {
                            parentItem = item
                        }
                    })
                })
                return parentItem
            }
        })
        provide('conversationObj', conversationObj)


        const statusTypeObj = reactive({
            statusTypeList: [] as ConversationStatusType[],
            isChecked: (item: any) => {
                return statusTypeObj.statusTypeList.includes(item)
            },
            handleClick: (item: any) => {
                if (statusTypeObj.statusTypeList.includes(item)) {
                    statusTypeObj.statusTypeList = statusTypeObj.statusTypeList.filter((i) => i !== item)
                } else {
                    statusTypeObj.statusTypeList.push(item)
                }
            },
            refreshConversationList: () => {

                if (statusTypeObj.statusTypeList.length === 0) {
                    conversationObj.sectionList = conversationObj.sections
                } else {
                    const sectionList = [] as any[]
                    conversationObj.sections.forEach((section: any) => {
                        const items = section.items.filter((item: any) => {
                            if (statusTypeObj.statusTypeList.length == 0) {
                                return true
                            } else {
                                return statusTypeObj.statusTypeList.includes(item.status)
                            }
                        })
                        const newSection = {
                            ...section,
                            items
                        }
                        sectionList.push(newSection)
                    })
                    conversationObj.sectionList = sectionList
                }
                conversationObj.sectionList.forEach((section: any) => {
                        section.items.forEach((item: any) => {
                            if(item.message_entity.entity_type=='video'||item.message_entity.entity_type=='image'){
                               const options= item?.message_entity.options.filter((option:any)=>item.message_entity.link==option.link)
                                if( item.message_entity.link!=''&&item.message_entity.link!=null&&options.length==0){
                                    item?.message_entity.options.unshift({
                                    created_at:  item.message_entity.created_at,
                                    deleted_at: null,
                                    id:  null,
                                    is_show: true,
                                    is_system: false,
                                    link: item.message_entity.link,
                                    message_entity_id: item.message_entity.id,
                                    option_type: "",
                                    sequence: 0,
                                    text: item.message_entity.entity_type=='video'?`${root.$i18n.t('project.video')}1`:`${root.$i18n.t('project.image')}1`,
                                    updated_at: null,
                                    })
                                    
                                }
                            }
                        })
                    })
            }
        })

        watch(
            () => statusTypeObj.statusTypeList,
            () => statusTypeObj.refreshConversationList()
        )

        const talkCreateBranch = (itemObj: any) => {
            let itemInfo = conversationObj.getParentItem(itemObj.message_entity_id)
            // if (itemInfo) {
            //     editSessionObj.onOpen(itemInfo, 'create', EntityType.branch)
            // }
        }
        
        const segments: Ref<any[]> = ref([])
        provide('segments', segments)
        watch(
            () => shareObj.projectUuId,
            async (newVal) => {
                await projectStore.getProjectSegments(newVal, true)
                const segs = toRef(projectStore.segment, newVal)
                segments.value = segs.value
            }
        )

        watch(
            () => projectInfo.online_number,
            (newVal) => {
                if (!projectTalkLoading.value) {

                    liveObj.countTo.change(newVal)
                }
                console.log(`人数更新：${newVal}`)
            }
        )

        watch(
            () => projectInfo.status,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    console.log(`项目状态更新：${newVal}`)
                    initTimers()
                }
            }
        )

        watch(
            () => projectInfo.last_message_updated_at,
            (newVal) => {
                console.log(`last_message_updated_at: ${newVal}`)

                if (!projectTalkLoading.value) {
                    console.log(`last_message_updated_at`)
                    getConversations()
                }
            }
        )

        const liveObj: any = reactive({
            timeInfo: {
                id: computed(() => projectInfo.last_conversation_message?.id ?? 0),
                message_entity_id: computed(() => liveObj.lastMessageEntity?.message_entity_id ?? projectInfo.last_conversation_message?.message_entity_id ?? 0),
                timeRemain: computed(() => {
                    if ([EntityType.speak, EntityType.image, EntityType.video].includes(projectInfo?.last_conversation_message?.message_type)) {
                        return 0
                    } else {
                        let remain = 0
                        if (liveObj.lastMessageEntity?.message_reply_expired_at) {
                            const expired_at = new Date(liveObj.lastMessageEntity.message_reply_expired_at).getTime()
                            remain = (expired_at - liveObj.now) / 1000

                            if (remain < 0) {
                                return 0
                            }
                        }

                        if (projectInfo?.time_remain && projectInfo?.time_remain > 0) {

                            remain = projectInfo.time_remain
                        }
                        return remain
                    }
                }),
                isCollecting: computed(() => liveObj.timeInfo.timeRemain > 0),
                duration: 0
            },
            pollList: [] as any[],
            conversationList: [] as any[],
            msgCount: computed(() => {
                return liveObj.pollList.length + liveObj.conversationList.length
            }),
            countTo: {
                start: 0,
                end: 0,
                change: (count: number) => {
                    liveObj.countTo.start = liveObj.countTo.end
                    liveObj.countTo.end = count
                }
            },
            participants: computed(() => {
                return projectInfo.online_number
            }),
            lastMessageEntity: computed(() => {
                if (liveObj.conversationList.length == 0) {
                    return null
                } else {
                    return liveObj.conversationList[liveObj.conversationList.length - 1].message
                }
            }),
            now: computed(() => {
                return new Date().getTime() + projectInfo?.server_offset ?? 0
            })
        })
        provide('liveObj', liveObj)

        const messageCount = ref(0)

        const onBadgeClick = () => {
            // scrollTalkInfo.scrollBottom()
            if( scrollTalkRef.value){
                scrollTalkRef.value.scrollToBottom()
            }
            messageCount.value = 0
        }

        const onTalkScroll = () => {
            if (scrollTalkRef.value) {
                const scrollTop = scrollTalkRef.value.$el.scrollTop;
                const scrollHeight = scrollTalkRef.value.$el.scrollHeight;
                const clientHeight = scrollTalkRef.value.$el.clientHeight;
                //fixed by Tom 20240124，由于滚动到底部的时候，由于样式问题，会导致无法触发滚动到底部的条件，所以增加一个20的值
                //if (scrollTop + clientHeight >= scrollHeight) {
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                    onScrollBottom();
                }
            }
        };

        const onScrollBottom = () => {
            messageCount.value = 0
        }

        watch(
            () => liveObj.conversationList.length + liveObj.pollList.length,
            (newVal, oldVal) => {
                if (!projectTalkLoading.value) {
                    if (oldVal === 0) {
                        nextTick(() => setTimeout(() => scrollTalkInfo.scrollBottom(), 1500))
                    } else {
                        messageCount.value += newVal - oldVal   
                    }
                }
                getConversations()
            }
        )


        const getLiveConversations = async (bottom?: boolean) => {
            try {
                const conversationList = await getLiveConversationMessagesApi({
                    uuid: projectUuId.value,
                    driver: dataDriver.value
                })
                const respondentsList = await getLiveConversationRespondentsApi({
                    uuid: projectUuId.value,
                })
                // console.log(`respondentsList:`,respondentsList)
                const plist: any[] = []
                const clist: any[] = []
                if (conversationList) {
                    conversationList.forEach((item: any) => {
                        item.respondents=respondentsList
                        if (item.message.message_stage == 'poll') {
                            plist.push(item)
                        }
                        if (item.message.message_stage == 'discussion') {
                            clist.push(item)
                        }
                    })
                }
                liveObj.pollList = plist
                if (liveObj.pollList) {
                    liveObj.pollList.sort((a: any, b: any) => {
                        return a.message.id - b.message.id
                    })
                }

                if (clist.length >= liveObj.conversationList.length) {
                    liveObj.conversationList = clist
                    liveObj.conversationList.sort((a: any, b: any) => {
                        return a.message.id - b.message.id
                    })
                    liveObj.conversationList.forEach((item: any) => {
                        if(item.message.message_type=='video'||item.message.message_type=='image'){
                                if( item.message.message_link!=''&&item.message.message_link!=null){
                                    item?.message.message_options.unshift({
                                    created_at:  item.message.created_at,
                                    deleted_at: null,
                                    id:  null,
                                    is_show: true,
                                    is_system: false,
                                    link: item.message.message_link,
                                    message_entity_id: item.message.id,
                                    option_type: "",
                                    sequence: 0,
                                    text: item.message.message_type=='video'?`${root.$i18n.t('project.video')}1`:`${root.$i18n.t('project.image')}1`,
                                    updated_at: null,
                                    })
                                }
                            }
                    })
                }
                if (bottom) {
                    nextTick(() => setTimeout(() => scrollTalkInfo.scrollBottom()))
                }
                liveObj.countTo.change(liveObj.participants)
            } catch (error) {
                showError(error)
            }
        }

        const projectTalkLoading = ref(true)
        const getShareData = async () => {
            try {
                const data = await getShareInfoApi({
                    uuid: unref(shareUuid)
                })
                set(shareObj, 'status', data.status)
                set(shareObj, 'projectUuId', data.project_uuid)
                set(shareObj, 'viewGuide', data.view_guide)
                set(shareObj, 'dataExport', data.data_export)
                set(shareObj, 'nlp', data.nlp)
                set(shareObj, 'ai', data.ai)
                set(shareObj, 'keyword', data.keyword)
                set(shareObj, 'token', data.token)
                set(shareObj, 'id', data.id)

                Object.keys(data.project).forEach((key: any) => {
                    set(projectInfo, key, data.project[key])
                })
                dataDriver.value = data.project.status == ProjectStatus.End ? 'mysql' : ''



                nextTick(() => {
                    const a = document.getElementById('timeSlider')
                    const b = a?.getElementsByClassName('el-slider__button-wrapper')[0]
                    b?.setAttribute('data-before', '00:30:00')
                })
            } catch (error) {
                showError(error)
            }
        }

        const initGuideData = async () => {
            getOnboardingPolls()
            getConversations()
        }

        const initData = async () => {

            const promiseList = [initGuideData()]
            Promise.all(promiseList).then(() => {
                projectTalkLoading.value = false
                getLiveConversations(true)
            })
        }

        const initTimers = () => {
            console.log(`initTimers`)

            clearTimers()

            if ([ProjectStatus.Published, ProjectStatus.Start, ProjectStatus.Pending].includes(projectInfo.status)) {
                console.log(`initTimers -- share_refresh`)
                timerStart('share_refresh', getShareData, 2000)
            }
            if ([ProjectStatus.Published, ProjectStatus.Start,ProjectStatus.End].includes(projectInfo.status)) {
                console.log(`initTimers -- 启动conversions_refresh`)
                timerStart('conversions_refresh', getLiveConversations, 5000)
                getLiveConversations(true)
            }
            // 项目结束页刷新
            // if (projectInfo.status != ProjectStatus.End) {
            //     console.log(`initTimers -- 启动guide_refresh`)
            //     timerStart('guide_refresh', initGuideData, 10000)
            // }
        }

        const clearTimers = () => {
            console.log(`clearTimers`)
            timerClear('conversions_refresh')
            timerClear('share_refresh')
            timerClear('guide_refresh')
        }

        const getProject = async () => {
            await getShareData()

            await initData()

            wsInfo.initWsSdk()
        }


        const wsInfo = {

            gatewayURL: `${location.protocol === 'https:' ? 'wss:' : 'ws:'}//${location.host}/gateway`,
            authInfo: {
                token: shareUuid.value ?? '',
                projectUuid: projectUuId.value,
                guard: LoginGuard.Observer
            },
            cli: {} as ARTClient,
            initWsSdk: async () => {

                wsInfo.cli = new ARTClient(wsInfo.gatewayURL)


                const eventcallback = (evt: ARTEvent, data?: any) => {
                    console.log(`通知事件【${evt}】，执行对应执行参数:${JSON.stringify(data)}`)
                    if (evt == ARTEvent.Refuse) {

                        shareObj.refuse = true
                    }
                }

                const evts = [ARTEvent.Closed, ARTEvent.Reconnecting, ARTEvent.Reconnected, ARTEvent.Kickout, ARTEvent.Notify, ARTEvent.Refuse]

                wsInfo.cli.register(evts, eventcallback)

                const { success, err } = await wsInfo.cli.login(wsInfo.authInfo)
                console.log(`login ${success},err ${err}`)

                if (!success) {
                    console.log(err)
                    clearTimers()
                    wsInfo.clear()
                } else {
                    if (shareObj.refuse) {
                        await root.$router.push({
                            name: 'ObserverRefuse',
                            params: {
                                shareUuid: shareUuid.value
                            }
                        })
                    }

                    shareObj.liveStatus = LiveStatus.Ready
                }
            },
            clear: async () => {
                if (wsInfo.cli) {
                    console.log(`wsInfo cli logout`)
                    await wsInfo.cli.logout()
                    wsInfo.cli = {} as ARTClient
                }
            }
        }

        onMounted(() => {
            console.log(`ObserverLive onMounted`)
            getProject()
        })

        onUnmounted(() => {
            console.log(`ObserverLive onUnmounted`)

            clearTimers()
            wsInfo.clear()
        })

        const documentTitle = computed(() => {
            if (calculateTimeInfo.countdown > 0) {
                return `(${timeFormat(calculateTimeInfo.countdown, 'mm:ss')})${liveObj.lastMessageEntity ? liveObj.lastMessageEntity.message_content.replace(/<[^>]+>/g, '') : projectInfo.detail_title
                    }`
            } else {
                return projectInfo.detail_title
            }
        })

        watch(
            () => liveObj.timeInfo.timeRemain,
            () => {
                if (liveObj.timeInfo.timeRemain > 0 && !calculateTimeInfo.isRun) {

                    calculateTimeInfo.start()
                }
            }
        )

        const calculateTimeInfo = reactive({
            isRun: false,
            countdown: 0,
            currentInterval: null as any,
            start: () => {
                calculateTimeInfo.clear()
                const time = liveObj.timeInfo.timeRemain * 1000 + new Date().getTime()

                calculateTimeInfo.currentInterval = workerTimer.setInterval(() => {

                    calculateTimeInfo.isRun = true
                    calculateTimeInfo.countdown = time - new Date().getTime()
                    if (calculateTimeInfo.countdown <= 0) {

                        calculateTimeInfo.clear()
                    }

                }, 1000)
            },
            clear: () => {
                if (calculateTimeInfo.currentInterval) {

                    workerTimer.clearInterval(calculateTimeInfo.currentInterval)
                    calculateTimeInfo.currentInterval = null
                    calculateTimeInfo.isRun = false
                    calculateTimeInfo.countdown = 0
                }
            }
        })

        return {
            shareUuid,
            scrollFormRef,
            scrollFormInfo,
            projectTalkLoading,
            ProjectFormType,
            ConversationStatusType,
            ProjectStatus,
            projectUuId,
            projectInfo,
            shareObj,
            baseObj,
            messageObj,
            outlineObj,
            conversationObj,
            scrollTalkRef,
            scrollTalkInfo,
            liveObj,
            statusTypeObj,
            EntityType,
            dataDeriveInfo,
            documentTitle,
            LiveStatus,
            onBadgeClick,
            messageCount,
            onTalkScroll,
            formattedConversationList,
            talkCreateBranch,
            ProjectLiveTalk
        }
    }
})
