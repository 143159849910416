import { render, staticRenderFns } from "./AiSettingsBox.vue?vue&type=template&id=42fb8738&scoped=true&"
import script from "./AiSettingsBox.vue?vue&type=script&lang=ts&"
export * from "./AiSettingsBox.vue?vue&type=script&lang=ts&"
import style0 from "./AiSettingsBox.vue?vue&type=style&index=0&id=42fb8738&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42fb8738",
  null
  
)

export default component.exports