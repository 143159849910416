














































import { Progress } from "element-ui";
import { getPercentage } from '@/utils/common'
import { getPercentageStyle } from '@/utils/percentage-color'
import { defineComponent, reactive,onMounted,nextTick ,ref } from '@vue/composition-api'
export default defineComponent({
    components: {
    [Progress.name]: Progress,
  },
    props: {
        value: Object
    },
    setup(props, { emit }) {

        const percentage = (option: any) => {
            const result = props.value?.collection?.result
            let percentage = 0
            if (result) {
                const num = result.ops[String(option.id)]?.participants ?? 0
                const total = result.participants ?? 0
                percentage = getPercentage(num, total)
            }
            return percentage
        }

        const optionObj = reactive({
            imgs: [] as any[],
            texts: [] as any[]
        })
        const conceptContents = ref([]);
        const isOverflows = ref<boolean[]>([]);

        const getWidth = (el: any) => {
        const width  = el.clientWidth;
        return width || 0;
        };

        const scrollWidth = (el: any) => {
        const width = el.scrollWidth;
        return width || 0;
        };  
        onMounted(() => {
      // 使用nextTick确保DOM更新后再执行逻辑
            nextTick(() => {

                // 重新计算或检查溢出状态，这里可以调用一个方法来处理
                checkAndUpdateOverflows();
            });
            });

            // 定义一个方法来检查并更新溢出状态
            const checkAndUpdateOverflows = () => {
            isOverflows.value = conceptContents.value.map((contentEl:any) => {
                if (contentEl) {
                const outerWidth = getWidth(contentEl);
                const textWidth = scrollWidth(contentEl);
                return outerWidth < textWidth;
                }
                return false;
            });
            };

        if (props.value?.message?.message_options) {

            let options
            if (Array.isArray(props.value?.message?.message_options)) {
                options = props.value?.message?.message_options
            } else {
                options = JSON.parse(props.value?.message?.message_options)
            }
            if (options && options.length > 0) {
                options
                    .sort((a: any, b: any) => {
                        return a.sequence - b.sequence
                    })
                    .forEach((option: any) => {
                        if (option.link) {
                            optionObj.imgs.push(option)
                        } else {
                            optionObj.texts.push(option)
                        }
                    })
            }

            return { percentage, optionObj, getPercentageStyle,conceptContents,isOverflows }
        }

    }
})
