import { computed, ref, watch } from '@vue/composition-api'

interface CountTimeParams {
    totalTime: number
}

export const useCountTime = ({ totalTime }: CountTimeParams) => {
    const now = ref(0)
    const time = ref(totalTime)
    let isRunning = false
    let timer: any = null;
    const onStart = () => {
        now.value = Date.now()
        if (time.value > 0) {
            isRunning = true
            timer= requestAnimationFrame(onStart)
        } else {
            isRunning = false
            cancelAnimationFrame(timer);
            timer = null
        }
    }


    
    const finishTime = computed(() => Date.now() + time.value)
    
    const countdown = computed(() => Math.max(0, Math.floor(finishTime.value - now.value)))

    const initTime = (initTimeNum: number) => {
        time.value = initTimeNum
        if (!isRunning) {
            onStart()
        }
    }

    watch(
        () => countdown.value,
        () => {
            if (0 === countdown.value) {
                if (timer) {
                    cancelAnimationFrame(timer);
                    timer = null;
                }
            }
        }
    )
    return {
        countdown,
        onStart,
        initTime
    }
}

