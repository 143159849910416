/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "pbMessage";

export interface Message {
  id: Long;
  tokenId: Long;
  projectUuid: string;
  messageId: string;
  replyId: Long;
  sequence: Long;
  conversation: Conversation | undefined;
  reply: Reply[];
  assigment: Assigment | undefined;
}

export interface Assigment {
  testGroupId: Long;
  tokenId: Long;
  round: number;
  optionId: Long;
}

export interface Reply {
  projectUuid: string;
  token: string;
  replyContent: string;
  replyIndex: string;
  messageId: string;
  replyStage: string;
  replyType: string;
  ranks: string;
  ext: string;
  replyTime: Long;
  /** data messageMeta info 0x01:image 0x02:video 0x03:audio */
  messageMeta: string;
  autocommit: boolean;
  aiScore: number;
  aiContent: string;
  aiJson: string;
}

export interface ReplyToMQ {
  token: string;
  reply: Reply | undefined;
}

export interface ReplyToDB {
  reply: Reply | undefined;
}

export interface Conversation {
  projectUuid: string;
  sequence: number;
  messageEntityId: Long;
  messageStage: string;
  messageId: string;
  messageReplyExpiredAt: Long;
  messageReplyQuantity: number;
  messageType: string;
  messageDuration: number;
  messageContent: string;
  messageLink: string;
  /** data messageMeta info 0x01:image 0x02:video 0x03:audio */
  messageMeta: string;
  messageOptions: string;
  messageCreatedAt: Long;
  messageAIEnable: boolean;
}

export interface ConversationToMQ {
  projectUuid: string;
  conversation: Conversation | undefined;
}

export interface ConversationToDB {
  conversation: Conversation | undefined;
}

export interface MessageAck {
  Ack: boolean;
  ErrCode: number;
  ErrMessage: string;
}

export interface ErrorResp {
  message: string;
}

export interface PullMessageReq {
  id: number;
  token: string;
  projectUuid: string;
}

export interface PullMessageResp {
  messages: Message[];
}

function createBaseMessage(): Message {
  return {
    id: Long.ZERO,
    tokenId: Long.ZERO,
    projectUuid: "",
    messageId: "",
    replyId: Long.ZERO,
    sequence: Long.ZERO,
    conversation: undefined,
    reply: [],
    assigment: undefined,
  };
}

export const Message = {
  encode(
    message: Message,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.id.isZero()) {
      writer.uint32(8).int64(message.id);
    }
    if (!message.tokenId.isZero()) {
      writer.uint32(16).int64(message.tokenId);
    }
    if (message.projectUuid !== "") {
      writer.uint32(26).string(message.projectUuid);
    }
    if (message.messageId !== "") {
      writer.uint32(34).string(message.messageId);
    }
    if (!message.replyId.isZero()) {
      writer.uint32(40).int64(message.replyId);
    }
    if (!message.sequence.isZero()) {
      writer.uint32(48).int64(message.sequence);
    }
    if (message.conversation !== undefined) {
      Conversation.encode(
        message.conversation,
        writer.uint32(58).fork()
      ).ldelim();
    }
    for (const v of message.reply) {
      Reply.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.assigment !== undefined) {
      Assigment.encode(message.assigment, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.int64() as Long;
          break;
        case 2:
          message.tokenId = reader.int64() as Long;
          break;
        case 3:
          message.projectUuid = reader.string();
          break;
        case 4:
          message.messageId = reader.string();
          break;
        case 5:
          message.replyId = reader.int64() as Long;
          break;
        case 6:
          message.sequence = reader.int64() as Long;
          break;
        case 7:
          message.conversation = Conversation.decode(reader, reader.uint32());
          break;
        case 8:
          message.reply.push(Reply.decode(reader, reader.uint32()));
          break;
        case 9:
          message.assigment = Assigment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      id: isSet(object.id) ? Long.fromValue(object.id) : Long.ZERO,
      tokenId: isSet(object.tokenId)
        ? Long.fromValue(object.tokenId)
        : Long.ZERO,
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
      messageId: isSet(object.messageId) ? String(object.messageId) : "",
      replyId: isSet(object.replyId)
        ? Long.fromValue(object.replyId)
        : Long.ZERO,
      sequence: isSet(object.sequence)
        ? Long.fromValue(object.sequence)
        : Long.ZERO,
      conversation: isSet(object.conversation)
        ? Conversation.fromJSON(object.conversation)
        : undefined,
      reply: Array.isArray(object?.reply)
        ? object.reply.map((e: any) => Reply.fromJSON(e))
        : [],
      assigment: isSet(object.assigment)
        ? Assigment.fromJSON(object.assigment)
        : undefined,
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = (message.id || Long.ZERO).toString());
    message.tokenId !== undefined &&
      (obj.tokenId = (message.tokenId || Long.ZERO).toString());
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    message.messageId !== undefined && (obj.messageId = message.messageId);
    message.replyId !== undefined &&
      (obj.replyId = (message.replyId || Long.ZERO).toString());
    message.sequence !== undefined &&
      (obj.sequence = (message.sequence || Long.ZERO).toString());
    message.conversation !== undefined &&
      (obj.conversation = message.conversation
        ? Conversation.toJSON(message.conversation)
        : undefined);
    if (message.reply) {
      obj.reply = message.reply.map((e) => (e ? Reply.toJSON(e) : undefined));
    } else {
      obj.reply = [];
    }
    message.assigment !== undefined &&
      (obj.assigment = message.assigment
        ? Assigment.toJSON(message.assigment)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.id =
      object.id !== undefined && object.id !== null
        ? Long.fromValue(object.id)
        : Long.ZERO;
    message.tokenId =
      object.tokenId !== undefined && object.tokenId !== null
        ? Long.fromValue(object.tokenId)
        : Long.ZERO;
    message.projectUuid = object.projectUuid ?? "";
    message.messageId = object.messageId ?? "";
    message.replyId =
      object.replyId !== undefined && object.replyId !== null
        ? Long.fromValue(object.replyId)
        : Long.ZERO;
    message.sequence =
      object.sequence !== undefined && object.sequence !== null
        ? Long.fromValue(object.sequence)
        : Long.ZERO;
    message.conversation =
      object.conversation !== undefined && object.conversation !== null
        ? Conversation.fromPartial(object.conversation)
        : undefined;
    message.reply = object.reply?.map((e) => Reply.fromPartial(e)) || [];
    message.assigment =
      object.assigment !== undefined && object.assigment !== null
        ? Assigment.fromPartial(object.assigment)
        : undefined;
    return message;
  },
};

function createBaseAssigment(): Assigment {
  return {
    testGroupId: Long.ZERO,
    tokenId: Long.ZERO,
    round: 0,
    optionId: Long.ZERO,
  };
}

export const Assigment = {
  encode(
    message: Assigment,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.testGroupId.isZero()) {
      writer.uint32(8).int64(message.testGroupId);
    }
    if (!message.tokenId.isZero()) {
      writer.uint32(16).int64(message.tokenId);
    }
    if (message.round !== 0) {
      writer.uint32(24).int32(message.round);
    }
    if (!message.optionId.isZero()) {
      writer.uint32(32).int64(message.optionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assigment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssigment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.testGroupId = reader.int64() as Long;
          break;
        case 2:
          message.tokenId = reader.int64() as Long;
          break;
        case 3:
          message.round = reader.int32();
          break;
        case 4:
          message.optionId = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Assigment {
    return {
      testGroupId: isSet(object.testGroupId)
        ? Long.fromValue(object.testGroupId)
        : Long.ZERO,
      tokenId: isSet(object.tokenId)
        ? Long.fromValue(object.tokenId)
        : Long.ZERO,
      round: isSet(object.round) ? Number(object.round) : 0,
      optionId: isSet(object.optionId)
        ? Long.fromValue(object.optionId)
        : Long.ZERO,
    };
  },

  toJSON(message: Assigment): unknown {
    const obj: any = {};
    message.testGroupId !== undefined &&
      (obj.testGroupId = (message.testGroupId || Long.ZERO).toString());
    message.tokenId !== undefined &&
      (obj.tokenId = (message.tokenId || Long.ZERO).toString());
    message.round !== undefined && (obj.round = Math.round(message.round));
    message.optionId !== undefined &&
      (obj.optionId = (message.optionId || Long.ZERO).toString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Assigment>, I>>(
    object: I
  ): Assigment {
    const message = createBaseAssigment();
    message.testGroupId =
      object.testGroupId !== undefined && object.testGroupId !== null
        ? Long.fromValue(object.testGroupId)
        : Long.ZERO;
    message.tokenId =
      object.tokenId !== undefined && object.tokenId !== null
        ? Long.fromValue(object.tokenId)
        : Long.ZERO;
    message.round = object.round ?? 0;
    message.optionId =
      object.optionId !== undefined && object.optionId !== null
        ? Long.fromValue(object.optionId)
        : Long.ZERO;
    return message;
  },
};

function createBaseReply(): Reply {
  return {
    projectUuid: "",
    token: "",
    replyContent: "",
    replyIndex: "",
    messageId: "",
    replyStage: "",
    replyType: "",
    ranks: "",
    ext: "",
    replyTime: Long.ZERO,
    messageMeta: "",
    autocommit: false,
    aiScore: 0,
    aiContent: "",
    aiJson: "",
  };
}

export const Reply = {
  encode(message: Reply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.projectUuid !== "") {
      writer.uint32(10).string(message.projectUuid);
    }
    if (message.token !== "") {
      writer.uint32(18).string(message.token);
    }
    if (message.replyContent !== "") {
      writer.uint32(26).string(message.replyContent);
    }
    if (message.replyIndex !== "") {
      writer.uint32(34).string(message.replyIndex);
    }
    if (message.messageId !== "") {
      writer.uint32(42).string(message.messageId);
    }
    if (message.replyStage !== "") {
      writer.uint32(50).string(message.replyStage);
    }
    if (message.replyType !== "") {
      writer.uint32(58).string(message.replyType);
    }
    if (message.ranks !== "") {
      writer.uint32(66).string(message.ranks);
    }
    if (message.ext !== "") {
      writer.uint32(74).string(message.ext);
    }
    if (!message.replyTime.isZero()) {
      writer.uint32(80).int64(message.replyTime);
    }
    if (message.messageMeta !== "") {
      writer.uint32(90).string(message.messageMeta);
    }
    if (message.autocommit === true) {
      writer.uint32(96).bool(message.autocommit);
    }
    if (message.aiScore !== 0) {
      writer.uint32(104).int32(message.aiScore);
    }
    if (message.aiContent !== "") {
      writer.uint32(114).string(message.aiContent);
    }
    if (message.aiJson !== "") {
      writer.uint32(122).string(message.aiJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Reply {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.projectUuid = reader.string();
          break;
        case 2:
          message.token = reader.string();
          break;
        case 3:
          message.replyContent = reader.string();
          break;
        case 4:
          message.replyIndex = reader.string();
          break;
        case 5:
          message.messageId = reader.string();
          break;
        case 6:
          message.replyStage = reader.string();
          break;
        case 7:
          message.replyType = reader.string();
          break;
        case 8:
          message.ranks = reader.string();
          break;
        case 9:
          message.ext = reader.string();
          break;
        case 10:
          message.replyTime = reader.int64() as Long;
          break;
        case 11:
          message.messageMeta = reader.string();
          break;
        case 12:
          message.autocommit = reader.bool();
          break;
        case 13:
          message.aiScore = reader.int32();
          break;
        case 14:
          message.aiContent = reader.string();
          break;
        case 15:
          message.aiJson = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Reply {
    return {
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
      token: isSet(object.token) ? String(object.token) : "",
      replyContent: isSet(object.replyContent)
        ? String(object.replyContent)
        : "",
      replyIndex: isSet(object.replyIndex) ? String(object.replyIndex) : "",
      messageId: isSet(object.messageId) ? String(object.messageId) : "",
      replyStage: isSet(object.replyStage) ? String(object.replyStage) : "",
      replyType: isSet(object.replyType) ? String(object.replyType) : "",
      ranks: isSet(object.ranks) ? String(object.ranks) : "",
      ext: isSet(object.ext) ? String(object.ext) : "",
      replyTime: isSet(object.replyTime)
        ? Long.fromValue(object.replyTime)
        : Long.ZERO,
      messageMeta: isSet(object.messageMeta) ? String(object.messageMeta) : "",
      autocommit: isSet(object.autocommit) ? Boolean(object.autocommit) : false,
      aiScore: isSet(object.aiScore) ? Number(object.aiScore) : 0,
      aiContent: isSet(object.aiContent) ? String(object.aiContent) : "",
      aiJson: isSet(object.aiJson) ? String(object.aiJson) : "",
    };
  },

  toJSON(message: Reply): unknown {
    const obj: any = {};
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    message.token !== undefined && (obj.token = message.token);
    message.replyContent !== undefined &&
      (obj.replyContent = message.replyContent);
    message.replyIndex !== undefined && (obj.replyIndex = message.replyIndex);
    message.messageId !== undefined && (obj.messageId = message.messageId);
    message.replyStage !== undefined && (obj.replyStage = message.replyStage);
    message.replyType !== undefined && (obj.replyType = message.replyType);
    message.ranks !== undefined && (obj.ranks = message.ranks);
    message.ext !== undefined && (obj.ext = message.ext);
    message.replyTime !== undefined &&
      (obj.replyTime = (message.replyTime || Long.ZERO).toString());
    message.messageMeta !== undefined &&
      (obj.messageMeta = message.messageMeta);
    message.autocommit !== undefined && (obj.autocommit = message.autocommit);
    message.aiScore !== undefined &&
      (obj.aiScore = Math.round(message.aiScore));
    message.aiContent !== undefined && (obj.aiContent = message.aiContent);
    message.aiJson !== undefined && (obj.aiJson = message.aiJson);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Reply>, I>>(object: I): Reply {
    const message = createBaseReply();
    message.projectUuid = object.projectUuid ?? "";
    message.token = object.token ?? "";
    message.replyContent = object.replyContent ?? "";
    message.replyIndex = object.replyIndex ?? "";
    message.messageId = object.messageId ?? "";
    message.replyStage = object.replyStage ?? "";
    message.replyType = object.replyType ?? "";
    message.ranks = object.ranks ?? "";
    message.ext = object.ext ?? "";
    message.replyTime =
      object.replyTime !== undefined && object.replyTime !== null
        ? Long.fromValue(object.replyTime)
        : Long.ZERO;
    message.messageMeta = object.messageMeta ?? "";
    message.autocommit = object.autocommit ?? false;
    message.aiScore = object.aiScore ?? 0;
    message.aiContent = object.aiContent ?? "";
    message.aiJson = object.aiJson ?? "";
    return message;
  },
};

function createBaseReplyToMQ(): ReplyToMQ {
  return { token: "", reply: undefined };
}

export const ReplyToMQ = {
  encode(
    message: ReplyToMQ,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.reply !== undefined) {
      Reply.encode(message.reply, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplyToMQ {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReplyToMQ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.reply = Reply.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReplyToMQ {
    return {
      token: isSet(object.token) ? String(object.token) : "",
      reply: isSet(object.reply) ? Reply.fromJSON(object.reply) : undefined,
    };
  },

  toJSON(message: ReplyToMQ): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    message.reply !== undefined &&
      (obj.reply = message.reply ? Reply.toJSON(message.reply) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReplyToMQ>, I>>(
    object: I
  ): ReplyToMQ {
    const message = createBaseReplyToMQ();
    message.token = object.token ?? "";
    message.reply =
      object.reply !== undefined && object.reply !== null
        ? Reply.fromPartial(object.reply)
        : undefined;
    return message;
  },
};

function createBaseReplyToDB(): ReplyToDB {
  return { reply: undefined };
}

export const ReplyToDB = {
  encode(
    message: ReplyToDB,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.reply !== undefined) {
      Reply.encode(message.reply, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplyToDB {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReplyToDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.reply = Reply.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReplyToDB {
    return {
      reply: isSet(object.reply) ? Reply.fromJSON(object.reply) : undefined,
    };
  },

  toJSON(message: ReplyToDB): unknown {
    const obj: any = {};
    message.reply !== undefined &&
      (obj.reply = message.reply ? Reply.toJSON(message.reply) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReplyToDB>, I>>(
    object: I
  ): ReplyToDB {
    const message = createBaseReplyToDB();
    message.reply =
      object.reply !== undefined && object.reply !== null
        ? Reply.fromPartial(object.reply)
        : undefined;
    return message;
  },
};

function createBaseConversation(): Conversation {
  return {
    projectUuid: "",
    sequence: 0,
    messageEntityId: Long.ZERO,
    messageStage: "",
    messageId: "",
    messageReplyExpiredAt: Long.ZERO,
    messageReplyQuantity: 0,
    messageType: "",
    messageDuration: 0,
    messageContent: "",
    messageLink: "",
    messageMeta: "",
    messageOptions: "",
    messageCreatedAt: Long.ZERO,
    messageAIEnable: false,
  };
}

export const Conversation = {
  encode(
    message: Conversation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.projectUuid !== "") {
      writer.uint32(10).string(message.projectUuid);
    }
    if (message.sequence !== 0) {
      writer.uint32(16).int32(message.sequence);
    }
    if (!message.messageEntityId.isZero()) {
      writer.uint32(24).int64(message.messageEntityId);
    }
    if (message.messageStage !== "") {
      writer.uint32(34).string(message.messageStage);
    }
    if (message.messageId !== "") {
      writer.uint32(42).string(message.messageId);
    }
    if (!message.messageReplyExpiredAt.isZero()) {
      writer.uint32(48).int64(message.messageReplyExpiredAt);
    }
    if (message.messageReplyQuantity !== 0) {
      writer.uint32(56).int32(message.messageReplyQuantity);
    }
    if (message.messageType !== "") {
      writer.uint32(66).string(message.messageType);
    }
    if (message.messageDuration !== 0) {
      writer.uint32(72).int32(message.messageDuration);
    }
    if (message.messageContent !== "") {
      writer.uint32(82).string(message.messageContent);
    }
    if (message.messageLink !== "") {
      writer.uint32(90).string(message.messageLink);
    }
    if (message.messageMeta !== "") {
      writer.uint32(98).string(message.messageMeta);
    }
    if (message.messageOptions !== "") {
      writer.uint32(106).string(message.messageOptions);
    }
    if (!message.messageCreatedAt.isZero()) {
      writer.uint32(112).int64(message.messageCreatedAt);
    }
    if (message.messageAIEnable === true) {
      writer.uint32(120).bool(message.messageAIEnable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Conversation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConversation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.projectUuid = reader.string();
          break;
        case 2:
          message.sequence = reader.int32();
          break;
        case 3:
          message.messageEntityId = reader.int64() as Long;
          break;
        case 4:
          message.messageStage = reader.string();
          break;
        case 5:
          message.messageId = reader.string();
          break;
        case 6:
          message.messageReplyExpiredAt = reader.int64() as Long;
          break;
        case 7:
          message.messageReplyQuantity = reader.int32();
          break;
        case 8:
          message.messageType = reader.string();
          break;
        case 9:
          message.messageDuration = reader.int32();
          break;
        case 10:
          message.messageContent = reader.string();
          break;
        case 11:
          message.messageLink = reader.string();
          break;
        case 12:
          message.messageMeta = reader.string();
          break;
        case 13:
          message.messageOptions = reader.string();
          break;
        case 14:
          message.messageCreatedAt = reader.int64() as Long;
          break;
        case 15:
          message.messageAIEnable = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Conversation {
    return {
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
      sequence: isSet(object.sequence) ? Number(object.sequence) : 0,
      messageEntityId: isSet(object.messageEntityId)
        ? Long.fromValue(object.messageEntityId)
        : Long.ZERO,
      messageStage: isSet(object.messageStage)
        ? String(object.messageStage)
        : "",
      messageId: isSet(object.messageId) ? String(object.messageId) : "",
      messageReplyExpiredAt: isSet(object.messageReplyExpiredAt)
        ? Long.fromValue(object.messageReplyExpiredAt)
        : Long.ZERO,
      messageReplyQuantity: isSet(object.messageReplyQuantity)
        ? Number(object.messageReplyQuantity)
        : 0,
      messageType: isSet(object.messageType) ? String(object.messageType) : "",
      messageDuration: isSet(object.messageDuration)
        ? Number(object.messageDuration)
        : 0,
      messageContent: isSet(object.messageContent)
        ? String(object.messageContent)
        : "",
      messageLink: isSet(object.messageLink) ? String(object.messageLink) : "",
      messageMeta: isSet(object.messageMeta) ? String(object.messageMeta) : "",
      messageOptions: isSet(object.messageOptions)
        ? String(object.messageOptions)
        : "",
      messageCreatedAt: isSet(object.messageCreatedAt)
        ? Long.fromValue(object.messageCreatedAt)
        : Long.ZERO,
      messageAIEnable: isSet(object.messageAIEnable)
        ? Boolean(object.messageAIEnable)
        : false,
    };
  },

  toJSON(message: Conversation): unknown {
    const obj: any = {};
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    message.sequence !== undefined &&
      (obj.sequence = Math.round(message.sequence));
    message.messageEntityId !== undefined &&
      (obj.messageEntityId = (message.messageEntityId || Long.ZERO).toString());
    message.messageStage !== undefined &&
      (obj.messageStage = message.messageStage);
    message.messageId !== undefined && (obj.messageId = message.messageId);
    message.messageReplyExpiredAt !== undefined &&
      (obj.messageReplyExpiredAt = (
        message.messageReplyExpiredAt || Long.ZERO
      ).toString());
    message.messageReplyQuantity !== undefined &&
      (obj.messageReplyQuantity = Math.round(message.messageReplyQuantity));
    message.messageType !== undefined &&
      (obj.messageType = message.messageType);
    message.messageDuration !== undefined &&
      (obj.messageDuration = Math.round(message.messageDuration));
    message.messageContent !== undefined &&
      (obj.messageContent = message.messageContent);
    message.messageLink !== undefined &&
      (obj.messageLink = message.messageLink);
    message.messageMeta !== undefined &&
      (obj.messageMeta = message.messageMeta);
    message.messageOptions !== undefined &&
      (obj.messageOptions = message.messageOptions);
    message.messageCreatedAt !== undefined &&
      (obj.messageCreatedAt = (
        message.messageCreatedAt || Long.ZERO
      ).toString());
    message.messageAIEnable !== undefined &&
      (obj.messageAIEnable = message.messageAIEnable);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Conversation>, I>>(
    object: I
  ): Conversation {
    const message = createBaseConversation();
    message.projectUuid = object.projectUuid ?? "";
    message.sequence = object.sequence ?? 0;
    message.messageEntityId =
      object.messageEntityId !== undefined && object.messageEntityId !== null
        ? Long.fromValue(object.messageEntityId)
        : Long.ZERO;
    message.messageStage = object.messageStage ?? "";
    message.messageId = object.messageId ?? "";
    message.messageReplyExpiredAt =
      object.messageReplyExpiredAt !== undefined &&
      object.messageReplyExpiredAt !== null
        ? Long.fromValue(object.messageReplyExpiredAt)
        : Long.ZERO;
    message.messageReplyQuantity = object.messageReplyQuantity ?? 0;
    message.messageType = object.messageType ?? "";
    message.messageDuration = object.messageDuration ?? 0;
    message.messageContent = object.messageContent ?? "";
    message.messageLink = object.messageLink ?? "";
    message.messageMeta = object.messageMeta ?? "";
    message.messageOptions = object.messageOptions ?? "";
    message.messageCreatedAt =
      object.messageCreatedAt !== undefined && object.messageCreatedAt !== null
        ? Long.fromValue(object.messageCreatedAt)
        : Long.ZERO;
    message.messageAIEnable = object.messageAIEnable ?? false;
    return message;
  },
};

function createBaseConversationToMQ(): ConversationToMQ {
  return { projectUuid: "", conversation: undefined };
}

export const ConversationToMQ = {
  encode(
    message: ConversationToMQ,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.projectUuid !== "") {
      writer.uint32(10).string(message.projectUuid);
    }
    if (message.conversation !== undefined) {
      Conversation.encode(
        message.conversation,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConversationToMQ {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConversationToMQ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.projectUuid = reader.string();
          break;
        case 2:
          message.conversation = Conversation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConversationToMQ {
    return {
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
      conversation: isSet(object.conversation)
        ? Conversation.fromJSON(object.conversation)
        : undefined,
    };
  },

  toJSON(message: ConversationToMQ): unknown {
    const obj: any = {};
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    message.conversation !== undefined &&
      (obj.conversation = message.conversation
        ? Conversation.toJSON(message.conversation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ConversationToMQ>, I>>(
    object: I
  ): ConversationToMQ {
    const message = createBaseConversationToMQ();
    message.projectUuid = object.projectUuid ?? "";
    message.conversation =
      object.conversation !== undefined && object.conversation !== null
        ? Conversation.fromPartial(object.conversation)
        : undefined;
    return message;
  },
};

function createBaseConversationToDB(): ConversationToDB {
  return { conversation: undefined };
}

export const ConversationToDB = {
  encode(
    message: ConversationToDB,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.conversation !== undefined) {
      Conversation.encode(
        message.conversation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConversationToDB {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConversationToDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.conversation = Conversation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConversationToDB {
    return {
      conversation: isSet(object.conversation)
        ? Conversation.fromJSON(object.conversation)
        : undefined,
    };
  },

  toJSON(message: ConversationToDB): unknown {
    const obj: any = {};
    message.conversation !== undefined &&
      (obj.conversation = message.conversation
        ? Conversation.toJSON(message.conversation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ConversationToDB>, I>>(
    object: I
  ): ConversationToDB {
    const message = createBaseConversationToDB();
    message.conversation =
      object.conversation !== undefined && object.conversation !== null
        ? Conversation.fromPartial(object.conversation)
        : undefined;
    return message;
  },
};

function createBaseMessageAck(): MessageAck {
  return { Ack: false, ErrCode: 0, ErrMessage: "" };
}

export const MessageAck = {
  encode(
    message: MessageAck,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Ack === true) {
      writer.uint32(8).bool(message.Ack);
    }
    if (message.ErrCode !== 0) {
      writer.uint32(16).int32(message.ErrCode);
    }
    if (message.ErrMessage !== "") {
      writer.uint32(26).string(message.ErrMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageAck {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageAck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Ack = reader.bool();
          break;
        case 2:
          message.ErrCode = reader.int32();
          break;
        case 3:
          message.ErrMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MessageAck {
    return {
      Ack: isSet(object.Ack) ? Boolean(object.Ack) : false,
      ErrCode: isSet(object.ErrCode) ? Number(object.ErrCode) : 0,
      ErrMessage: isSet(object.ErrMessage) ? String(object.ErrMessage) : "",
    };
  },

  toJSON(message: MessageAck): unknown {
    const obj: any = {};
    message.Ack !== undefined && (obj.Ack = message.Ack);
    message.ErrCode !== undefined &&
      (obj.ErrCode = Math.round(message.ErrCode));
    message.ErrMessage !== undefined && (obj.ErrMessage = message.ErrMessage);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MessageAck>, I>>(
    object: I
  ): MessageAck {
    const message = createBaseMessageAck();
    message.Ack = object.Ack ?? false;
    message.ErrCode = object.ErrCode ?? 0;
    message.ErrMessage = object.ErrMessage ?? "";
    return message;
  },
};

function createBaseErrorResp(): ErrorResp {
  return { message: "" };
}

export const ErrorResp = {
  encode(
    message: ErrorResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ErrorResp {
    return {
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: ErrorResp): unknown {
    const obj: any = {};
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ErrorResp>, I>>(
    object: I
  ): ErrorResp {
    const message = createBaseErrorResp();
    message.message = object.message ?? "";
    return message;
  },
};

function createBasePullMessageReq(): PullMessageReq {
  return { id: 0, token: "", projectUuid: "" };
}

export const PullMessageReq = {
  encode(
    message: PullMessageReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.token !== "") {
      writer.uint32(18).string(message.token);
    }
    if (message.projectUuid !== "") {
      writer.uint32(26).string(message.projectUuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PullMessageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePullMessageReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.int32();
          break;
        case 2:
          message.token = reader.string();
          break;
        case 3:
          message.projectUuid = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PullMessageReq {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      token: isSet(object.token) ? String(object.token) : "",
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
    };
  },

  toJSON(message: PullMessageReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.token !== undefined && (obj.token = message.token);
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PullMessageReq>, I>>(
    object: I
  ): PullMessageReq {
    const message = createBasePullMessageReq();
    message.id = object.id ?? 0;
    message.token = object.token ?? "";
    message.projectUuid = object.projectUuid ?? "";
    return message;
  },
};

function createBasePullMessageResp(): PullMessageResp {
  return { messages: [] };
}

export const PullMessageResp = {
  encode(
    message: PullMessageResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.messages) {
      Message.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PullMessageResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePullMessageResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messages.push(Message.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PullMessageResp {
    return {
      messages: Array.isArray(object?.messages)
        ? object.messages.map((e: any) => Message.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PullMessageResp): unknown {
    const obj: any = {};
    if (message.messages) {
      obj.messages = message.messages.map((e) =>
        e ? Message.toJSON(e) : undefined
      );
    } else {
      obj.messages = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PullMessageResp>, I>>(
    object: I
  ): PullMessageResp {
    const message = createBasePullMessageResp();
    message.messages =
      object.messages?.map((e) => Message.fromPartial(e)) || [];
    return message;
  },
};

export interface MessageService {
  ModeratorToInterviewee(request: Conversation): Promise<MessageAck>;
  IntervieweeReply(request: Reply): Promise<MessageAck>;
  PullMessage(request: PullMessageReq): Promise<PullMessageResp>;
}

export class MessageServiceClientImpl implements MessageService {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ModeratorToInterviewee = this.ModeratorToInterviewee.bind(this);
    this.IntervieweeReply = this.IntervieweeReply.bind(this);
    this.PullMessage = this.PullMessage.bind(this);
  }
  ModeratorToInterviewee(request: Conversation): Promise<MessageAck> {
    const data = Conversation.encode(request).finish();
    const promise = this.rpc.request(
      "pbMessage.MessageService",
      "ModeratorToInterviewee",
      data
    );
    return promise.then((data) => MessageAck.decode(new _m0.Reader(data)));
  }

  IntervieweeReply(request: Reply): Promise<MessageAck> {
    const data = Reply.encode(request).finish();
    const promise = this.rpc.request(
      "pbMessage.MessageService",
      "IntervieweeReply",
      data
    );
    return promise.then((data) => MessageAck.decode(new _m0.Reader(data)));
  }

  PullMessage(request: PullMessageReq): Promise<PullMessageResp> {
    const data = PullMessageReq.encode(request).finish();
    const promise = this.rpc.request(
      "pbMessage.MessageService",
      "PullMessage",
      data
    );
    return promise.then((data) => PullMessageResp.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
