


















































































































































import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { Switch } from 'element-ui'
import Avatar from '@/components/Avatar.vue'
import { showError } from '@/utils/common'
import { getProjectApi, getProjectShareApi, updateProjectShareApi } from '@/api/project'
// import qrcode from 'qrcode'
import config from '@/config'
import ProjectSharedDialog from './ProjectSharedDialog.vue'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
import { UserRole } from '@/api/user/model'
export default defineComponent({
    components: {
        Avatar,
        [Switch.name]: Switch,
        ProjectSharedDialog
    },
    setup(props, { root, refs }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)

        const projectUuId = computed(() => root.$route.params.projectUuId)
        const shareMngObj: any = reactive({
            showDialog: false,
            link: computed(() => `${location.protocol}//${location.host}/observer/${shareMngObj.shareInfo.token}`),
            isViewGuide: false,
            isDataExport: false,
            isKeyword: false,
            isNlp: false,
            shareInfo: {},
            isOpened: false,
            ai:false
        })
        // const qrcodeUrl = ref('')
        // qrcode.toDataURL(`${config.apiServer}/api/v1/project/${projectUuId.value}/new-token`).then((url: string) => {
        //     qrcodeUrl.value = url
        // })

        const getProject = async () => {
            try {
                const { owner } = await getProjectApi({
                    uuid: projectUuId.value
                })
                projectSharedInfo.show = userInfo.value.role == UserRole.Super || owner?.user_id == userInfo.value.id
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const getProjectShare = async () => {
            try {
                const shareInfo = await getProjectShareApi({
                    uuid: projectUuId.value
                })
                shareMngObj.shareInfo = shareInfo
                shareMngObj.isViewGuide = shareInfo.view_guide
                shareMngObj.isDataExport = shareInfo.data_export
                shareMngObj.isKeyword = shareInfo.keyword
                shareMngObj.isNlp = shareInfo.nlp
                shareMngObj.ai = shareInfo.ai
                shareMngObj.isOpened = shareInfo.status === 'open'
            } catch (error) {
                showError(error)
            }
        }
        getProjectShare()

        watch(
            () => [shareMngObj.isOpened, shareMngObj.isViewGuide, shareMngObj.isDataExport, shareMngObj.isKeyword, shareMngObj.isNlp, shareMngObj.ai],
            () => {
                updateProjectShare()
            }
        )

        const updateProjectShare = async () => {
            try {
                const options = {
                    project_uuid: projectUuId.value,
                    status: shareMngObj.isOpened ? 'open' : 'closed',
                    view_guide: shareMngObj.isViewGuide,
                    data_export: shareMngObj.isDataExport,
                    keyword: shareMngObj.isKeyword,
                    nlp: shareMngObj.isNlp,
                    ai: shareMngObj.ai
                }

                await updateProjectShareApi(options)
            } catch (error) {
                showError(error)
            }
        }


        const projectSharedDialogRef = ref()
        const projectSharedInfo = reactive({
            show: false,
            open() {
                projectSharedDialogRef.value.init(projectUuId.value)
            }
        })

        return { shareMngObj, userInfo, projectSharedInfo, projectSharedDialogRef }
    }
})
