import { wordColors, wordShapes } from "@/utils/word-cloud";
import { EntityType } from "./model";
import { cloneDeep } from "lodash";
export const entityTypeTemplate: any = {
    content: "",
    duration: 15,
    entity_type: "",
    link: "",
    parent_id: undefined,
    parent_message_entity: {},
    branchs: [],
    options: [],
    settings: [
        {
            ai_enable:true,
            options: {
                randomized: false,
                other: false,
                none: false,
            },
            recommend_enable: true,
        },
        
    ],
    nlp_settings: {
        keywords_enable: true,
        nlp_enable: false,
        color: wordColors[0].color,
        shape: wordShapes[0].shape,
        apply_global_color: false,
        apply_global_shape: false,
    },
    ai_settings:{
        ai_enable: true,
        user_prompt: "",
        language: "zh-CN",
        apply_global_language: false,
        theme:""
      }
};

export const handleNlpSettings = (sections: any[]) => {
    const message_entity = cloneDeep(entityTypeTemplate);
    if (sections && sections.length > 0) {
        const hasGlobalColor = (v: any) =>
            [EntityType.ask_opinion, EntityType.branch].includes(
                v.message_entity.entity_type
            ) && v.message_entity?.nlp_settings?.apply_global_color;
        const hasGlobalShape = (v: any) =>
            [EntityType.ask_opinion, EntityType.branch].includes(
                v.message_entity.entity_type
            ) && v.message_entity?.nlp_settings?.apply_global_shape;
        const messageColor = sections
            .find((section) => section.items.some(hasGlobalColor))
            ?.items.find(hasGlobalColor);
        const messageShape = sections
            .find((section) => section.items.some(hasGlobalShape))
            ?.items.find(hasGlobalShape);
        const color = messageColor?.message_entity?.nlp_settings?.color;
        const shape = messageShape?.message_entity?.nlp_settings?.shape;

        if (color) {
            message_entity.nlp_settings.color = color;
        }
        if (shape) {
            message_entity.nlp_settings.shape = shape;
        }
       
    }
    
    return message_entity;
};
