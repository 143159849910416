














import { defineComponent, reactive, ref, toRefs, nextTick } from '@vue/composition-api'

export default defineComponent({
    props: {
        value: String,
        maxlength: {
            type: Number,
            default: 30
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const inputRef = ref()
        const state = reactive({
            isShowInput: false,
            inputVal: props.value,
            onClick: () => {
                state.isShowInput = true
                nextTick(() => inputRef.value.focus())
            },
            onBlur: () => {
                if (state.inputVal === '') {
                    state.inputVal = props.value
                    return
                }
                emit('input', state.inputVal)
                state.isShowInput = false
            },
            onChange: () => {
                if (state.inputVal === '') {
                    state.inputVal = props.value
                    return
                }
                emit('change', state.inputVal)
            }
        })
        return {
            inputRef,
            ...toRefs(state)
        }
    }
})
