
import { Header, Status } from './proto/common'
export class Seq {
    public static num: number = 0
    public static Next() {
        Seq.num++
        Seq.num = Seq.num % 65536
        return Seq.num
    }
}

export enum Command {

    SignIn = 'login.signin',
    SignOut = 'login.signout',


    ChatUserTalk = 'chat.user.talk',
    ChatTalkAck = 'chat.talk.ack',


    ModeratorTalk = 'moderator.talk',
    ModeratorTalkAck = 'moderator.talk.ack',


    MessageIn = 'message.in',


    OfflineIndex = 'chat.offline.index',
    OfflineContent = 'chat.offline.content',


    RefuseObserver = "refuse.observer",

    Notify = "notify"
}

const MagicLogicPkt = new Uint8Array([0xc3, 0x11, 0xa3, 0x65])
const MagicBasicPkt = new Uint8Array([0xc3, 0x15, 0xa7, 0x65])

export const MagicLogicPktInt = Buffer.from(MagicLogicPkt).readInt32BE(0)
export const MagicBasicPktInt = Buffer.from(MagicBasicPkt).readInt32BE(0)

export enum MessageType {
    Text = 1,
    Image = 2,
    Voice = 3,
    Video = 4
}

export const Ping = new Uint8Array([0xc3, 0x15, 0xa7, 0x65, 0, 1, 0, 0])
export const Pong = new Uint8Array([0xc3, 0x15, 0xa7, 0x65, 0, 2, 0, 0])

export class LogicPkt {
    public static build(command: string, payload: Uint8Array = new Uint8Array()): LogicPkt {

        const message = new LogicPkt()
        message.command = command
        message.sequence = Seq.Next()
        if (payload.length > 0) {
            message.payload = payload
        }
        return message
    }
    public static from(buf: Buffer): LogicPkt {
        let offset = 0
        const magic = buf.readInt32BE(offset)
        let hlen = 0

        if (magic == MagicLogicPktInt) {
            offset += 4
        }
        hlen = buf.readInt32BE(offset)
        offset += 4

        const header = Header.decode(buf.subarray(offset, offset + hlen))
        offset += hlen
        const message = new LogicPkt()

        Object.assign(message, header)

        const plen = buf.readInt32BE(offset)
        offset += 4
        message.payload = buf.subarray(offset, offset + plen)
        return message
    }
    public command?: string
    public channelId?: string
    public sequence: number = 0
    public flag?: number
    public status: number = Status.Success
    public payload: Uint8Array
    constructor() {
        this.payload = new Uint8Array()
    }
    public bytes(): Buffer {
        const headerArray = Header.encode(Header.fromJSON(this)).finish()
        const hlen = headerArray.length
        const plen = this.payload.length

        const buf = Buffer.alloc(4 + 4 + hlen + 4 + plen)
        let offset = 0
        Buffer.from(MagicLogicPkt).copy(buf, offset, 0)
        offset += 4

        offset = buf.writeInt32BE(hlen, offset)

        Buffer.from(headerArray).copy(buf, offset, 0)
        offset += hlen

        offset = buf.writeInt32BE(plen, offset)

        Buffer.from(this.payload).copy(buf, offset, 0)
        return buf
    }
}

export let print = (arr: Uint8Array) => {
    if (arr == null) {
        return
    }
    console.info(`[${arr.join(',')}]`)
}
