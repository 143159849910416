import { ProjectStatus } from '@/api/project/model'
import { UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
import { useProjectStore } from '@/pinia/modules/project'
import { onUnmounted } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import Vue from 'vue'
const Bus = new Vue()

export const useEventBus = () => {



    const projectStore = useProjectStore()
    const { surveySaveState, projectInfo } = storeToRefs(projectStore)

    const busEmit = (action: string, param: any) => {
        if ([UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY].includes(action)) {

            surveySaveState.value = 1
            Bus.$emit(action, param)
        } else {
            Bus.$emit(action, param)
        }

    }

    const busOn = (action: string, callback: any) => {
        Bus.$on(action, callback)
    }

    const busOff = (action?: string) => {
        if (action) {
            Bus.$off(action)
        } else {
            Bus.$off()
        }
    }

    return {
        busOn,
        busEmit,
        busOff
    }
}
