






























import { defineComponent, inject, reactive, watch } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import ImageCheckbox from './components/ImageCheckbox.vue'
import { EntityType } from '@/api/project/model'
import { sesstionPollItems } from '@/api/project/entity-type-option'
import { typeDurationOption } from '@/api/project/duration-option'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/pinia/modules/project'

export default defineComponent({
    components: {
        Editor,
        TextCheckbox,
        ImageCheckbox,
        TimeCustomSelect
    },
    setup(props, { root }) {
        const itemObj: any = inject('itemObj')
        const itemWay: any = inject('itemWay')
        const conversationObj: any = inject('conversationObj')
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        if (itemWay.value == 'create') {
            itemObj.message_entity.entity_type = EntityType.poll
            itemObj.message_entity.duration = typeDurationOption[EntityType.poll].value
        }

        const entityTypeInfo = reactive({
            value: EntityType.poll,
            oldValue: itemObj.message_entity.entity_type,
            option: sesstionPollItems,
            change: () => {
                const textPoll = [EntityType.poll, EntityType.poll_multi]
                const picturePoll = [EntityType.picture_poll, EntityType.picture_poll_multi]
                if (
                    (textPoll.includes(itemObj.message_entity.entity_type) && !textPoll.includes(entityTypeInfo.oldValue)) ||
                    (picturePoll.includes(itemObj.message_entity.entity_type) && !picturePoll.includes(entityTypeInfo.oldValue))
                ) {
                    itemObj.message_entity.options = []
                    if (itemObj.message_entity.settings[0]) {
                        itemObj.message_entity.settings[0].options = {
                            randomized: false,
                            other: false,
                            none: false
                        }
                        itemObj.message_entity.settings[0].ai_enable = true
                        itemObj.message_entity.settings[0].recommend_enable = true
                    }
                }
                entityTypeInfo.oldValue = itemObj.message_entity.entity_type
            }
        })

        watch(
            () => itemObj.message_entity.settings,
            (newValue, oldValue) => {
                const options = newValue[0]?.options
                if (options) {
                    const optionsCopy = _.cloneDeep(itemObj.message_entity.options)
                    itemObj.message_entity.options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                    if (options.other) {
                        const opt = optionsCopy.find((v: any) => v.is_system && v.option_type == 'other')
                        itemObj.message_entity.options.push(
                            opt
                                ? opt
                                : {
                                      is_system: true,
                                      text: root.$t('projectSurvey.optionType.other', projectInfo.value.detail_language),
                                      is_show: true,
                                      option_type: 'other'
                                  }
                        )
                    }
                    if (options.none) {
                        const opt = optionsCopy.find((v: any) => v.is_system && v.option_type == 'none')
                        itemObj.message_entity.options.push(
                            opt
                                ? opt
                                : {
                                      is_system: true,
                                      text: root.$t('projectSurvey.optionType.none', projectInfo.value.detail_language),
                                      is_show: true,
                                      option_type: 'none'
                                  }
                        )
                    }
                    itemObj.message_entity.options.forEach((option: any, index: number) => {
                        option.sequence = index
                    })
                }
            },
            {
                deep: true
            }
        )

        return {
            EntityType,
            itemObj,
            entityTypeInfo,
            conversationObj
        }
    }
})
