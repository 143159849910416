



















import { defineComponent, inject, ref,reactive,computed } from '@vue/composition-api'
import { EntityType, ProjectFormType } from '@/api/project/model'
export default defineComponent({
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')
        const testGroupInfo = computed(() => {
            const { test_group,test_group_rounds } = itemInfo.message_entity
            return {
                current_round: test_group?test_group.current_round:0,
                rounds:test_group?test_group.rounds:0,
                test_group_rounds:test_group_rounds,
                getStatus:(round:number)=>{
                    let status = 'ready'
                    if(test_group_rounds){
                        status = test_group_rounds.filter((item:any)=>itemInfo.message_entity_id==item.message_entity_id&&item.round==round).map((item:any)=>item.status)[0]
                    }
                    return status
                }
            }
        })

        return {
            testGroupInfo
        }
    }
})
