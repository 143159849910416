
const blobURL = URL.createObjectURL(
    new Blob(
        [
            '(',

            function () {
                const intervalIds: any = {}


                self.onmessage = function onMsgFunc(e) {
                    switch (e.data.command) {
                        case 'interval:start':
                            const intervalId = setInterval(function () {
                                postMessage({
                                    message: 'interval:tick',
                                    id: e.data.id
                                })
                            }, e.data.interval)

                            postMessage({
                                message: 'interval:started',
                                id: e.data.id
                            })

                            intervalIds[e.data.id] = intervalId
                            break
                        case 'interval:clear':
                            clearInterval(intervalIds[e.data.id])

                            postMessage({
                                message: 'interval:cleared',
                                id: e.data.id
                            })

                            delete intervalIds[e.data.id]
                            break
                    }
                }
            }.toString(),

            ')()'
        ],
        { type: 'application/javascript' }
    )
)

const worker = new Worker(blobURL)

URL.revokeObjectURL(blobURL)

const workerTimer = {
    id: 0,
    callbacks: {} as any,
    setInterval(cb: any, interval: number, context?: any) {
        this.id++
        const id = this.id
        this.callbacks[id] = { fn: cb, context }
        worker.postMessage({
            command: 'interval:start',
            interval,
            id
        })
        return id
    },
    setTimeout(cb: any, timeout: number, context?: any) {
        this.id++
        const id = this.id
        this.callbacks[id] = { fn: cb, context }
        worker.postMessage({ command: 'timeout:start', timeout, id })
        return id
    },

    onMessage(e: any) {
        switch (e.data.message) {
            case 'interval:tick':
            case 'timeout:tick': {
                const callbackItem = this.callbacks[e.data.id]
                if (callbackItem && callbackItem.fn) {
                    callbackItem.fn.apply(callbackItem.context)
                }
                break
            }

            case 'interval:cleared':
            case 'timeout:cleared':
                delete this.callbacks[e.data.id]
                break
        }
    },

    clearInterval(id: any) {
        worker.postMessage({ command: 'interval:clear', id })
    },
    clearTimeout(id: any) {
        worker.postMessage({ command: 'timeout:clear', id })
    }
}

worker.onmessage = workerTimer.onMessage.bind(workerTimer)

export default workerTimer
