

































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        shape: {
            type: String,
            default: ''
        }
    },
    setup() {}
})
