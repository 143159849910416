











































import { moveSectionMessageGroupApi } from '@/api/project'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { showError } from '@/utils/common'
import { computed, ComputedRef, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { RadioGroup, Radio } from 'element-ui'
import ShowFormWrap from '@/components/form-common-show/ShowFormWrap.vue'
export default defineComponent({
    components: {
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        ShowFormWrap
    },
    props: {
        sections: {
            type: Array,
            default: []
        }
    },
    setup(props, { root, emit }) {
        const sectionList: ComputedRef<any[]> = computed(() => {
            return props.sections || []
        })

        const state = reactive({
            show: false,
            sectionId: '',
            loading: false,
            location: 'tail',
            isCollapse: false,
            message_entity: {} as any,
            sectionInfo: {} as any,
            onOpen: (item: any) => {
                state.sectionId = item.section_id
                state.sectionInfo = JSON.parse(JSON.stringify(item))
                state.message_entity = state.sectionInfo.message_entity
                state.show = true
            },
            onSave: async () => {
                try {
                    await moveSectionMessageGroupApi({
                        uuid: state.sectionInfo.project_uuid,
                        messageId: state.sectionInfo.id,
                        sectionId: ~~state.sectionId,
                        pos: state.location
                    })
                    emit('success')
                    state.show = false
                } catch (error) {
                    showError(error)
                }
            }
        })

        return {
            ProjectFormType,
            EntityType,
            sectionList,
            ...toRefs(state)
        }
    }
})
