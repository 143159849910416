

























































import {
    excelConversationDataExportApi,
    excelConversationReplyDataExportApi,
    getConversationExcelExportStatus,
    getConversationReplyDownloadInfoApi,
    recomputeConversationExcelExport
} from '@/api/project'
import { exportFile, showError } from '@/utils/common'
import { dateFormat } from '@/utils/filters'
import { computed, defineComponent, inject, onMounted, reactive, watch } from '@vue/composition-api'
import config from '@/config'
import { goUrl } from '@/utils/common'
import { useSetInterval } from '@/hooks/useSetInterval'

enum DownloadStatus {
    Pending = 'pending',
    Completed = 'completed',
    Failed = 'failed'
}

enum ExcelExportStatus {

    WordCouldRunning = 'word_could_running',

    ExportRunning = 'export_running',

    GoRecomputing = 'go_recomputing',

    AllDone = 'all_done'
}

export default defineComponent({
    props: {
        title: {
            required: true
        },
        uuid: {
            required: true
        },
        value: {
            type: Boolean
        }
    },
    setup(props, { emit, root }) {
        const show = computed({
            get: () => {
                if (props.value) {
                    timerObj.start()
                } else {
                    timerObj.clear()
                }
                return props.value || false
            },
            set: (value) => {
                emit('input', value)
            }
        })

        const { timerStart, timerClear } = useSetInterval()


        let downloadInfo: any


        const observer: boolean = inject('observer', false)
        console.log(`data export observer: ${observer}`)

        onMounted(() => {})

        const exportObj = reactive({
            loadingObj: {
                recompute: false,
                analyze: false,
                reply: false,
                download: false
            },
            exportStatus: ExcelExportStatus.AllDone,
            exportStatusText: computed(() => {
                switch (exportObj.exportStatus) {
                    case ExcelExportStatus.WordCouldRunning:

                        return root.$t('dataDialog.exportStatusText1')
                    case ExcelExportStatus.ExportRunning:

                        return root.$t('dataDialog.exportStatusText2')
                    case ExcelExportStatus.GoRecomputing:

                        return root.$t('dataDialog.exportStatusText3')
                    case ExcelExportStatus.AllDone:

                        return root.$t('dataDialog.exportStatusText4')
                    default:
                        return ''
                }
            }),
            downloadStatus: DownloadStatus.Failed,
            exportAnalyzeData: async () => {
                console.log(`exportAnalyzeData`)
                exportObj.loadingObj.analyze = true
                try {
                    const data = await excelConversationDataExportApi({
                        uuid: String(props.uuid),
                        observer
                    })

                    exportFile(data, `${root.$t('dataDialog.statisticalDataTitle')}-${props.title}-${dateFormat(new Date(), 'YYYYYYMMDDHHmm')}.xlsx`)
                } catch (error) {
                    showError(error)
                }
                exportObj.loadingObj.analyze = false
            },
            exportReplyData: async () => {
                console.log(`exportReplyData`)
                exportObj.loadingObj.reply = true
                try {
                    const data = await excelConversationReplyDataExportApi({
                        uuid: String(props.uuid),
                        observer
                    })
                    exportFile(data, `${root.$t('dataDialog.rawDataTitle')}-${props.title}-${dateFormat(new Date(), 'YYYYYYMMDDHHmm')}.xlsx`)
                } catch (error) {
                    showError(error)
                }
                exportObj.loadingObj.reply = false
            },
            initDownloadInfo: async () => {
                console.log(`initDownloadInfo`)
                try {
                    const data = await getConversationReplyDownloadInfoApi({
                        uuid: String(props.uuid),
                        observer
                    })
                    downloadInfo = data
                    exportObj.downloadStatus = data?.status
                    console.log(exportObj.downloadStatus)
                } catch (error) {
                    showError(error)
                }
            },
            downloadResourceInfo: async () => {
                console.log(`downloadResourceInfo`)
                exportObj.loadingObj.download = true
                await exportObj.initDownloadInfo()
                if (downloadInfo.status == 'completed') {
                    const link = `https://${config.qiniuDomain}/${downloadInfo.link.replace('txt', 'zip')}`
                    const name = `${root.$t('dataDialog.zipFileTitle')}-${props.title}-${dateFormat(new Date(), 'YYYYYYMMDDHHmm')}.zip`



                    goUrl(`${link}?attname=${name}`)
                } else {
                    showError(root.$t('downloadErrorMsg'))
                }
                exportObj.loadingObj.download = false
            },
            getExcelExportStatus: async () => {
                console.log(`getExcelExportStatus`)
                const info: any = await getConversationExcelExportStatus({
                    uuid: String(props.uuid),
                    observer
                })
                console.log(`info: ${JSON.stringify(info)}`)
                if (info.status == ExcelExportStatus.GoRecomputing && observer) {
                    exportObj.exportStatus = ExcelExportStatus.ExportRunning
                } else {
                    exportObj.exportStatus = info.status
                }
                exportObj.loadingObj.recompute = false
            },
            recompute: async () => {
                exportObj.loadingObj.recompute = true
                timerObj.clear()
                await recomputeConversationExcelExport({
                    uuid: String(props.uuid),
                    observer
                })
                setTimeout(() => {
                    timerObj.start()
                }, 3000)
            }
        })

        const timerObj = reactive({
            timer: null as any,
            start: () => {
                console.log(`启动定时器`)
                exportObj.initDownloadInfo()
                exportObj.getExcelExportStatus()
                timerStart(
                    `roll-status-${String(props.uuid)}`,
                    () => {
                        exportObj.initDownloadInfo()
                        exportObj.getExcelExportStatus()
                    },
                    5000
                )
            },
            clear: () => {
                timerClear(`roll-status-${String(props.uuid)}`)
            }
        })

        watch(
            () => exportObj.exportStatus,
            (newVal) => {
                console.log(`watch exportStatus: ${newVal}`)
                if ([ExcelExportStatus.WordCouldRunning, ExcelExportStatus.ExportRunning].includes(newVal)) {
                    console.log(`正在计算中不能下载`)
                } else if (newVal === ExcelExportStatus.GoRecomputing) {
                    console.log(`需要重新生成表格`)
                } else if (newVal === ExcelExportStatus.AllDone) {
                    console.log(`全部完成`)
                }
            }
        )
        return {
            show,
            exportObj,
            DownloadStatus,
            ExcelExportStatus
        }
    }
})
