import { onUnmounted } from '@vue/composition-api'
import _ from 'lodash'
import { setTimeout } from 'timers'

const timer: any = {}
export const useSetInterval = () => {
    onUnmounted(() => timerClearAll())

    const timerClearAll = () => {
        console.log(`timerClearAll`)
        _.forEach(timer, (value, key) => {
            delete timer[key]
        })
        console.log(timer)
    }

    const timerClear = (action: string) => {
        if (!_.isNil(timer[action])) {
            delete timer[action]
        }
    }

    const timerStart = async (action: string, callback: any, t = 1000, isFirst = true, isStart = false, count = 0) => {
        // console.log(`timerStart:${action}, isFirst:${isFirst}, isStart:${isStart}, count:${count}`)
        if (_.isNil(timer[action])) {
            if (!isFirst) {
                return
            }
            timer[action] = count = 0
        }

        if (timer[action] < count) {
            console.log(`踢出冗余的定时器:${action}`)
            return
        }

        if (isStart) {
            try {
                await callback()
            } catch {
                console.log(`定时器${action}执行异常`)
            }
        }

        count++
        timer[action] = count
        setTimeout(() => {
            timerStart(action, callback, t, false, true, count)
        }, t)

    }

    return {
        timerClear,
        timerStart,
        timerClearAll
    }
}
