import Axios from '../index'
import { UploadFileDto } from './model'

/**
 * 上传图片
 */
export const uploadImage = (option: UploadFileDto): Promise<any> => {
    const param = new FormData()
    param.append('file', option.file)
    return Axios.post('/api/v1/upload-image', param, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

/**
 * 上传视频
 */
export const uploadVideo = (option: UploadFileDto): Promise<any> => {
    const param = new FormData()
    param.append('file', option.file)
    return Axios.post('/api/v1/upload-video', param, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 1000 * 60 * 10
    })
}
