/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "pkt";

export interface LoginReq {
  token: string;
  guard: string;
  projectUuid: string;
}

export interface LoginResp {
  channelId: string;
  message: string;
}

export interface KickoutNotify {
  channelId: string;
}

export interface RefuseObserver {
  channelId: string;
}

function createBaseLoginReq(): LoginReq {
  return { token: "", guard: "", projectUuid: "" };
}

export const LoginReq = {
  encode(
    message: LoginReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.guard !== "") {
      writer.uint32(18).string(message.guard);
    }
    if (message.projectUuid !== "") {
      writer.uint32(26).string(message.projectUuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.guard = reader.string();
          break;
        case 3:
          message.projectUuid = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginReq {
    return {
      token: isSet(object.token) ? String(object.token) : "",
      guard: isSet(object.guard) ? String(object.guard) : "",
      projectUuid: isSet(object.projectUuid) ? String(object.projectUuid) : "",
    };
  },

  toJSON(message: LoginReq): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    message.guard !== undefined && (obj.guard = message.guard);
    message.projectUuid !== undefined &&
      (obj.projectUuid = message.projectUuid);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<LoginReq>, I>>(object: I): LoginReq {
    const message = createBaseLoginReq();
    message.token = object.token ?? "";
    message.guard = object.guard ?? "";
    message.projectUuid = object.projectUuid ?? "";
    return message;
  },
};

function createBaseLoginResp(): LoginResp {
  return { channelId: "", message: "" };
}

export const LoginResp = {
  encode(
    message: LoginResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.channelId !== "") {
      writer.uint32(10).string(message.channelId);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.channelId = reader.string();
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginResp {
    return {
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: LoginResp): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<LoginResp>, I>>(
    object: I
  ): LoginResp {
    const message = createBaseLoginResp();
    message.channelId = object.channelId ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseKickoutNotify(): KickoutNotify {
  return { channelId: "" };
}

export const KickoutNotify = {
  encode(
    message: KickoutNotify,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.channelId !== "") {
      writer.uint32(10).string(message.channelId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KickoutNotify {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKickoutNotify();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.channelId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): KickoutNotify {
    return {
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
    };
  },

  toJSON(message: KickoutNotify): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<KickoutNotify>, I>>(
    object: I
  ): KickoutNotify {
    const message = createBaseKickoutNotify();
    message.channelId = object.channelId ?? "";
    return message;
  },
};

function createBaseRefuseObserver(): RefuseObserver {
  return { channelId: "" };
}

export const RefuseObserver = {
  encode(
    message: RefuseObserver,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.channelId !== "") {
      writer.uint32(10).string(message.channelId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefuseObserver {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefuseObserver();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.channelId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RefuseObserver {
    return {
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
    };
  },

  toJSON(message: RefuseObserver): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RefuseObserver>, I>>(
    object: I
  ): RefuseObserver {
    const message = createBaseRefuseObserver();
    message.channelId = object.channelId ?? "";
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
