



























































import { computed, defineComponent, reactive, Ref, ref, nextTick } from '@vue/composition-api'
import { Drawer, Empty, Form, FormItem } from 'element-ui'
import Avatar from '@/components/Avatar.vue'
import { showError } from '@/utils/common'
import { deleteProjectSharedApi, getProjectSharedListApi, saveProjectSharedApi } from '@/api/project/index'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/pinia/modules/user'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Drawer.name]: Drawer,
        [Empty.name]: Empty,
        Avatar
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        const isShowDrawer = ref(false)

        const projectUuid = ref()

        const init = (uuid: string) => {
            projectUuid.value = uuid
            getProjectSharedList()
            isShowDrawer.value = true
            nextTick(() => formRef.value.resetFields())
        }

        const formRef = ref()

        const formInfo: any = reactive({
            email: ''
        })
        const formRules = reactive({
            email: [
                { required: true, message: root.$t('shareDialog.formRule1'), trigger: 'blur' },
                { type: 'email', message: root.$t('shareDialog.formRule2'), trigger: ['blur', 'change'] }
            ]
        })

        const sharedList: Ref<any[]> = ref([])
        const pageLoad = ref(true)
        const getProjectSharedList = async () => {
            pageLoad.value = true
            try {
                const list: any[] = await getProjectSharedListApi({
                    uuid: projectUuid.value
                })
                sharedList.value = list.filter((v) => v.email != userInfo.value.email)
            } catch (error) {
                showError(error)
            }
            pageLoad.value = false
        }

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                return !Object.keys(formRules).every((key) => formInfo[key])
            }),
            onSubmitFrom: () => {
                formRef.value.validate().then(async () => {
                    submitInfo.isSubmitLoad = true
                    try {
                        await saveProjectSharedApi({
                            uuid: projectUuid.value,
                            email: formInfo.email
                        })
                        root.$message.success(root.$t('success').toString())
                        formRef.value.resetFields()
                        getProjectSharedList()
                    } catch (error) {
                        showError(error)
                    }
                    submitInfo.isSubmitLoad = false
                })
            }
        })

        const onDeleteProjectShared = async (item: any, index: number) => {
            try {
                await root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message: <string>root.$i18n.t('confirmDeleteMsg')
                })
                await deleteProjectSharedApi({
                    uuid: projectUuid.value,
                    email: item.email
                })
                sharedList.value.splice(index, 1)
                root.$message.success(root.$t('deleteSuccess').toString())
            } catch (error) {
                if (error != 'cancel') {
                    showError(error)
                }
            }
        }

        return {
            init,
            isShowDrawer,
            formRef,
            formInfo,
            formRules,
            sharedList,
            pageLoad,
            submitInfo,
            onDeleteProjectShared
        }
    }
})
