





















import { getPercentage } from '@/utils/common'
import { Progress } from "element-ui";
import { computed, defineComponent,onMounted,nextTick ,ref} from '@vue/composition-api'
import { getPercentageStyle } from '@/utils/percentage-color'
export default defineComponent({
      components: {
    [Progress.name]: Progress,
  },
    props: {
        value: Object
    },
    setup(props, { emit }) {
        const options = computed(() => {
            if (!props.value?.message.message_options) {
                return []
            } else {
                let message_options

                if (Array.isArray(props.value?.message?.message_options)) {
                    message_options = props.value?.message?.message_options
                } else {
                    message_options = JSON.parse(props.value?.message?.message_options)
                }

                if (message_options && message_options.length > 0) {

                    message_options.sort((a: any, b: any) => {
                        return a.sequence - b.sequence
                    })
                }

                return message_options
            }
        })
        const conceptContents = ref([]);
        const isOverflows = ref<boolean[]>([]);

        const getWidth = (el: any) => {
        const width  = el.clientWidth;
        return width || 0;
        };

        const scrollWidth = (el: any) => {
        const width = el.scrollWidth;
        return width || 0;
        };  
        onMounted(() => {
      // 使用nextTick确保DOM更新后再执行逻辑
            nextTick(() => {
                // 重新计算或检查溢出状态，这里可以调用一个方法来处理
                checkAndUpdateOverflows();
            });
            });

            // 定义一个方法来检查并更新溢出状态
            const checkAndUpdateOverflows = () => {
            isOverflows.value = conceptContents.value.map((contentEl:any) => {
                if (contentEl) {
                const outerWidth = getWidth(contentEl);
                const textWidth = scrollWidth(contentEl);
                return outerWidth < textWidth;
                }
                return false;
            });
            };

        const percentage = (option: any) => {
            const result = props.value?.collection?.result
            let percentage = 0
            if (result) {
                const num = result.ops[String(option.id)]?.participants ?? 0
                const total = result.participants ?? 0
                percentage = getPercentage(num, total)
            }
            return percentage
        }
        return { percentage, getPercentageStyle, options ,conceptContents,isOverflows}
    }
})
