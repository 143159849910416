import { EntityType, EntityTypeOption } from '@/api/project/model'
import i18n from '@/locale'
import { typeDurationOption } from './duration-option'
export const basicItems: EntityTypeOption[] = [
    {
        icon: 'ri-checkbox-line',
        name: i18n.t('entityType.poll'),
        label: i18n.t('entityType.poll'),
        msg: i18n.t('entityType.pollMsg'),
        duration: 15,
        value: EntityType.onboarding_poll
    },
    {
        icon: 'ri-questionnaire-line',
        name: i18n.t('entityType.askOpinion'),
        label: i18n.t('entityType.askOpinion'),
        msg: i18n.t('entityType.askOpinionMsg'),
        duration: 15,
        value: EntityType.onboarding_ask_opinion
    }
]

export const basicPollItems = [
    {
        value: EntityType.onboarding_poll,
        label: i18n.t('entityType.singleSelect')
    },
    {
        value: EntityType.onboarding_poll_multi,
        label: i18n.t('entityType.multiSelect')
    },
    {
        value: EntityType.onboarding_poll_picture,
        label: i18n.t('entityType.pictureSingleSelect')
    },
    {
        value: EntityType.onboarding_poll_picture_multi,
        label: i18n.t('entityType.pictureMultiSelect')
    }
]

export const sessionItems: EntityTypeOption[] = [
    {
        icon: 'ri-message-2-line',
        name: i18n.t('entityType.speak'),
        msg: i18n.t('entityType.speakMsg'),
        duration: 15,
        value: EntityType.speak
    },
    {
        icon: 'ri-checkbox-multiple-line',
        name: i18n.t('entityType.poll'),
        msg: i18n.t('entityType.pollMsg'),
        duration: typeDurationOption[EntityType.poll].value,
        value: EntityType.poll
    },
    {
        icon: 'ri-filter-3-line',
        name: i18n.t('entityType.rank'),
        msg: i18n.t('entityType.rankMsg'),
        duration: typeDurationOption[EntityType.rank].value,
        value: EntityType.rank
    },
    {
        icon: 'ri-questionnaire-line',
        name: i18n.t('entityType.askOpinion'),
        msg: i18n.t('entityType.askOpinionMsg'),
        duration: typeDurationOption[EntityType.ask_opinion].value,
        value: EntityType.ask_opinion
    },
    {
        icon: 'ri-questionnaire-line',
        name: i18n.t('entityType.askExperience'),
        msg: i18n.t('entityType.askExperienceMsg'),
        duration: typeDurationOption[EntityType.ask_experience].value,
        value: EntityType.ask_experience
    },
    {
        icon: 'ri-camera-3-line',
        name: i18n.t('entityType.multimediaUpload'),
        msg: i18n.t('entityType.multimediaUploadMsg'),
        duration: typeDurationOption[EntityType.upload_image].value,
        value: EntityType.upload_image
    },
    {
        icon: 'ri-image-2-line',
        name: i18n.t('entityType.picture'),
        msg: i18n.t('entityType.pictureMsg'),
        duration: 15,
        value: EntityType.image
    },
    {
        icon: 'ri-live-line',
        name: i18n.t('entityType.video'),
        msg: i18n.t('entityType.videoMsg'),
        duration: 15,
        value: EntityType.video
    },
    {
        icon: 'ri-questionnaire-line',
        name: i18n.t('entityType.branch'),
        msg: '',
        duration: typeDurationOption[EntityType.branch].value,
        value: EntityType.branch
    }
]

export const sesstionPollItems = [
    {
        value: EntityType.poll,
        label: i18n.t('entityType.singleSelect')
    },
    {
        value: EntityType.poll_multi,
        label: i18n.t('entityType.multiSelect')
    },
    {
        value: EntityType.picture_poll,
        label: i18n.t('entityType.pictureSingleSelect')
    },
    {
        value: EntityType.picture_poll_multi,
        label: i18n.t('entityType.pictureMultiSelect')
    }
]

export const sesstionUploadItems = [
    {
        value: EntityType.upload_image,
        label: i18n.t('entityType.picture')
    },
    {
        value: EntityType.upload_video,
        label: i18n.t('entityType.video')
    }
    // {
    //     value: EntityType.upload_mix,
    //     label: '图片或视频'
    // }
]

export const allEntityTypeItems = [
    
    {
        icon: 'ri-checkbox-line',
        value: EntityType.onboarding_poll,
        label: i18n.t('entityType.singleSelect')
    },
    {
        icon: 'ri-checkbox-line',
        value: EntityType.onboarding_poll_multi,
        label: i18n.t('entityType.multiSelect')
    },
    {
        icon: 'ri-checkbox-line',
        value: EntityType.onboarding_poll_picture,
        label: i18n.t('entityType.pictureSingleSelect')
    },
    {
        icon: 'ri-checkbox-line',
        value: EntityType.onboarding_poll_picture_multi,
        label: i18n.t('entityType.pictureMultiSelect')
    },
    {
        icon: 'ri-questionnaire-line',
        value: EntityType.onboarding_ask_opinion,
        label: i18n.t('entityType.askOpinion')
    },
    
    {
        icon: 'ri-checkbox-multiple-line',
        value: EntityType.poll,
        label: i18n.t('entityType.singleSelect')
    },
    {
        icon: 'ri-checkbox-multiple-line',
        value: EntityType.poll_multi,
        label: i18n.t('entityType.multiSelect')
    },
    {
        icon: 'ri-checkbox-multiple-line',
        value: EntityType.picture_poll,
        label: i18n.t('entityType.pictureSingleSelect')
    },
    {
        icon: 'ri-checkbox-multiple-line',
        value: EntityType.picture_poll_multi,
        label: i18n.t('entityType.pictureMultiSelect')
    },
    {
        icon: 'ri-message-2-line',
        value: EntityType.speak,
        label: i18n.t('entityType.speak')
    },
    {
        icon: 'ri-filter-3-line',
        value: EntityType.rank,
        label: i18n.t('entityType.rank')
    },
    {
        icon: 'ri-questionnaire-line',
        value: EntityType.ask_opinion,
        label: i18n.t('entityType.askOpinion')
    },
    {
        icon: 'ri-questionnaire-line',
        value: EntityType.ask_experience,
        label: i18n.t('entityType.askExperience')
    },
    {
        icon: 'ri-image-2-line',
        value: EntityType.image,
        label: i18n.t('entityType.picture')
    },
    {
        icon: 'ri-live-line',
        value: EntityType.video,
        label: i18n.t('entityType.video')
    },
    {
        icon: 'ri-questionnaire-line',
        value: EntityType.branch,
        label: i18n.t('entityType.branch')
    },
    {
        icon: 'ri-camera-3-line',
        value: EntityType.upload_image,
        label: i18n.t('entityType.multimediaUploadPicture')
    },
    {
        icon: 'ri-camera-3-line',
        value: EntityType.upload_video,
        label: i18n.t('entityType.multimediaUploadVideo')
    }
    // {
    //     icon: 'ri-camera-3-line',
    //     value: EntityType.upload_mix,
    //     label: '图片或视频上传'
    // }
]
