










import { defineComponent, inject, ref } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import { EntityType } from '@/api/project/model'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { typeDurationOption } from '@/api/project/duration-option'
import WordCloudBox from './components/WordCloudBox.vue'
import { Switch } from 'element-ui'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        Editor,
        TimeCustomSelect,
        WordCloudBox,
        [Switch.name]: Switch,
        SettingBox,
    },
    setup() {
        const itemObj: any = inject('itemObj')
        const itemWay: any = inject('itemWay')
        if (itemWay.value == 'create') {
            itemObj.message_entity.entity_type = EntityType.ask_opinion
            itemObj.message_entity.duration = typeDurationOption[EntityType.ask_opinion].value
        }
        if (itemObj.message_entity.settings.length === 0) {
            itemObj.message_entity.settings.push({
                ai_enable: true,
                options: {
                    randomized: false,
                    other: false,
                    none: false,
                },
                recommend_enable: true,
            });
        }
        const setIsCollapse = ref(true)
        return {
            itemObj,
            EntityType,
            setIsCollapse,
        }
    }
})
