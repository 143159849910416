

























































































































































































































































































































































































































































































































































































































































import {
  getMessageOptionsAnalyzeApi,
  getMessageSegmentAnalyzeApi,
  getTestGroupInfoApi,
  getTestGroupMessageAnalyzeApi,
} from "@/api/project";
import { EntityType, OrderType } from "@/api/project/model";
import { useProjectStore } from "@/pinia/modules/project";
import { getPercentage } from "@/utils/common";
import { getPercentageStyle } from "@/utils/percentage-color";
import VideoDialog from "@/components/VideoDialog.vue";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  nextTick
} from "@vue/composition-api";
import {
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Progress,
} from "element-ui";
import { isEqual, isNil, cloneDeep } from "lodash";
import Filters from "./components/Filters.vue";
import  CommonTooltip  from "./components/CommonTooltip.vue";

export default defineComponent({
  components: {
    [Drawer.name]: Drawer,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Progress.name]: Progress,
    Filters,
    VideoDialog,
    CommonTooltip,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  setup(props, { root, emit }) {
    const showDrawer = ref(props.value);
    watch(
      () => props.value,
      () => {
        showDrawer.value = props.value;
      }
    );
    const tooltipRef=ref()
    const handleDropdownVisibleChange=(visible:any)=> {
      if (visible) {
        nextTick(() => {
          // 确保 CommonTooltip 已经渲染且可见
          if (tooltipRef.value) {
            const tooltipElement =tooltipRef.value; // 假设 CommonTooltip 传递了 ref 到内部元素
            tooltipRef.value.map((item:any)=>{
              item.checkOverflowAndHandle()
            })
          }
        });
      }
    };
    
    const onClosed = () => {
      emit("input", showDrawer.value);
    };
    const videoDialogRef = ref();
    const showFilters = ref(false);
    const showConceptFilters = ref(false);
    const showIntersectFilters = ref(false);
    const segments: any = inject("segments");

   
    const driver: any = inject("driver");
    const observer: boolean = inject("observer", false);

    const projectStore = useProjectStore();
    const {
      getAnalyzeSegments,
      getAnalyzeConceptPoll,
      updateAnalyzeConceptPoll,
      getFilterSegments,
      updateFilterSegments,
    } = projectStore;

    watch(
      () => {
        return props.item;
      },
      (value, old) => {
        analyzeObj.loading = !isEqual(value, old);
        if (analyzeObj.selectedAnalyzeType == 1) {
          analyzeObj.testGroups.forEach((segment: any, index: any) => {
            setTestGroupCollection(segment, index);
          });
          analyzeObj.orderConcept.refreshOrder();
        } else if (analyzeObj.selectedAnalyzeType == 2) {
          analyzeObj.segments.forEach((segment: any, index: any) => {
            setCollection(segment, index);
          });
          analyzeObj.orderObj.refreshOrder();
        } else if (analyzeObj.selectedAnalyzeType == 3) {
          analyzeObj.intersect.forEach((segment: any, index: any) => {
            setIntersectCollection(segment, index);
          });
          analyzeObj.orderIntersect.refreshOrder();
        }
      }
    );

    

    const analyzeObj: any = reactive({
      loading: true,
      currentRound: null,
      selectedAnalyzeType: 1,
      analyzeTypes: [
        {
          id: 1,
          name: root.$t("projectTestGroup.conceptualContrast"),
        },
        {
          id: 2,
          name: root.$t("projectTestGroup.populationComparison"),
        },
        {
          id: 3,
          name: root.$t("projectTestGroup.crossContrast"),
        },
      ],
      selectedSegment: 0,
      selectedConcept: { id: null, text: "" },
      groups: [] as any[],
      selectedRounds: {
        value: -1,
        text: root.$t("projectTestGroup.allRounds"),
      },
      selectedOptions: { id: null, text: "" },
      options: [] as any[],
      testGroupInfo: {},
      testGroups: [] as any[],
      overallSegment: {
        id: 0,
        oldId:0,
        name: root.$t("all"),
        collection: null,
        collections: [],
      },
      clickSegmentIndex: 0,
      clickIntersectSegmentIndex: 0,
      originSegments: computed(() => {
        const items = segments.value.map((segment: any) => {
          const item = {
            id: segment.id,
            oldId:segment.id,
            name: segment.name,
            collection: null,
          };
          return item;
        });
        return [analyzeObj.overallSegment, ...items];
      }),
      segments: [] as any[],

      orderObj: {
        type: OrderType.None,
        curOrderSegment: {} as any,
        getType: (segment: any) => {
          if (segment.id !== analyzeObj.orderObj.curOrderSegment.id) {
            return OrderType.None;
          }
          return analyzeObj.orderObj.type;
        },
        clearAllOrder: () => {
          analyzeObj.orderObj.type = OrderType.None;
          analyzeObj.orderObj.refreshOrder();
        },
        changeOrder: (segment: any, orderType?: OrderType) => {
          if (isNil(segment.id)) {
            return;
          }

          if (segment.id != analyzeObj.orderObj.curOrderSegment.id) {
            analyzeObj.orderObj.type = OrderType.None;
            analyzeObj.orderObj.curOrderSegment = segment;
          }
          const dataMap = analyzeObj.getDataMap(segment);
          let keys = Object.keys(dataMap).map((key) => Number(key));
          if (keys.length == 0) {
            return;
          }
          if (!orderType) {
            if (analyzeObj.orderObj.type == OrderType.None) {
              orderType = OrderType.DESC;
            } else if (analyzeObj.orderObj.type == OrderType.DESC) {
              orderType = OrderType.ASC;
            } else if (analyzeObj.orderObj.type == OrderType.ASC) {
              orderType = OrderType.None;
            }
          }
          analyzeObj.orderObj.type = orderType;

          const type = analyzeObj.orderObj.type;
          switch (type) {
            case OrderType.None:
              keys = keys.sort((a, b) => a - b);
              break;
            case OrderType.DESC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.options.find((option: any) => option.id === a).sequence -
                    analyzeObj.options.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[b] - dataMap[a];
                }
              });
              break;
            case OrderType.ASC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.options.find((option: any) => option.id === a).sequence -
                    analyzeObj.options.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[a] - dataMap[b];
                }
              });
              break;
          }
          analyzeObj.options.sort((a: any, b: any) => {
            return keys.indexOf(a.id) - keys.indexOf(b.id);
          });
        },
        refreshOrder: () => {
          analyzeObj.orderObj.changeOrder(
            analyzeObj.orderObj.curOrderSegment,
            analyzeObj.orderObj.type
          );
        },
        canReset: computed(() => {
          return analyzeObj.orderObj.type != OrderType.None;
        }),
      },
      getParticipants: (item: any) => {
        if (!item?.collection) {
          return 0;
        }
        if (!item.collection.result) {
          return 0;
        }
        return item.collection.result.participants;
      },
      getIntersecParticipants(item: any){
        const valueMap: any = {};
        if (item.collection?.result) {
          if(analyzeObj.selectedRounds.value<0){
            analyzeObj.testGroups.forEach((option: any) => {
              let participants=0
              item.collections.map((inter: any)=>{
                if(inter.result){
                  participants+=(inter.result.ops[option.id].all_participants?inter.result.ops[option.id].all_participants:0)
                }else{
                  participants+=0
                }
              })
              valueMap[option.id]=participants
            });
          }else{
            analyzeObj.testGroups.forEach((option: any) => {
              if (!item?.collection) {
                valueMap[option.id]= 0;
              }
              if (!item.collection.result) {
                valueMap[option.id]= 0;
              }
              valueMap[option.id]= item.collection.result.ops[option.id].all_participants
            })
           
          }
         
        } else {
          analyzeObj.testGroups.forEach((option: any) => {
            valueMap[option.id] = 0;
          });
        }
        return valueMap;
      },
      getDataMap: (item: any) => {
        const valueMap: any = {};
        if (item.collection?.result) {
          analyzeObj.options.forEach((option: any) => {
            if (item.collection?.result.ops[option.id]) {
              valueMap[option.id] = percentage(
                item.collection?.result.ops[option.id],
                item.collection?.result
              );
            }
          });
        } else {
          analyzeObj.options.forEach((option: any) => {
            valueMap[option.id] = 0;
          });
        }
        return valueMap;
      },
      getIntersectMap: (inter: any) => {
        const valueMap: any = {};
      if (inter.collection?.result) {
        analyzeObj.testGroupInfo.material.options.forEach((option: any) => {
            if (inter.collection?.result.ops[option.id]) {
              valueMap[option.id] = percentageIntersect(inter.collection?.result.ops[option.id].participants,analyzeObj.getIntersecParticipants(inter)[option.id])
            }
          });
        } else {
          analyzeObj.testGroupInfo.material.options.forEach((option: any) => {
            valueMap[option.id] = 0;
          });
        }
        return valueMap;
      },
      intersect: [] as any[],
      originIntersect: computed(() => {
        const items = segments.value.map((segment: any) => {
          const item = {
            id: segment.id,
            name: segment.name,
            collection: null,
          };
          return item;
        });
        return [analyzeObj.overallSegment, ...items];
      }),
      filterSegment: {
        formIndex: 0,
        segments: [] as any[],
        curFilter: computed(() => {
          if (analyzeObj.filterSegment.segments.length == 0) {
            return null;
          }
          return analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex];
        }),
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return "--";
          }
          return selected
            .map((item: any) => {
              return item.text;
            })
            .join(",");
        },
        handleChange: async (options: any[]) => {
          analyzeObj.filterSegment.curFilter.selected = options;
          analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(
            options
          );
         
          await setCollection(
            analyzeObj.filterSegment.curFilter,
            analyzeObj.filterSegment.formIndex
          );
          analyzeObj.orderObj.refreshOrder();
        },
        
        reset: async () => {
          console.log(`reset`);
          analyzeObj.filterSegment.curFilter.selected = [];
          analyzeObj.filterSegment.curFilter.name = "--";
          setCollection(
            analyzeObj.filterSegment.curFilter,
            analyzeObj.filterSegment.formIndex
          );
          await analyzeObj.orderObj.clearAllOrder();
        },
      },
      filterIntersect: {
        formIndex: 0,
        segments: [] as any[],
        curFilter: computed(() => {
          if (analyzeObj.filterIntersect.segments.length == 0) {
            return null;
          }
          return analyzeObj.filterIntersect.segments[
            analyzeObj.filterIntersect.formIndex
          ];
        }),
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return "--";
          }
          return selected
            .map((item: any) => {
              return item.text;
            })
            .join(",");
        },
        handleChange: async (options: any[]) => {
          analyzeObj.filterIntersect.curFilter.selected = options;
          analyzeObj.filterIntersect.curFilter.name = analyzeObj.filterIntersect.getName(
            options
          );
          // analyzeObj.filterIntersect.segments[analyzeObj.filterIntersect.formIndex].name=analyzeObj.filterIntersect.getName(
          //   options
          // );
          await setIntersectCollection(
            analyzeObj.filterIntersect.curFilter,
            analyzeObj.filterIntersect.formIndex
          );
          analyzeObj.orderIntersect.refreshOrder();
        },
        reset: async () => {
          console.log(`reset`);
          analyzeObj.filterIntersect.curFilter.selected = [];
          analyzeObj.filterIntersect.curFilter.name = "--";
          setIntersectCollection(
            analyzeObj.filterIntersect.curFilter,
            analyzeObj.filterIntersect.formIndex
          );
          await analyzeObj.orderIntersect.clearAllOrder();
        },
      },
      filterConcept: {
        formIndex: 0,
        filtersName: root.$t("projectTestGroup.allSegment"),
        concepts: [] as any[],
        selected: [],
        curFilter: computed(() => {
          if (analyzeObj.filterConcept.concepts.length == 0) {
            return null;
          }
          return analyzeObj.filterConcept.concepts[analyzeObj.filterConcept.formIndex];
        }),
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return "--";
          }
          return selected
            .map((item: any) => {
              return item.text;
            })
            .join(",");
        },
        handleChange: async (options: any[]) => {
          console.log(`handleChange`);
          analyzeObj.filterConcept.selected = options;
          analyzeObj.filterConcept.filtersName = analyzeObj.filterConcept.getName(
            options
          );
      
          analyzeObj.testGroups.map((group: any, index: any) => {
            setTestGroupCollection(group, index);
          });
          analyzeObj.orderConcept.refreshOrder();
        },
        reset: async () => {
          analyzeObj.filterConcept.selected = [];
          analyzeObj.filterConcept.filtersName = "--";
          analyzeObj.testGroups.map((group: any, index: any) => {
            setTestGroupCollection(group, index);
          });
          await analyzeObj.orderConcept.clearAllOrder();
        },
      },
      orderConcept: {
        type: OrderType.None,
        curOrderSegment: {} as any,
        getType: (segment: any) => {
          if (segment.id !== analyzeObj.orderConcept.curOrderSegment.id) {
            return OrderType.None;
          }
          return analyzeObj.orderConcept.type;
        },
        clearAllOrder: () => {
          analyzeObj.orderConcept.type = OrderType.None;
          analyzeObj.orderConcept.refreshOrder();
        },
        changeOrder: (segment: any, orderType?: OrderType) => {
          if (isNil(segment.id)) {
            return;
          }

          if (segment.id != analyzeObj.orderConcept.curOrderSegment.id) {
            analyzeObj.orderConcept.type = OrderType.None;
            analyzeObj.orderConcept.curOrderSegment = segment;
          }
          const dataMap = analyzeObj.getDataMap(segment);
          let keys = Object.keys(dataMap).map((key) => Number(key));
          if (keys.length == 0) {
            return;
          }
          if (!orderType) {
            if (analyzeObj.orderConcept.type == OrderType.None) {
              orderType = OrderType.DESC;
            } else if (analyzeObj.orderConcept.type == OrderType.DESC) {
              orderType = OrderType.ASC;
            } else if (analyzeObj.orderConcept.type == OrderType.ASC) {
              orderType = OrderType.None;
            }
          }
          analyzeObj.orderConcept.type = orderType;

          const type = analyzeObj.orderConcept.type;
          switch (type) {
            case OrderType.None:
              keys = keys.sort((a, b) => a - b);
              break;
            case OrderType.DESC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.options.find((option: any) => option.id === a).sequence -
                    analyzeObj.options.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[b] - dataMap[a];
                }
              });
              break;
            case OrderType.ASC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.options.find((option: any) => option.id === a).sequence -
                    analyzeObj.options.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[a] - dataMap[b];
                }
              });
              break;
          }
          analyzeObj.options.sort((a: any, b: any) => {
            return keys.indexOf(a.id) - keys.indexOf(b.id);
          });
        },
        refreshOrder: () => {
          analyzeObj.orderConcept.changeOrder(
            analyzeObj.orderConcept.curOrderSegment,
            analyzeObj.orderConcept.type
          );
        },
        canReset: computed(() => {
          return analyzeObj.orderConcept.type != OrderType.None;
        }),
      },
      orderIntersect: {
        type: OrderType.None,
        curOrderSegment: {} as any,
        getType: (segment: any) => {
          if (segment.id !== analyzeObj.orderIntersect.curOrderSegment.id) {
            return OrderType.None;
          }
          return analyzeObj.orderIntersect.type;
        },
        clearAllOrder: () => {
          analyzeObj.orderIntersect.type = OrderType.None;
          analyzeObj.orderIntersect.refreshOrder();
        },
        changeOrder: (segment: any,id:any, orderType?: OrderType) => {
          console.log(id)
          if (isNil(segment.id)) {
            return;
          }
          if (segment.id != analyzeObj.orderIntersect.curOrderSegment.id) {
            analyzeObj.orderIntersect.type = OrderType.None;
            analyzeObj.orderIntersect.curOrderSegment = segment;
          }
          let dataMap = analyzeObj.getIntersectMap(segment,analyzeObj.getIntersecParticipants(segment)[id]);
          let keys = Object.keys(dataMap).map((key) => Number(key));
          if (keys.length == 0) {
            return;
          }
          if (!orderType) {
            if (analyzeObj.orderIntersect.type == OrderType.None) {
              orderType = OrderType.DESC;
            } else if (analyzeObj.orderIntersect.type == OrderType.DESC) {
              orderType = OrderType.ASC;
            } else if (analyzeObj.orderIntersect.type == OrderType.ASC) {
              orderType = OrderType.None;
            }
          }
          analyzeObj.orderIntersect.type = orderType;

          const type = analyzeObj.orderIntersect.type;
          switch (type) {
            case OrderType.None:
              keys = keys.sort((a, b) => a - b);
              break;
            case OrderType.DESC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.testGroups.find((option: any) => option.id === a)
                      .sequence -
                    analyzeObj.testGroups.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[b] - dataMap[a];
                }
              });
              break;
            case OrderType.ASC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return (
                    analyzeObj.testGroups.find((option: any) => option.id === a)
                      .sequence -
                    analyzeObj.testGroups.find((option: any) => option.id === b).sequence
                  );
                } else {
                  return dataMap[a] - dataMap[b];
                }
              });
              break;
          }
          analyzeObj.testGroups.sort((a: any, b: any) => {
            return keys.indexOf(a.id) - keys.indexOf(b.id);
          });
        },
        refreshOrder: () => {
          analyzeObj.orderIntersect.changeOrder(
            analyzeObj.orderIntersect.curOrderSegment,
            0,
            analyzeObj.orderIntersect.type
          );
        },
        canReset: computed(() => {
          return analyzeObj.orderIntersect.type != OrderType.None;
        }),
      },

      enableClass: (segment: any, itemSeg: any) => {
        if (segment.id >= 0) {
          return segment.id == itemSeg.id ? "item-enable" : "";
        } else {
          return itemSeg.id < 0 ? "item-enable" : "";
        }
      },
      getTestGroupInfo: async () => {
        analyzeObj.testGroupInfo = await getTestGroupInfoApi({
          uuid: props.item?.message.project_uuid,
          testGroupId: props.item?.message.test_group.id,
          observer: observer
        });
        analyzeObj.selectedConcept.id = analyzeObj.testGroupInfo?.material.options[0]?.id;
        analyzeObj.selectedConcept.text =
          analyzeObj.testGroupInfo?.material.options[0]?.text;
        analyzeObj.testGroupInfo?.material.options.map((item: any, index: any) => {
          let obj = {
            id: item.id,
            name: item.text,
            value: item.id,
            selected: [],
            link: item.link,
            options: analyzeObj.testGroupInfo.material.options,
            entity_type: analyzeObj.testGroupInfo.material.entity_type,
            collection: null,
            collections: null,
          };
          analyzeObj.testGroups.push(obj);
          analyzeObj.filterConcept.concepts.push(obj);
          setTestGroupCollection(obj, index);
        });
      },
      handleTypes: async (id: number) => {
        if (analyzeObj.selectedAnalyzeType == id) return;
        analyzeObj.selectedAnalyzeType = id;
        showConceptFilters.value = false;
        showFilters.value = false;
        showIntersectFilters.value = false;
        if (analyzeObj.selectedAnalyzeType == 2) {
          analyzeObj.segments.forEach((segment: any, index: any) => {
            setCollection(segment, index);
          });
        } else if (analyzeObj.selectedAnalyzeType == 3) {
          // getIntersect()
          analyzeObj.intersect.forEach((segment: any, index: any) => {
            setIntersectCollection(segment, index);
          });
        }else{
          analyzeObj.testGroups.map((group: any, index: any) => {
              setTestGroupCollection(group, index)
            })
        }
      },
      chageTestGroupRound: (item: any) => {
        if( analyzeObj.selectedRounds.value==item.index) return
        analyzeObj.selectedRounds.value = item.index;
        analyzeObj.selectedRounds.text = item.text;
        if (analyzeObj.selectedAnalyzeType == 1) {
          analyzeObj.testGroups.map((group: any, index: any) => {
            group.collection = deepClone(
              getCollection(group.collections, analyzeObj.selectedRounds.value)
            );
            analyzeObj.filterConcept.concepts[index].collection = deepClone(
              getCollection(group.collections, analyzeObj.selectedRounds.value)
            );
          });
        } else if (analyzeObj.selectedAnalyzeType == 2) {
          analyzeObj.segments.map((segment: any, index: any) => {
            segment.collection = deepClone(
              getCollection(segment.collections, analyzeObj.selectedRounds.value)
            );
            analyzeObj.filterSegment.segments[index].collection = deepClone(
              getCollection(segment.collections, analyzeObj.selectedRounds.value)
            );
          });
        } else if (analyzeObj.selectedAnalyzeType == 3) {
          analyzeObj.intersect.map((segment: any, index: any) => {
            segment.collection = deepClone(
              getIntersectCollection(segment.collections, analyzeObj.selectedRounds.value)
            );
            analyzeObj.filterIntersect.segments[index].collection = deepClone(
              getIntersectCollection(segment.collections, analyzeObj.selectedRounds.value)
            );
          });
        }
      },
      chageConcept: (item: any) => {

        let obj = {
          id: item.id,
          name: item.text,
          value: item.value,
          selected: [],
          options: analyzeObj.testGroupInfo.material.options,
          collection: null,
          collections: null,
        };
        analyzeObj.testGroups[item.index].value = item.value;
        analyzeObj.testGroups[item.index].name = item.text;
        analyzeObj.testGroups[item.index].link = item.link;
        setTestGroupCollection(obj, item.index);
      },
      handleConcept: async (item: any) => {
        if( analyzeObj.selectedConcept.id==item.id)return
        analyzeObj.selectedConcept.id = item.id;
        analyzeObj.selectedConcept.text = item.text;
        if (showConceptFilters.value) {
          await setCollection(
            analyzeObj.filterSegment.curFilter,
            analyzeObj.filterSegment.formIndex
          );
          analyzeObj.orderObj.refreshOrder();
        } else {
          analyzeObj.segments.forEach((segment: any, index: any) => {
            setCollection(segment, index);
          });
        }
      },
      handleSegment: async (item: any) => {
        if (!item.segment) {
          if (analyzeObj.filterConcept.selected.length <= 0) {
            analyzeObj.filterConcept.filtersName = "--";
          } else {
            //
            analyzeObj.filterConcept.filtersName = analyzeObj.filterConcept.getName(
              analyzeObj.filterConcept.selected
            );
          }
          console.log(analyzeObj.filterConcept.selected);
          showConceptFilters.value = true;
          analyzeObj.selectedSegment = -1;
          analyzeObj.testGroups.map((group: any, index: any) => {
            setTestGroupCollection(group, index);
          });
        } else {
        if( analyzeObj.selectedSegment==item.segment.id)return
          showConceptFilters.value = false;
          analyzeObj.selectedSegment = item.segment.id;
          analyzeObj.filterConcept.filtersName = item.segment.name;
          analyzeObj.testGroups.map((group: any, index: any) => {
            setTestGroupCollection(group, index);
          });
        }
      },
      handleOptions: async (item: any) => {
        if(analyzeObj.selectedOptions.id==item.id)return
        analyzeObj.selectedOptions.id = item.id;
        analyzeObj.selectedOptions.text = item.text;
        analyzeObj.intersect.forEach((segment: any, index: any) => {
          setIntersectCollection(segment, index);
        });
      },
      showVideoDialog: (url: string) => {
        if (url) {
          videoDialogRef.value.open(url);
        }
      },
    });

    watch(
      () => analyzeObj.originSegments,
      () => {
        getSegments([], []);
        getIntersect([], []);
      }
    );

    onMounted(() => {
      initData();
    });

    onUnmounted(() => {
      analyzeObj.segments.forEach((segment: any) => {
        segment.collection = null;
      });
      analyzeObj.filterSegment.segments.forEach((segment: any) => {
        segment.collection = null;
      });
      updateAnalyzeConceptPoll(
        props.item?.message.project_uuid,
        analyzeObj,
        props.item?.message.message_entity_id
      );
    });
    const initData = async () => {
      analyzeObj.currentRound = props.item?.message.test_group.current_round;
      let analyzeConceptPoll = getAnalyzeConceptPoll(
        props.item?.message.project_uuid,
        props.item?.message.message_entity_id
      );
      analyzeObj.options = props.item?.message.message_options.map((option: any) => {
        return {
          id: option.id,
          text: option.text,
          link: option.link,
          sequence: option.sequence,
        };
      });
      analyzeObj.selectedOptions.id = analyzeObj.options[0].id;
      analyzeObj.selectedOptions.text = analyzeObj.options[0].text;
      
      if (analyzeConceptPoll != null) {
        analyzeObj.selectedAnalyzeType = analyzeConceptPoll.selectedAnalyzeType;
        analyzeObj.selectedSegment = analyzeConceptPoll.selectedSegment;
        analyzeObj.selectedConcept = analyzeConceptPoll.selectedConcept;
        analyzeObj.groups = analyzeConceptPoll.groups;
        analyzeObj.selectedRounds = analyzeConceptPoll.selectedRounds;
        analyzeObj.selectedOptions = analyzeConceptPoll.selectedOptions;
        analyzeObj.options = analyzeConceptPoll.options;
        analyzeObj.testGroupInfo = analyzeConceptPoll.testGroupInfo;
        analyzeObj.testGroups = analyzeConceptPoll.testGroups;
        analyzeObj.segments = analyzeConceptPoll.segments;
        analyzeObj.intersect = analyzeConceptPoll.intersect;
        analyzeObj.filterConcept.selected = analyzeConceptPoll.filterConcept.selected;
        analyzeObj.filterConcept.filtersName =analyzeConceptPoll.filterConcept.filtersName;
        if(analyzeObj.selectedSegment>-1){
          let segmentId=analyzeObj.originSegments.filter((item:any)=>{return item.id==analyzeObj.selectedSegment})
          if(segmentId.length>0){
            analyzeObj.filterConcept.filtersName=segmentId[0].name
          }else{
            analyzeObj.selectedSegment =analyzeObj.originSegments[0].id;
          analyzeObj.filterConcept.filtersName = analyzeObj.originSegments[0].name;
            analyzeObj.testGroups.map((group: any, index: any) => {
              setTestGroupCollection(group, index)
            })
          }
        }

        
        analyzeObj.filterConcept.concepts = analyzeConceptPoll.filterConcept.concepts;
        getSegments(
          analyzeConceptPoll.segments,
          analyzeConceptPoll.filterSegment.segments
        );
        getIntersect(
          analyzeConceptPoll.intersect,
          analyzeConceptPoll.filterIntersect.segments
        );
      } else {
        analyzeObj.getTestGroupInfo();
        getSegments([], []);
        getIntersect([], []);
      }
    };

    const setCollection = async (item: any, index: any) => {
      if (item.id > -1) {
        const res = await getTestGroupMessageAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          tgid: props.item?.message.test_group.id,
          project_uuid: props.item?.message.project_uuid,
          test_group_id: props.item?.message.test_group.id,
          test_option_id: analyzeObj.selectedConcept.id,
          segment_id: item.id,
          message_entity_id: props.item?.message.message_entity_id,
          type: analyzeObj.selectedAnalyzeType,
          observer: observer
        });
        if (res.collections.length > 0) {
          // console.log("filterSegments", analyzeObj.filterSegment, index);
          analyzeObj.segments[index].collections = res.collections;
          analyzeObj.segments[index].collection = getCollection(
            res.collections,
            analyzeObj.selectedRounds.value
          );
          analyzeObj.filterSegment.segments[index].collections = res.collections;
          analyzeObj.filterSegment.segments[index].collection =
            analyzeObj.segments[index].collection;
        }
      } else {
        if (item.collections != null && item.selected.length > 0) {
          const optionIds = item.selected.map((option: any) => option.id);
          const res = await getTestGroupMessageAnalyzeApi({
            uuid: props.item?.message.project_uuid,
            tgid: props.item?.message.test_group.id,
            project_uuid: props.item?.message.project_uuid,
            test_group_id: props.item?.message.test_group.id,
            filter_option_ids: optionIds,
            test_option_id: analyzeObj.selectedConcept.id,
            segment_id: item.id,
            message_entity_id: props.item?.message.message_entity_id,
            type: analyzeObj.selectedAnalyzeType,
            observer: observer
          });
          if (res.collections.length > 0) {
            analyzeObj.segments[index].collections = res.collections;
            analyzeObj.segments[index].collection = getCollection(
              res.collections,
              analyzeObj.selectedRounds.value
            );
            analyzeObj.filterSegment.segments[index].collections = res.collections;
            analyzeObj.filterSegment.segments[index].collection =
              analyzeObj.segments[index].collection;
          }
        } else {
          analyzeObj.segments[index].collections = [];
          analyzeObj.segments[index].collection = null;
          analyzeObj.filterSegment.segments[index].collections = [];
          analyzeObj.filterSegment.segments[index].collection = null;
        }
      }
    };

    const getSegments = (segs: any, filterSegments: any) => {
      analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : [];
      // 新增: 创建一个基于 originSegments 的 ID 集合
      const originSegmentIds = new Set(analyzeObj.originSegments.map((segment:any)=> segment.oldId));
      analyzeObj.segments = analyzeObj.segments.filter((segment:any)=> originSegmentIds.has(segment.oldId));
      analyzeObj.originSegments.forEach((segmentOrigin:any) => {
        let segment = analyzeObj.segments.find((s:any)=> s.oldId == segmentOrigin.oldId);
        if (!segment) {
          segment = cloneDeep(segmentOrigin);
          analyzeObj.segments.push(segment);
        } else {
          if(segment.id>-1){
            segment.name =  analyzeObj.originSegments.filter((item:any)=> segment.id==item.id)[0].name;
          }
        }
      });

      analyzeObj.filterSegment.segments =
        filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : [];
        if (analyzeObj.filterSegment.segments.length <= 0) {
          analyzeObj.segments.forEach((segment:any,index:any)=>{
            analyzeObj.filterSegment.segments.push({
              id: -1 - index,
              name: "--",
              oldId:segment.oldId,
              selected: [],
              collection: null,
              collections: null,
            });
          })
      }
      const ids = segments.value.map((item: any) => item.id) ?? [];

      analyzeObj.segments.forEach((segment: any, index: any) => {
        if (segment.id > 0 && !ids.includes(segment.id)) {
          segment.name = "--";
          segment.id = null;
          segment.collection = null;
          segment.selected = [];
        }
        setCollection(segment, index);
      });
    };

    const getIntersect = (segs: any, filterSegments: any) => {
      analyzeObj.intersect = segs && segs.length > 0 ? cloneDeep(segs) : [];
      // 新增: 创建一个基于 originSegments 的 ID 集合
      const originSegmentIds = new Set(analyzeObj.originSegments.map((segment:any)=> segment.oldId));
      analyzeObj.intersect = analyzeObj.intersect.filter((segment:any)=> originSegmentIds.has(segment.oldId));
      analyzeObj.originSegments.forEach((segmentOrigin:any) => {
        let segment = analyzeObj.intersect.find((s:any)=> s.oldId == segmentOrigin.oldId);
        if (!segment) {
          segment = cloneDeep(segmentOrigin);
          analyzeObj.intersect.push(segment);
        } else {
          if(segment.id>-1){
            segment.name =  analyzeObj.originSegments.filter((item:any)=> segment.id==item.id)[0].name;
          }
        }
      });

      analyzeObj.filterIntersect.segments =
        filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : [];
        if (analyzeObj.filterIntersect.segments.length <= 0) {
          analyzeObj.intersect.forEach((segment: any, index: any)=>{
            analyzeObj.filterIntersect.segments.push({
              id: -1 - index,
              name: "--",
              oldId:segment.oldId,
              selected: [],
              collection: null,
              collections: null,
            });
          })
      }

      const ids = segments.value.map((item: any) => item.id) ?? [];
      console.log(`样本分组IDs: ${ids}`);
      analyzeObj.intersect.forEach((segment: any, index: any) => {
        if (segment.id > 0 && !ids.includes(segment.id)) {
          segment.name = "--";
          segment.id = null;
          segment.collection = null;
          segment.selected = [];
          segment.conceptId = null;
        }
        // setIntersectCollection(segment,index)
      });
    };

    const setIntersectCollection = async (item: any, index: any) => {
      if (item.id > -1) {
        const res = await getTestGroupMessageAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          tgid: props.item?.message.test_group.id,
          project_uuid: props.item?.message.project_uuid,
          test_group_id: props.item?.message.test_group.id,
          message_option_id: analyzeObj.selectedOptions.id,
          segment_id: item.id,
          message_entity_id: props.item?.message.message_entity_id,
          type: analyzeObj.selectedAnalyzeType,
          observer: observer
        });
        if (res.collections.length > 0) {
          analyzeObj.intersect[index].collections = res.collections;
          analyzeObj.intersect[index].collection = getIntersectCollection(
            res.collections,
            analyzeObj.selectedRounds.value
          );
          analyzeObj.filterIntersect.segments[index].collections = res.collections;
          analyzeObj.filterIntersect.segments[index].collection =
            analyzeObj.intersect[index].collection;
        }
      } else {
        if (item.selected.length > 0) {
          const optionIds = item.selected.map((option: any) => option.id);
          const res = await getTestGroupMessageAnalyzeApi({
            uuid: props.item?.message.project_uuid,
            tgid: props.item?.message.test_group.id,
            project_uuid: props.item?.message.project_uuid,
            test_group_id: props.item?.message.test_group.id,
            filter_option_ids: optionIds,
            message_option_id: analyzeObj.selectedOptions.id,
            segment_id: item.id,
            message_entity_id: props.item?.message.message_entity_id,
            type: analyzeObj.selectedAnalyzeType,
            observer: observer
          });
          if (res.collections.length > 0) {
            analyzeObj.intersect[index].collections = res.collections;
            analyzeObj.intersect[index].collection = getIntersectCollection(
              res.collections,
              analyzeObj.selectedRounds.value
            );
            analyzeObj.filterIntersect.segments[index].collections = res.collections;
            analyzeObj.filterIntersect.segments[index].collection =
              analyzeObj.intersect[index].collection;
          }
        } else {
          analyzeObj.intersect[index].collections = [];
          analyzeObj.intersect[index].collection = null;
          analyzeObj.filterIntersect.segments[index].collections = [];
          analyzeObj.filterIntersect.segments[index].collection = null;
        }
      }
    };

    const setTestGroupCollection = async (item: any, index: any) => {
      if (showConceptFilters.value) {
        const selected = analyzeObj.filterConcept.selected;
        if (selected.length > 0) {
          const optionIds = selected.map((option: any) => option.id);
          const res = await getTestGroupMessageAnalyzeApi({
            uuid: props.item?.message.project_uuid,
            tgid: props.item?.message.test_group.id,
            project_uuid: props.item?.message.project_uuid,
            test_group_id: props.item?.message.test_group.id,
            test_option_id: item.value,
            filter_option_ids: optionIds,
            message_entity_id: props.item?.message.message_entity_id,
            type: analyzeObj.selectedAnalyzeType,
            observer: observer
          });
          if (res.collections.length > 0) {
            analyzeObj.testGroups[index].collections = res.collections;
            analyzeObj.testGroups[index].collection = getCollection(
              res.collections,
              analyzeObj.selectedRounds.value
            );
            analyzeObj.filterConcept.concepts[index].collections = res.collections;
            analyzeObj.filterConcept.concepts[index].collection =
              analyzeObj.testGroups[index].collection;
          }
        } else {
          analyzeObj.testGroups[index].collections = [];
          analyzeObj.testGroups[index].collection = null;
          analyzeObj.filterConcept.concepts[index].collections = [];
          analyzeObj.filterConcept.concepts[index].collection = null;
        }
      } else {
        const res = await getTestGroupMessageAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          tgid: props.item?.message.test_group.id,
          project_uuid: props.item?.message.project_uuid,
          test_group_id: props.item?.message.test_group.id,
          test_option_id: item.value,
          segment_id: analyzeObj.selectedSegment,
          message_entity_id: props.item?.message.message_entity_id,
          type: analyzeObj.selectedAnalyzeType,
          observer: observer
        });
        if (res.collections.length > 0) {
          analyzeObj.testGroups[index].collections = res.collections;
          analyzeObj.testGroups[index].collection = getCollection(
            res.collections,
            analyzeObj.selectedRounds.value
          );
          analyzeObj.filterConcept.concepts[index].collections = res.collections;
          analyzeObj.filterConcept.concepts[index].collection =
            analyzeObj.testGroups[index].collection;
        }
      }
    };

    const percentage = (option: any, result: any) => {
      let percentage = 0;
      if (result) {
        const id = option.id ? String(option.id) : "";
        const opParticipants = result.ops[id]?.participants ?? 0;
        const allParticipants = result.participants;
        if (allParticipants == 0) {
          return percentage;
        }
        percentage = getPercentage(opParticipants, allParticipants);
      }
      return percentage;
    };

    const percentageIntersect=(opParticipants:any,allParticipants:any)=>{
      let percentage = 0;
      percentage = getPercentage(opParticipants, allParticipants);
      return percentage;
    }

    const getCollection = (data: any, index: any) => {
      if (index > -1) {
        let collection = data
          ? data.filter((item: any) => item.message.message_round - 1 == index)
          : [];
        return collection.length > 0 ? collection[0] : null;
      } else {
        let collection = data ? deepClone(data[0]) : { result: {} };
        collection.result = {
          ops: {},
          participants: 0,
        };
        analyzeObj.options.map((option: any) => {
          collection.result.ops[option.id] = {
            id: option.id,
            text: option.text,
            participants: 0,
            participant_tokens: [],
            sequence: option.sequence,
          };
        });
        data.map((item: any) => {
          if (item.result != null) {
            collection.result.participants += item.result.participants;
            Object.keys(item.result.ops).map((key: any) => {
              collection.result.ops[key].participants +=
                item.result.ops[key].participants;
              if (item.result.ops[key].participant_tokens != null) {
                collection.result.ops[key].participant_tokens = collection.result.ops[
                  key
                ].participant_tokens.concat(item.result.ops[key].participant_tokens);
              }
            });
          }
        });
        return collection;
      }
    };

    // getConceptCollection()

    const getIntersectCollection = (data: any, index: any) => {
      if (index > -1) {
        let collection = data
          ? data.filter((item: any) => item.message.message_round - 1 == index)
          : [];
        return collection.length > 0 ? collection[0] : null;
      } else {
        let collection = data ? deepClone(data[0]) : { result: {} };
        collection.result = {
          ops: {},
          participants: 0,
        };
        analyzeObj.testGroupInfo.material.options.map((option: any) => {
          collection.result.ops[option.id] = {
            id: option.id,
            text: option.text,
            participants: 0,
            participant_tokens: [],
            sequence: option.sequence,
          };
        });
        data.map((item: any) => {
          if (item.result != null) {
            collection.result.participants += item.result.participants;
            Object.keys(item.result.ops).map((key: any) => {
              if (item.result.ops[key].participant_tokens != null) {
                collection.result.ops[key].participant_tokens = collection.result.ops[
                  key
                ].participant_tokens.concat(item.result.ops[key].participant_tokens);
              }
              collection.result.ops[key].participants +=
                item.result.ops[key].participants;
            });
          }
        });
        return collection;
      }
    };

    //已封装的深拷贝函数
    const deepClone = (obj: any = {}, hashMap = new WeakMap()) => {
      let objClone: any = null,
        hashKey = hashMap.get(obj);
      if (obj instanceof RegExp) return new RegExp(obj);
      if (obj instanceof Date) return new Date(obj);
      if (hashKey) return hashKey;
      //判断是否需要继续进行递归
      if (typeof obj == "object" && obj !== null) {
        objClone = obj instanceof Array ? [] : {};
        hashMap.set(obj, objClone);
        //进行下一层递归克隆
        for (const i in obj) {
          if (obj.hasOwnProperty(i)) {
            objClone[i] = deepClone(obj[i], hashMap);
          }
        }
      } else {
        objClone = obj;
      }

      return objClone;
    };

    const handleCommand = async (item: any) => {
      analyzeObj.clickSegmentIndex = item.index;

      if (!item.segment) {
        const segment = analyzeObj.filterSegment.segments[item.index];
        analyzeObj.filterSegment.formIndex =
          item.index ?? analyzeObj.filterSegment.formIndex ?? 0;
        analyzeObj.segments[analyzeObj.filterSegment.formIndex] = deepClone(segment);
        showFilters.value = true;
        await setCollection(
          analyzeObj.segments[item.index],
          analyzeObj.filterSegment.formIndex
        );
      } else {
        showFilters.value = false;
        let segmentItem = analyzeObj.originSegments.find((segment: any) => {
          return segment.id === item.segment.id;
        });
        if (segmentItem) {
          await setCollection(segmentItem, item.index);
          analyzeObj.segments[item.index].id = segmentItem.id;
          analyzeObj.segments[item.index].name = segmentItem.name;
          item.index = item.index ?? analyzeObj.filterIntersect.formIndex ?? 0;
          analyzeObj.segments.splice(
            item.index,
            1,
            JSON.parse(JSON.stringify(analyzeObj.segments[item.index]))
          );
        } else {
          const segment =
            analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex];
          analyzeObj.segments[analyzeObj.filterSegment.formIndex] = deepClone(segment);
        }
      }
    };
    const handleIntersectCommand = async (item: any) => {
      if (!item.segment) {
        const segment = analyzeObj.filterIntersect.segments[item.index];
        analyzeObj.intersect[item.index] = deepClone(segment);
        analyzeObj.filterIntersect.formIndex =
          item.index ?? analyzeObj.filterIntersect.formIndex ?? 0;
        showIntersectFilters.value = true;
        await setIntersectCollection(
          analyzeObj.intersect[item.index],
          analyzeObj.filterIntersect.formIndex
        );
      } else {
        showIntersectFilters.value = false;
        let segmentItem = analyzeObj.originIntersect.find((segment: any) => {
          return segment.id === item.segment.id;
        });
        if (segmentItem) {
          await setIntersectCollection(segmentItem, item.index);
          analyzeObj.intersect[item.index].id = segmentItem.id;
          analyzeObj.intersect[item.index].name = segmentItem.name;
          item.index = item.index ?? analyzeObj.filterIntersect.formIndex ?? 0;
          analyzeObj.intersect.splice(
            item.index,
            1,
            JSON.parse(JSON.stringify(analyzeObj.intersect[item.index]))
          );
        } else {
          const segment =
            analyzeObj.filterIntersect.segments[analyzeObj.filterSegment.formIndex];
          analyzeObj.intersect[analyzeObj.filterSegment.formIndex] = deepClone(segment);
        }
      }
    };

    return {
      EntityType,
      OrderType,
      showDrawer,
      showFilters,
      showConceptFilters,
      showIntersectFilters,
      analyzeObj,
      handleCommand,
      getPercentageStyle,
      onClosed,
      handleIntersectCommand,
      videoDialogRef,
      handleDropdownVisibleChange,
      tooltipRef
    };
  },
});
