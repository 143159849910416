





























import { defineComponent, reactive, ref,watch ,computed} from '@vue/composition-api'
import { Drawer } from 'element-ui'
import { useProjectStore } from '@/pinia/modules/project'
import ProjectGroupList from './ProjectGroupList.vue'
import ProjectGroupEdit from './ProjectGroupEdit.vue'
export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        ProjectGroupList,
        ProjectGroupEdit
    },
    setup(props, { root, emit }) {
        const isShowDrawer = ref(false)
        const showType = ref(1)
        const projectStore = useProjectStore()
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const projectGroupListRef = ref()
        const projectGroupEditRef = ref()
        const segmentObj = reactive({
            edit: (item: any) => {
                showType.value = 2
                projectGroupEditRef.value.segmentObj.edit(item)
            },
            add: () => {
                showType.value = 2
                projectGroupEditRef.value.segmentObj.add()
            },
            cancel: () => {
                showType.value = 1
            },
            update: () => {
                showType.value = 1
            }
        })

        watch(
            () => isShowDrawer.value,
            (newVal, oldVal) => {
                if (newVal) {
                    projectStore.getProjectSegments(projectUuId.value)
                }
            }
        )

        return {
            isShowDrawer,
            projectGroupListRef,
            projectGroupEditRef,
            segmentObj,
            showType
        }
    }
})
