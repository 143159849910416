
















import { defineComponent, onMounted, reactive, ref, watch, nextTick } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default defineComponent({
    components: { quillEditor },
    props: {
        value: {
            type: String
        },
        height: {
            type: Number,
            default: 100
        },
        disabled: {
            type: Boolean,
            default: false
        },
        customPlaceholder: {
            type: Number,
            default: 0
        }
    },
    setup(props, { root, emit }) {
        const quillEditorRef = ref()
        const content = ref(props.value)

        watch(
            () => props.value,
            () => {
                setEditorCursor()
            }
        )

        watch(
            () => props.disabled,
            () => quillEditorRef.value.quill.enable(!props.disabled)
        )

        const setEditorCursor = () => {
            nextTick(() => {
                const quill = quillEditorRef.value.quill
                if (quill.hasFocus()) {
                    const range = quill.getSelection()
                    if (range) {
                        content.value = props.value
                        setTimeout(() => quill.setSelection(range.index, 0))
                    } else {
                        content.value = props.value
                    }
                } else {
                    content.value = props.value
                }
            })
        }

        const selectPlaceholder=(index:number)=>{
           if(index==0){
            return root.$t('editor.placeholder')
           }else if(index==1){
            return root.$t('editor.placeholder1')
           }else {
            return root.$t('editor.placeholder')
           }
        }

        const editorOption = reactive({
            placeholder: selectPlaceholder(props.customPlaceholder),
            modules: {
                toolbar: [['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, 'link']]
            }
        })

        const onEditorReady = (editor: any) => {

            editor.container.style.height = `${props.height}px`
        }

        onMounted(() => {
            if (quillEditorRef.value.quill && !props.disabled) {
                quillEditorRef.value.quill.enable(false)
                setTimeout(() => {
                    if (quillEditorRef.value.quill && !props.disabled) {
                        quillEditorRef.value.quill.enable(true)
                        quillEditorRef.value.quill.blur()
                    }
                }, 100)
            }
        })

        const onEditorChange = (e: any) => {
            emit('input', e.html)
            emit('change', e.html)
        }

        const onEditorFocus = (quill: any) => {
            console.log('onEditorFocus')
            const tooltip: any = quill.container.querySelector('.ql-tooltip')
            const editor: any = quill.container.querySelector('.ql-editor')


            const height = editor.scrollHeight
            const width = quill.container.offsetWidth

            const t_height = tooltip.offsetHeight
            const t_width = tooltip.offsetWidth
            const t_left = tooltip.offsetLeft
            const t_top = tooltip.offsetTop


            if (t_height + t_top + 10 > height) {
                tooltip.style.top = `${height - t_height - 20}px`
            }
            if (t_width + t_left + 10 > width) {
                tooltip.style.left = `${width - t_width - 20}px`
            }

            if (t_left <= 0) {
                tooltip.style.left = `${0}px`
                tooltip.classList.remove('ql-location')
            }
        }

        const onEditorBlur = (quill: any) => {
            const tooltip: any = quill.container.querySelector('.ql-tooltip')
            const editor: any = quill.container.querySelector('.ql-editor')

            console.log('onEditorBlur')
            const height = editor.scrollHeight
            const width = quill.container.offsetWidth

            const t_height = tooltip.offsetHeight
            const t_width = tooltip.offsetWidth
            const t_left = tooltip.offsetLeft
            const t_top = tooltip.offsetTop


            if (t_height + t_top + 10 > height) {
                tooltip.style.top = `${height - t_height - 20}px`
            }
            if (t_width + t_left + 10 > width) {
                tooltip.style.left = `${width - t_width - 20}px`
            }

            if (t_left <= 0) {
                tooltip.style.left = `${0}px`
                tooltip.classList.add('ql-location')
            }
        }

       

        return {
            quillEditorRef,
            content,
            onEditorReady,
            onEditorChange,
            onEditorFocus,
            onEditorBlur,
            editorOption,
            selectPlaceholder
        }
    }
})
