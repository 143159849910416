












import { defineComponent, reactive, Ref, ref, onMounted, watch } from '@vue/composition-api'
import * as echarts from 'echarts'
import 'echarts-wordcloud'
import { otherShapeMap, wordColors } from '@/utils/word-cloud'
import { max, min, debounce } from 'lodash'
import { Empty } from 'element-ui'
const defaultWidth = 440
export default defineComponent({
    props: {
        width: {
            type: Number,
            default: defaultWidth
        },
        height: {
            type: Number,
            default: defaultWidth
        },
        list: {
            type: Array,
            default: []
        },
        shape: {
            type: String,
            default: 'shape1'
        },
        colors: {
            type: Array,
            default: () => wordColors[0].list
        },
        loading: {
            type: Boolean,
            default: false
        },
        debounceDuration: {
            type: Number,
            default: 0
        }
    },
    components: {
        [Empty.name]: Empty
    },
    setup(props, { root, emit }) {
        const wordId = ref(`echarts-word-cloud-${new Date().getTime()}-${Math.random() * 100000000000000000}`)
        const wordCloudViewRef: Ref<any> = ref(null)
        const wordCloudOptions = reactive({})

        const onDrawWordCloudView = () => {
            if (wordCloudViewRef.value) {
                wordCloudViewRef.value.dispose()
            }
            wordCloudViewRef.value = document.getElementById(wordId.value) && echarts.init(document.getElementById(wordId.value) as any)
            wordCloudViewRef.value && wordCloudViewRef.value.setOption(wordCloudOptions)
        }


        const initMaskImage = () => {
            return new Promise((resolve, reject) => {
                const otherShape = otherShapeMap[props.shape]
                if (otherShape) {
                    const maskImage = new Image()
                    maskImage.src = otherShape
                    maskImage.onload = () => {
                        resolve(maskImage)
                    }
                    maskImage.onerror = () => {
                        resolve(null)
                    }
                } else {
                    resolve(null)
                }
            })
        }


        const initViewData = async () => {
            const maskImage = await initMaskImage()
            const maxValue = max(props.list.map((v: any) => v.value))
            const minValue = min(props.list.map((v: any) => v.value))
            const isSameValue = maxValue - minValue == 0
            const listLength = props.list.length
            let layoutDensity = props.width / defaultWidth

            let fontMin = 15 * (props.width / 500)
            let fontMax = 50 * (props.width / 500)
            let gridSize = 10 * (props.width / 500)

            wordCloudViewRef.value.setOption({
                series: [
                    {
                        type: 'wordCloud',
                        shape: props.shape,
                        clickable: true,
                        keepAspect: true,
                        width: '100%',
                        height: '100%',
                        maskImage: maskImage,
                        sizeRange: [fontMin, fontMax],
                        rotationRange: [0, 0],
                        rotationStep: 1,
                        gridSize: gridSize,
                        drawOutOfBound: false,
                        layoutAnimation: true,
                        shrinkToFit: true,

                        textStyle: {
                            fontFamily: 'sans-serif',
                            fontWeight: 'bold',
                            color: () => {
                                const colors = props.colors
                                return colors[parseInt(String(Math.random() * colors.length))]
                            }
                        },

                        data: props.list
                    }
                ],
                backgroundColor: '#fff',
                tooltip: {
                    show: true,
                    trigger: 'item',
                    triggerOn: 'mousemove|click',
                    axisPointer: {
                        type: 'line'
                    },
                    textStyle: {
                        fontSize: 14,
                        fontWeight: 'normal'
                    },
                    borderWidth: 0
                }
            })

            wordCloudViewRef.value.on('click', (params: any) => emit('clickWord', params))
        }

        onMounted(() => {
            onDrawWordCloudView()
            initViewData()
        })

        const dataURLtoBlob = (dataurl: any) => {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], { type: mime })
        }

        const downloadImage = (url: string) => {
            const a = document.createElement('a')
            const clickEvent = document.createEvent('MouseEvents')
            a.setAttribute('href', url)
            a.setAttribute('download', 'word-cloud')
            a.setAttribute('target', '_blank')
            clickEvent.initEvent('click', true, true)
            a.dispatchEvent(clickEvent)
        }

        const downloadWord = () => {
            const myBlob = dataURLtoBlob(
                wordCloudViewRef.value.getDataURL({
                    pixelRatio: 2,
                    backgroundColor: '#fff'
                })
            )

            const myUrl = URL.createObjectURL(myBlob)

            downloadImage(myUrl)
        }

        const updateViewData = debounce(() => initViewData(), 1000 * props.debounceDuration)

        watch(
            () => [props.shape, props.colors, props.list],
            (oldVal, newVal) => {
                if (JSON.stringify(oldVal) !== JSON.stringify(newVal) ) {
                    updateViewData()
                }
            },
            {
                deep: true
            }
        )

        return {
            wordId,
            downloadWord,
            initViewData
        }
    }
})
