
























import { defineComponent, inject, ref } from '@vue/composition-api'
import { EntityType, ProjectFormType } from '@/api/project/model'
export default defineComponent({
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')

        const isCollapse = ref(false)

        return {
            EntityType,
            ProjectFormType,
            isCollapse,
            itemInfo
        }
    }
})
