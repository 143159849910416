







































import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'

export default defineComponent({
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        const isCollapse = ref(false)

        const pictureList = computed(() => {
            return itemInfo.message_entity.options.filter((v: any) => !v.is_system && v.link)
        })


        const previewPictureList = computed(() => {
            return itemInfo.message_entity.options.filter((v: any) => !v.is_system && v.link).map((v: any) => v.link)
        })

        return {
            EntityType,
            ProjectFormType,
            isCollapse,
            pictureList,
            previewPictureList,
            itemInfo,
            projectInfo
        }
    }
})
