

























































import { timeFormat, timeFormatStr } from '@/utils/time-format'
import { computed, defineComponent, nextTick, ref, ComputedRef, watch } from '@vue/composition-api'
import { Slider } from 'element-ui'
export default defineComponent({
    props: {
        projectInfo: {
            type: Object,
            default: {}
        }
    },
    components: {
        [Slider.name]: Slider
    },
    setup(props) {
        const projectObj: ComputedRef<any> = computed(() => props.projectInfo)

        const earlyTime: ComputedRef<any> = computed(() => {
            let time = 0

            if (projectObj.value.started_at && projectObj.value.detail_expect_at) {
                time = (new Date(projectObj.value.detail_expect_at).getTime() - new Date(projectObj.value.started_at).getTime()) / 1000
            }
            return time
        })


        const surplusTime = computed(() => {
            let time = projectObj.value.detail_estimated_time ?? 0

            if (projectObj.value.started_at) {
                const end = new Date(projectObj.value.started_at).getTime() + projectObj.value.detail_estimated_time * 1000
                time = (end - Date.now() - props.projectInfo.server_offset) / 1000
            }

            return time < 0 ? 0 : time
        })

        let runTime = ref('00:00:00')
        const isOvertime = ref(false)
        const overTime = ref(0)
        const timeSliderValue = ref(100)
        const handleTimeSlider = () => {

            let time = 0
            nextTick(() => {
                if (projectObj.value.started_at) {
                    time = (Date.now() + props.projectInfo.server_offset - new Date(projectObj.value.started_at).getTime()) / 1000
                    timeSliderValue.value = 100 * ((projectObj.value.detail_estimated_time - time) / projectObj.value.detail_estimated_time)
                }
                const a = document.getElementById('timeSlider')
                const b = a?.getElementsByClassName('el-slider__button-wrapper')[0]
                b?.setAttribute('data-before', timeFormat(time * 1000))

                overTime.value = time - projectObj.value.detail_estimated_time

                runTime.value = time > 0 ? timeFormat(time * 1000) : '00:00:00'
                isOvertime.value = time > projectObj.value.detail_estimated_time
                requestAnimationFrame(handleTimeSlider)
            })
        }

        handleTimeSlider()

        return {
            earlyTime,
            timeSliderValue,
            timeFormatStr,
            projectObj,
            surplusTime,
            runTime,
            isOvertime,
            overTime
        }
    }
})
