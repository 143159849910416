






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { getTestGroupInfoApi, getTestGroupMessageAnalyzeApi, getAnalysisOpenSummaries,setHiddenAnswerApi } from '@/api/project'
import { OrderType } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentageVarietyStyle } from '@/utils/percentage-color'
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch, Ref } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem, Empty } from 'element-ui'
import { isEqual, isNil, cloneDeep } from 'lodash'
import Filters from './components/Filters.vue'
import WordCloud from '@/components/WordCloud.vue'
import { wordCloudTags, WordCloudType, AnalyzeTab, wordColors } from '@/utils/word-cloud'
import { getHighlightText, showError } from '@/utils/common'

export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Empty.name]: Empty,
        Filters,
        WordCloud
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true,
        }
    },
    setup(props, { root, emit }) {

        const showDrawer = ref(props.value)
        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)
        const loadingData = ref(false)
        const showHightRate = ref(false)


        const segments: any = inject('segments')
        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)
        const observerInfo: any = inject('observerInfo', { isObserver: false })

        const liveObj: any = inject("liveObj");
        const time = computed(() => {
            let remain = 0;
            // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
            //     remain = liveObj?.timeInfo?.timeRemain
            // }
            if (
                liveObj?.timeInfo?.id == props.item?.message?.id &&
                liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
            ) {
                return (remain = liveObj?.timeInfo?.timeRemain);
            }
            return remain;
        });

        const projectStore = useProjectStore()
        const { getAnalyzeConceptAskOpinion, getFilterSegments, updateAnalyzeConceptAskOpinion } = projectStore

        const onShowWordCloudDialog = observerInfo.isObserver ? () => { } : inject<(message_entity_id: number) => void>('onShowWordCloudDialog')

        if (!onShowWordCloudDialog) {
            throw new Error('onShowWordCloudDialog is not provided');
        }

        const wordCloudRef: Ref<any> = ref(null)


        const roleMng: any = reactive({
            showConfig: computed(() => {
                return !observerInfo.isObserver
            }),
            allowShowWordCloud: computed(() => {
                const wordCloud = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return false
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.nlp && wordCloud.nlp_settings.keywords_enable
                    // return (observerInfo.shareInfo?.nlp && wordCloud.nlp_settings.nlp_enable) || (observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable)
                } else {
                    return wordCloud.nlp_settings.keywords_enable 
                    // return wordCloud.nlp_settings.keywords_enable || wordCloud.nlp_settings.nlp_enable
                }
            }),
            allowShowAiAnalyse: computed(() => {
                const aiAnalyse = props.item?.message.ai_settings;
                if (isNil(aiAnalyse)) {
                    return false;
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable
                } else {
                    return aiAnalyse.ai_enable
                }
            }),
            allowVoting: computed(() => {
                try {
                    return props.item?.message.settings[0].recommend_enable
                } catch (error) {
                    return true
                }
            }),
            allowShow: (type: WordCloudType) => {
                const item = type === WordCloudType.Keywords ? 'keyword' : 'nlp'
                const res = observerInfo.shareInfo?.[item]
                return !observerInfo.isObserver || res
            }
        })

        /* 
        用来存储概念分组的数据
        */
        const testGroupInfo: any = reactive({
            list: {} as any,
            clear: () => {
                testGroupInfo.list = {}
            },
            getTestGroupMessageAnalyze: async (test_option_id: any, segment_id: any, filter_option_ids: any) => {
                // let key = `${test_option_id}-${segment_id}-${filter_option_ids.join(',')}`
                // if(testGroupInfo[key] !== void 0){
                //     return testGroupInfo[key];
                // }

                //Todo:这里的display:none参数是必须要加的，原因是不加就会返回所有文本
                //加了之后，就会返回需要的数据。
                //暂时不清楚，为什么要加display:none
                //这里type 固定为1 是因为在开放题，这个参数没有实际用途，但是又必须要设置值
                let result: any = await getTestGroupMessageAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    tgid: props.item?.message.test_group.id,

                    project_uuid: props.item?.message.project_uuid,
                    test_group_id: props.item?.message.test_group.id,
                    message_entity_id: props.item?.message.message_entity_id,
                    type: 1,
                    test_option_id: test_option_id,
                    segment_id: segment_id,
                    filter_option_ids: filter_option_ids,
                    display: 'none',
                    observer: observer,
                    analysis_type: analyzeObj.contentType
                });
                if (result === "") {
                    result = {
                        "collections": []
                    };
                }
                result.collections.map((i: any) => {
                    if (i.result) {
                        for (let j in i.result.opinions) {
                            i.result.opinions[j].participants = i.result.participants
                        }
                    } else {
                        i.result = {
                            participants: 0,
                            opinions: {}
                        };
                    }
                })
                // testGroupInfo[key] = result;
                return result;
            }
        });

        const supportRateInfo: any = reactive({
            answerSegment: {
                id: 0,
                name: root.$t('all'),
                answers: [] as any[]
            },
            allSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null,
                        ai_analysis_result: null
                    }
                    return item
                })
                return items
            }),
            segments: [] as any[],
            testGroupsAnswerRateAnalyze: [] as any[],
            answers: [] as any[],
            testGroups2: [] as any[],
            selected_test_group2: 0,
            selected_test_group2_name: computed(() => {
                return supportRateInfo.testGroups2.find((i: any) => i.id == supportRateInfo.selected_test_group2) && supportRateInfo.testGroups2.find((i: any) => i.id == supportRateInfo.selected_test_group2).name
            }),
            handleTestGroup2Command: ({ testGroup }: any) => {
                if (supportRateInfo.selected_test_group2 != testGroup.id) {
                    supportRateInfo.selected_test_group2 = testGroup.id;
                    supportRateInfo.refreshTestGroupMessageAnalyze().then(() => {
                        wordCloudInfo.refreshTestGroupMessageAnalyze();
                    });
                }
            },
            refreshTestGroupMessageAnalyze: async () => {
                // loadingData.value = true;
                supportRateInfo.testGroupsAnswerRateAnalyze = await Promise.all(
                    supportRateInfo.allSegments.map(async (segment: any) => {
                        return await supportRateInfo.getTestGroupMessageRateAnalyze(segment.id);
                    })
                )
                supportRateInfo.answerSegment.answers = supportRateInfo.testGroupMessageRateAnalyzeText();
                loadingData.value = false;
            },
            getTestGroupMessageRateAnalyze: async (id: any) => {
                let result = await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, id, [])
                return result;
            },
            testGroupMessageRateAnalyzeText: () => {
                if (supportRateInfo.answerSegment.id == 0) {
                    let arr = supportRateInfo.testGroupsAnswerRateAnalyze.map((i: any) => {
                        return i.collections.map((j: any) => {
                            let arr = [];
                            for (let k in j.result.opinions) {
                                arr.push(j.result.opinions[k])
                            }
                            return arr
                        }).flat()
                    }).flat();
                    let arr_id = arr.map((i: any) => i.reply_id);
                    arr_id = Array.from(new Set(arr_id));
                    return arr_id.map((i: any) => arr.find((j: any) => j.reply_id == i));
                } else {
                    let index = supportRateInfo.allSegments.findIndex((i: any) => i.id == supportRateInfo.answerSegment.id);
                    let arr = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections.map((j: any) => {
                        let arr = [];
                        for (let k in j.result.opinions) {
                            arr.push(j.result.opinions[k])
                        }
                        return arr
                    }).flat()
                    let arr_id = arr.map((i: any) => i.reply_id);
                    arr_id = Array.from(new Set(arr_id));
                    return arr_id.map((i: any) => arr.find((j: any) => j.reply_id == i));
                }
            },
            getParticipants: (segment: any) => {
                // if (segment === null) {
                //     return 0;
                // }
                // if (segment.selected && segment.selected.length > 0) {
                //     if (supportRateInfo.filterSegment.curFilter.testGroup) {
                //         if (analyzeObj.selected_rounds == 0) {
                //             return supportRateInfo.filterSegment.curFilter.testGroup.collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //         } else {
                //             let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // } else if (segment && segment.id) {
                //     let index = supportRateInfo.allSegments.findIndex((i: any) => i.id == segment.id);
                //     if (supportRateInfo.testGroupsAnswerRateAnalyze && supportRateInfo.testGroupsAnswerRateAnalyze[index]) {
                //         if (analyzeObj.selected_rounds == 0) {
                //             return supportRateInfo.testGroupsAnswerRateAnalyze[index].collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //         } else {
                //             let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // }
                if (segment === null) {
                    return 0;
                }else{
                    return supportRateInfo.answerSegment.answers.filter((opt: any) => !opt.is_hidden).length
                }
                // return 0;
            },
            getPercentage: (segment: any, reply_id: number) => {
                if (segment === null) {
                    return 0;
                }
                if (segment.selected && segment.selected.length > 0) {
                    let has_record = false;
                    let total = 0;
                    let agree = 0;
                    if (supportRateInfo.filterSegment.curFilter.testGroup) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.filterSegment.curFilter.testGroup.collections.length; i++) {
                                let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[i];
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    total += opinion.agree + opinion.disagree + opinion.lose
                                    agree += opinion.agree
                                    has_record = true;
                                }
                            }
                        } else {
                            let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    total += opinion.agree + opinion.disagree + opinion.lose
                                    agree += opinion.agree
                                    has_record = true;
                                }
                            }
                        }
                    }
                    if (has_record) {
                        if (total > 0) {
                            return Math.round(agree / total * 100)
                        } else {
                            return 0;
                        }
                    }

                } else if (segment.id) {
                    let index = supportRateInfo.allSegments.findIndex((i: any) => i.id == segment.id);
                    if (supportRateInfo.testGroupsAnswerRateAnalyze && supportRateInfo.testGroupsAnswerRateAnalyze[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.testGroupsAnswerRateAnalyze[index].collections.length; i++) {
                                let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[i];
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    return Math.round(opinion.agree_rate * 100)
                                } else {
                                    return 0
                                }
                            }
                        } else {
                            let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    return Math.round(opinion.agree_rate * 100)
                                } else {
                                    return 0
                                }
                            }
                        }
                    }
                }
                return 0
            },

            getNumber: (segment: any, reply_id: number) => {

                if (segment === null) {
                    return 0;
                }
                if (segment.selected && segment.selected.length > 0) {
                    let has_record = false;
                    let total = 0;
                    if (supportRateInfo.filterSegment.curFilter.testGroup) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.filterSegment.curFilter.testGroup.collections.length; i++) {
                                let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[i];
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    total += opinion.agree + opinion.disagree + opinion.lose
                                    has_record = true;
                                }
                            }
                        } else {
                            let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    total += opinion.agree + opinion.disagree + opinion.lose
                                    has_record = true;
                                }
                            }
                        }
                    }
                    if (has_record) {
                        return total
                    }
                } else if (segment.id) {
                    let index = supportRateInfo.allSegments.findIndex((i: any) => i.id == segment.id);
                    if (supportRateInfo.testGroupsAnswerRateAnalyze && supportRateInfo.testGroupsAnswerRateAnalyze[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.testGroupsAnswerRateAnalyze[index].collections.length; i++) {
                                let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[i];
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    return opinion.agree + opinion.disagree + opinion.lose
                                } else {
                                    return 0
                                }
                            }
                        } else {
                            let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let opinion = col.result.opinions[reply_id];
                                if (opinion) {
                                    return opinion.agree + opinion.disagree + opinion.lose
                                } else {
                                    return 0
                                }
                            }
                        }
                    }
                }

                return 0
            },
            getOpinions: (segment: any) => {
                if (segment && segment.selected && segment.selected.length > 0) {
                    if (supportRateInfo.filterSegment.curFilter.testGroup) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.filterSegment.curFilter.testGroup.collections.length; i++) {
                                let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[i];
                                let has_result = false;
                                for (let j in col.result.opinions) {
                                    has_result = true;
                                }
                                if (has_result) {
                                    return col.result.opinions;
                                }
                            }
                            return undefined
                        } else {
                            let col = supportRateInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                return col.result.opinions;
                            }
                        }
                    }
                } else if (segment && segment.id) {
                    let index = supportRateInfo.allSegments.findIndex((i: any) => i.id == segment.id);
                    if (supportRateInfo.testGroupsAnswerRateAnalyze && supportRateInfo.testGroupsAnswerRateAnalyze[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            for (let i = 0; i < supportRateInfo.testGroupsAnswerRateAnalyze[index].collections.length; i++) {
                                let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[i];
                                let has_result = false;
                                for (let j in col.result.opinions) {
                                    has_result = true;
                                }
                                if (has_result) {
                                    return col.result.opinions;
                                }
                            }
                            return undefined
                        } else {
                            let col = supportRateInfo.testGroupsAnswerRateAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                return col.result.opinions;
                            }
                        }
                    }
                }

                return undefined
            },
            showOrder: (segment: any) => {
                return supportRateInfo.getOpinions(segment) !== undefined;
            },
            orderObj: {
                type: OrderType.None,
                curOrderSegment: {} as any,
                getType: (segment: any) => {
                    if (segment.id !== supportRateInfo.orderObj.curOrderSegment.id) {
                        return OrderType.None
                    }
                    return supportRateInfo.orderObj.type
                },
                clearAllOrder: () => {
                    supportRateInfo.orderObj.type = OrderType.None
                    supportRateInfo.orderObj.refreshOrder()
                },
                changeOrder: (segment: any, orderType?: OrderType) => {
                    if (isNil(segment.id)) {
                        return
                    }

                    if (segment.id != supportRateInfo.orderObj.curOrderSegment.id) {
                        supportRateInfo.orderObj.type = OrderType.None
                        supportRateInfo.orderObj.curOrderSegment = segment

                    }

                    let keys = supportRateInfo.answerSegment.answers.map((answer: any) => answer.reply_id)
                    if (keys.length == 0) {
                        return
                    }

                    if (!orderType) {

                        if (supportRateInfo.orderObj.type == OrderType.None) {
                            orderType = OrderType.DESC
                        } else if (supportRateInfo.orderObj.type == OrderType.DESC) {
                            orderType = OrderType.ASC
                        } else if (supportRateInfo.orderObj.type == OrderType.ASC) {
                            orderType = OrderType.None
                        }
                    }
                    supportRateInfo.orderObj.type = orderType


                    const type = supportRateInfo.orderObj.type
                    const opinions = supportRateInfo.getOpinions(segment)
                    switch (type) {
                        case OrderType.None:
                            keys = keys.sort((a: any, b: any) => a - b)
                            break
                        case OrderType.DESC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a]?.agree_rate === opinions[b]?.agree_rate) {
                                    return a - b
                                } else {
                                    return opinions[b]?.agree_rate - opinions[a]?.agree_rate
                                }
                            })
                            break
                        case OrderType.ASC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a]?.agree_rate === opinions[b]?.agree_rate) {
                                    return b - a
                                } else {
                                    return opinions[a]?.agree_rate - opinions[b]?.agree_rate
                                }
                            })
                            break
                    }
                    supportRateInfo.answerSegment.answers.sort((a: any, b: any) => {
                        return keys.indexOf(a.reply_id) - keys.indexOf(b.reply_id)
                    })

                },
                refreshOrder: () => {
                    supportRateInfo.orderObj.changeOrder(supportRateInfo.orderObj.curOrderSegment, supportRateInfo.orderObj.type)
                },
                canReset: computed(() => {
                    return supportRateInfo.orderObj.type != OrderType.None
                })
            },

            filterSegment: {
                formIndex: 0,
                segments: [] as any[],
                curFilter: computed(() => {
                    if (supportRateInfo.filterSegment.segments.length == 0) {
                        return null
                    }
                    return supportRateInfo.filterSegment.segments[supportRateInfo.filterSegment.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    supportRateInfo.filterSegment.curFilter.selected = options
                    supportRateInfo.filterSegment.curFilter.name = supportRateInfo.filterSegment.getName(options)
                    supportRateInfo.filterSegment.curFilter.testGroup = await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, 0, options.map((i: any) => i.id));
                    supportRateInfo.orderObj.refreshOrder()
                },
                reset: async () => {
                    supportRateInfo.filterSegment.curFilter.selected = []
                    supportRateInfo.filterSegment.curFilter.name = '--'
                    supportRateInfo.filterSegment.curFilter.testGroup = []
                    await supportRateInfo.orderObj.clearAllOrder()
                }
            },

            handleSegmentChange: async (segment: any) => {
                supportRateInfo.answerSegment.name = segment.name
                supportRateInfo.answerSegment.id = segment.id
                supportRateInfo.answerSegment.answers = supportRateInfo.testGroupMessageRateAnalyzeText();
                supportRateInfo.orderObj.refreshOrder()
            },

            handleCommand: async (item: any) => {
                if (!item.segment) {
                    const segment = supportRateInfo.filterSegment.segments[item.index]
                    supportRateInfo.segments[item.index] = segment
                    supportRateInfo.filterSegment.formIndex = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                    showFilters.value = true
                    supportRateInfo.filterSegment.curFilter.testGroup = await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, 0, supportRateInfo.filterSegment.curFilter.selected.map((i: any) => i.id));
                } else {
                    showFilters.value = false
                    let segmentItem = supportRateInfo.allSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const cloneItem = cloneDeep(segmentItem)
                        item.index = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                        supportRateInfo.segments.splice(item.index, 1, cloneItem)
                    }
                }
            },
            segmentAnswers: [] as any[],
            filterAnswers: computed(() => {
                if (showFilters.value) {
                    if (supportRateInfo.filterSegment.curFilter?.collection) {
                        return Object.values(supportRateInfo.filterSegment.curFilter.collection.result?.opinions) ?? []
                    }
                } else {
                    if (supportRateInfo.curSegment?.collection) {
                        return Object.values(supportRateInfo.curSegment.collection.result?.opinions) ?? []
                    }
                }

                return []
            }),
        })


        const wordCloudInfo: any = reactive({
            testGroupsAnswerCloudAnalyze: [] as any[],
            allSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                    }
                    return item
                })
                return [{ id: 0, name: root.$t('all') }, ...items];
            }),
            refreshTestGroupMessageAnalyze: async () => {
                // loadingData.value = true;
                wordCloudInfo.testGroupsAnswerCloudAnalyze = await Promise.all(
                    wordCloudInfo.allSegments.map(async (segment: any) => {
                        return await await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, segment.id, [])
                    })
                )
                loadingData.value = false;
            },

            cur_word_cloud: computed(() => {
                let index = wordCloudInfo.allSegments.findIndex((i: any) => i.id == wordCloudInfo.curSegment.id);
                if (wordCloudInfo.testGroupsAnswerCloudAnalyze && wordCloudInfo.testGroupsAnswerCloudAnalyze[index]) {
                    if (analyzeObj.selected_rounds == 0) {
                        let word_clouds: any = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections.map((i: any) => {
                            return i.word_cloud
                        });
                        if (word_clouds.length > 0) {
                            let new_word_cloud = {
                                branch_keywords: {},
                                branch_nlp: {},
                                keywords_retrieve: {},
                                loading: false,
                                nlp_retrieve: {},
                                nlp_settings: word_clouds[0].nlp_settings,
                            };
                            word_clouds.map((i:any)=>{
                                 new_word_cloud.keywords_retrieve={...new_word_cloud.keywords_retrieve, ...i.keywords_retrieve}
                            })
                            return new_word_cloud;
                            //return word_clouds;
                        }
                    } else {
                        let col = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                        if (col) {
                            return col.word_cloud;
                        }
                    }
                } else {
                    let ops: any[] = [];
                    if (wordCloudInfo.filterSegment.curFilter.testGroup && wordCloudInfo.filterSegment.curFilter.testGroup.collections) {
                        if (analyzeObj.selected_rounds == 0) {
                            let word_clouds: any = wordCloudInfo.filterSegment.curFilter.testGroup.collections.map((i: any) => {
                                return i.word_cloud
                            });
                            if (word_clouds.length > 0) {
                                let new_word_cloud = {
                                    branch_keywords: {},
                                    branch_nlp: {},
                                    keywords_retrieve: {},
                                    loading: false,
                                    nlp_retrieve: {},
                                    nlp_settings: word_clouds[0].nlp_settings,
                                };
                                word_clouds.map((i:any)=>{
                                 new_word_cloud.keywords_retrieve={...new_word_cloud.keywords_retrieve, ...i.keywords_retrieve}
                            })
                            return new_word_cloud;
                                //return word_clouds;
                            }
                        } else {
                            let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                return col.word_cloud;
                            }
                        }
                    }
                }

                return void 0;
            }),
            isLoading: computed(() => {
                return wordCloudInfo.cur_word_cloud?.loading
            }),
            list: [] as any[],
            shape: computed(() => {
                return props.item?.word_cloud?.nlp_settings?.shape ?? 'circle'
            }),
            colors: computed(() => {
                const color = props.item?.word_cloud?.nlp_settings?.color
                return wordColors.find((item) => item.color === color)?.list ?? ['#000000']
            }),
            currentTag: WordCloudType.Keywords,
            getTagClass: (tag: string) => {
                return tag === wordCloudInfo.currentTag ? 'active' : ''
            },
            typeTags: computed(() => {
                const tags = [] as any[]

                const wordCloud: any = wordCloudInfo.cur_word_cloud
                if (isNil(wordCloud)) {
                    return tags
                }

                if (wordCloud.nlp_settings?.keywords_enable && roleMng.allowShow(WordCloudType.Keywords)) {
                    tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Keywords))
                }
                if (wordCloud.nlp_settings?.nlp_enable && roleMng.allowShow(WordCloudType.Nlp)) {

                    tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Nlp))
                }

                if (tags.length > 0 && !tags.find((tag) => tag.value === wordCloudInfo.currentTag)) {
                    wordCloudInfo.currentTag = tags[0].value
                }
                return tags
            }),
            tagClick: (tag: any) => {
                wordCloudInfo.currentTag = tag.value
            },
            keywordTags: [] as any[],
            nlpTags: [] as any[],
            getParticipants: (segment: any) => {
                // if (segment === null) {
                //     return 0;
                // }
                // if (segment.selected && segment.selected.length > 0) {
                //     if (wordCloudInfo.filterSegment.curFilter.testGroup) {
                //         if (analyzeObj.selected_rounds == 0) {
                //             let participants = wordCloudInfo.filterSegment.curFilter.testGroup.collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //             return participants;
                //         } else {
                //             let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // } else if (segment && segment.id >= 0) {
                //     let index = wordCloudInfo.allSegments.findIndex((i: any) => i.id == segment.id);
                //     if (wordCloudInfo.testGroupsAnswerCloudAnalyze && wordCloudInfo.testGroupsAnswerCloudAnalyze[index]) {
                //         if (analyzeObj.selected_rounds == 0) {
                //             let participants = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //             return participants
                //         } else {
                //             let col = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // }
                // return 0;
                if (segment === null) {
                    return 0;
                }else{
                   return wordCloudInfo.opinions.filter((opt: any) => !opt.is_hidden).length
                }
            },
            opinions: computed(() => {
                if (showFilters.value) {
                    if (wordCloudInfo.curSegment.selected && wordCloudInfo.curSegment.selected.length > 0) {
                        let ops: any[] = [];
                        if (wordCloudInfo.filterSegment.curFilter.testGroup) {
                            if (analyzeObj.selected_rounds == 0) {
                                for (let i = 0; i < wordCloudInfo.filterSegment.curFilter.testGroup.collections.length; i++) {
                                    let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[i];
                                    let subops = Object.values(col.result.opinions)
                                    ops = [...ops, ...subops];
                                }
                                return ops
                            } else {
                                let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                                if (col) {
                                    return Object.values(col.result.opinions)
                                }
                            }
                        }
                    }
                    return [];
                } else {
                    if (wordCloudInfo.curSegment.selected && wordCloudInfo.curSegment.selected.length > 0) {
                        let ops: any[] = [];
                        if (wordCloudInfo.filterSegment.curFilter.testGroup) {
                            if (analyzeObj.selected_rounds == 0) {

                                for (let i = 0; i < wordCloudInfo.filterSegment.curFilter.testGroup.collections.length; i++) {
                                    let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[i];
                                    let subops = Object.values(col.result.opinions)
                                    ops = [...ops, ...subops];
                                }
                                const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                                if (retrieveMap) {
                                    const tags: any[] = wordCloudInfo.getWordTags()
                                    const idSet = new Set()
                                    if (tags.length > 0) {
                                        const keys = tags.map((tag) => tag.name)
                                        keys.forEach((key) => {
                                            const item = retrieveMap[key]
                                            if (item && item.frequency > 0) {
                                                item.replies.forEach((id: any) => idSet.add(Number(id)))
                                            }
                                        })
                                        ops = ops.filter((op) => idSet.has(op.reply_id))
                                    }
                                }
                                return ops
                            } else {
                                let col = wordCloudInfo.filterSegment.curFilter.testGroup.collections[analyzeObj.selected_rounds - 1];
                                if (col) {
                                    ops=Object.values(col.result.opinions)
                                    const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                                    if (retrieveMap) {
                                        const tags: any[] = wordCloudInfo.getWordTags()
                                        const idSet = new Set()
                                        if (tags.length > 0) {
                                            const keys = tags.map((tag) => tag.name)
                                            keys.forEach((key) => {
                                                const item = retrieveMap[key]
                                                if (item && item.frequency > 0) {
                                                    item.replies.forEach((id: any) => idSet.add(Number(id)))
                                                }
                                            })
                                            ops = ops.filter((op) => idSet.has(op.reply_id))
                                        }
                                    }
                                    return ops
                                }
                            }
                        }
                    }
                    else if (wordCloudInfo.curSegment && wordCloudInfo.curSegment.id >= 0) {
                        let ops: any[] = [];
                        let index = wordCloudInfo.allSegments.findIndex((i: any) => i.id == wordCloudInfo.curSegment.id);
                        if (wordCloudInfo.testGroupsAnswerCloudAnalyze && wordCloudInfo.testGroupsAnswerCloudAnalyze[index]) {
                            if (analyzeObj.selected_rounds == 0) {
                                for (let i = 0; i < wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections.length; i++) {
                                    let col = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections[i];
                                    let subops = Object.values(col.result.opinions)
                                    ops = [...ops, ...subops];
                                }
                                
                                const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                                if (retrieveMap) {
                                    const tags: any[] = wordCloudInfo.getWordTags()
                                    const idSet = new Set()
                                    if (tags.length > 0) {
                                        const keys = tags.map((tag) => tag.name)
                                        keys.forEach((key) => {
                                            const item = retrieveMap[key]
                                            if (item && item.frequency > 0) {
                                                item.replies.forEach((id: any) => idSet.add(Number(id)))
                                            }
                                        })
                                        ops = ops.filter((op) => idSet.has(op.reply_id))
                                    }
                                }
                                return ops
                            } else {
                                let col = wordCloudInfo.testGroupsAnswerCloudAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                                if (col) {
                                    ops=Object.values(col.result.opinions)
                                    const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                                    if (retrieveMap) {
                                        const tags: any[] = wordCloudInfo.getWordTags()
                                        const idSet = new Set()
                                        if (tags.length > 0) {
                                            const keys = tags.map((tag) => tag.name)
                                            keys.forEach((key) => {
                                                const item = retrieveMap[key]
                                                if (item && item.frequency > 0) {
                                                    item.replies.forEach((id: any) => idSet.add(Number(id)))
                                                }
                                            })
                                            ops = ops.filter((op) => idSet.has(op.reply_id))
                                        }
                                    }
                                    return ops
                                }
                            }
                        }
                    }
                }

                return []
            }),
            wordList: computed(() => {
                const wordCloud = wordCloudInfo.cur_word_cloud
                if (!wordCloud) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`]
                if (!wordCloud || isNil(result)) {
                    return []
                }
                const arr = Object.keys(result).map((key) => {
                    const item = result[key]
                    return {
                        name: key,
                        value: item.frequency
                    }
                })
                return arr
            }),
            downloadWord: () => wordCloudRef.value.downloadWord(),
            refreshWord: () => wordCloudRef.value.initViewData(),
            clickWord: (params: any) => {
                const { data } = params
                const tags: any[] = wordCloudInfo.getWordTags()
                if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
                    return
                }
                wordCloudInfo.getWordTags().push(data)
            },
            clearAllWork: () => {
                const tags = wordCloudInfo.getWordTags()
                tags.splice(0, tags.length)
            },
            clearWork: (tag: any, i: number) => {
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.splice(i, 1)
            },
            getWordTags() {
                return wordCloudInfo.currentTag == WordCloudType.Keywords ? wordCloudInfo.keywordTags : wordCloudInfo.nlpTags
            },
            getHighlightTags: (opinion: any) => {
                const wordCloud = wordCloudInfo.cur_word_cloud
                if (isNil(wordCloud)) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`]
                if (isNil(result)) {
                    return []
                }
                const hightlightSet = new Set()
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.forEach((tag) => {
                    const item = result[tag.name]
                    if (item.replies.includes(opinion.reply_id)) {
                        item.highlight_tags?.forEach((tag: any) => {
                            hightlightSet.add(tag)
                        })
                    }
                })
                return Array.from(hightlightSet)
            },
            curSegment: {
                id: 0,
                name: root.$t('all'),
                collection: null as any,
                word_cloud: null as any,
                selected: [] as any[]
            },
            filterSegment: {
                curFilter: {
                    id: -1,
                    name: '--',
                    collection: null as any,
                    word_cloud: null as any,
                    selected: [] as any[],
                    testGroup: null as any,
                },
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    wordCloudInfo.filterSegment.curFilter.testGroup = await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, 0, options.map((i: any) => i.id));
                    wordCloudInfo.filterSegment.curFilter.selected = options
                    wordCloudInfo.filterSegment.curFilter.name = wordCloudInfo.filterSegment.getName(options)
                },
                reset: async () => {
                    wordCloudInfo.filterSegment.curFilter.selected = []
                    wordCloudInfo.filterSegment.curFilter.name = '--'
                    wordCloudInfo.filterSegment.curFilter.testGroup = [];
                }
            },
            handleCommand: async (item: any) => {
                if (!item.segment) {
                    showFilters.value = true
                    wordCloudInfo.curSegment = wordCloudInfo.filterSegment.curFilter
                    wordCloudInfo.filterSegment.curFilter.testGroup = await testGroupInfo.getTestGroupMessageAnalyze(supportRateInfo.selected_test_group2, 0, wordCloudInfo.filterSegment.curFilter.selected.map((i: any) => i.id));
                } else {
                    showFilters.value = false
                    let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const item = cloneDeep(segmentItem)
                        wordCloudInfo.curSegment = item
                    }
                }
                wordCloudInfo.clearAllWork()
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg?.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            },
        })
        //wordCloudInfo.list = wordCloudInfo.wordList

        //由于更改了TestGroupsAnswerAnalyze属性，页面没有刷新，所以增加了一个参数，来强制刷新
        const showTestGroupsAnswerAnalyze = ref(false)
        const analyzeObj: any = reactive({
            loading: true,
            current_round: 0,
            contentType: AnalyzeTab.Answer,
            analyze_types: [{
                id: 1,
                name: root.$t('projectTestGroup.conceptualContrast')
            }, {
                id: 2,
                name: root.$t('projectTestGroup.populationComparison')
            }] as any[],
            selected_analyze_type: 1,
            selected_analyze_type_name: computed(() => {
                return analyzeObj.analyze_types.find((i: any) => i.id == analyzeObj.selected_analyze_type).name
            }),
            copyMarkdownSummary :(index: any) => {
                let segmentAnswers = analyzeObj.testGroupsAnswerAnalyzeAnalysis(index)
                let summary=''
                if (segmentAnswers.length > 0) {
                    if (segmentAnswers[0].id == 0){
                        summary=''
                    }
                    if(segmentAnswers[0].status == 'done'){
                        summary=segmentAnswers[0].summary
                    }
                
                }
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = root.$markdownToHtml(summary);

                // 从div中提取纯文本
                const textToCopy = tempDiv.textContent || '';
                root.$doCopy(textToCopy);
                tempDiv.remove();
            },
            copy_text:(segments_answer: any) => {
                root.$doCopy(analyzeObj.testGroupsAnswerAnalyzeText(segments_answer).map((i: any) => '"' + i.text.replace('"', '\"') + '"').join('\n'))
            },
            segments: [{
                id: 0,
                name: root.$t('projectTestGroup.allSegment'),
            }] as any[],
            selected_segment: 0,
            selected_segment_name: computed(() => {
                return analyzeObj.segments.find((i: any) => i.id == analyzeObj.selected_segment) && analyzeObj.segments.find((i: any) => i.id == analyzeObj.selected_segment).name
            }),

            segmentsAnswer: [] as any[],
            selected_segments_answer: [] as any[],
            selected_segments_answer_name: (id: any) => {
                return analyzeObj.segmentsAnswer.find((i: any) => i.id == id).name;
            },

            testGroupInfo: {},

            testGroups: [] as any[],
            selected_test_group: null,
            selected_test_group_name: computed(() => {
                return analyzeObj.testGroups.find((i: any) => i.id == analyzeObj.selected_test_group) && analyzeObj.testGroups.find((i: any) => i.id == analyzeObj.selected_test_group).name
            }),

            testGroupsAnswer: [] as any[],
            selected_test_group_answer: [] as any[],
            selected_test_group_answer_name: (id: any) => {
                return analyzeObj.testGroupsAnswer.find((i: any) => i.id == id).name;
            },

            //概念对比数组存储
            testGroupsAnswerAnalyze: [] as any[],
            //人群对比数据存储
            testGroupsAnswerAnalyze2: [] as any[],

            testGroupsAnswerAnalyzeText: (id: any) => {
                if (analyzeObj.selected_analyze_type == 1) {
                    //概念对比
                    let index = analyzeObj.testGroupsAnswer.findIndex((i: any) => i.id == id);
                    if (analyzeObj.testGroupsAnswerAnalyze && analyzeObj.testGroupsAnswerAnalyze[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            return analyzeObj.testGroupsAnswerAnalyze[index].collections.map((i: any) => {
                                let result_message = []
                                for (let j in i.result.opinions) {
                                    result_message.push(i.result.opinions[j])
                                }
                                return result_message;
                            }).flat();

                        } else {
                            let col = analyzeObj.testGroupsAnswerAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let result_message = []
                                for (let j in col.result.opinions) {
                                    result_message.push(col.result.opinions[j])
                                }
                                return result_message;
                            }

                        }
                    }


                } else {
                    // 人群对比
                    let index = analyzeObj.segmentsAnswer.findIndex((i: any) => i.id == id);
                    if (analyzeObj.testGroupsAnswerAnalyze2 && analyzeObj.testGroupsAnswerAnalyze2[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            return analyzeObj.testGroupsAnswerAnalyze2[index].collections.map((i: any) => {
                                let result_message = []
                                for (let j in i.result.opinions) {
                                    result_message.push(i.result.opinions[j])
                                }
                                return result_message;
                            }).flat();
                        } else {
                            let col = analyzeObj.testGroupsAnswerAnalyze2[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let result_message = []
                                for (let j in col.result.opinions) {
                                    result_message.push(col.result.opinions[j])
                                }
                                return result_message;
                            }
                        }
                    }
                }

                return [];
            },

            testGroupsAnswerAnalyzeAnalysis: (id: any) => {
                if (analyzeObj.selected_analyze_type == 1) {
                    //概念对比
                    let index = analyzeObj.testGroupsAnswer.findIndex((i: any) => i.id == id);
                    if (analyzeObj.testGroupsAnswerAnalyze && analyzeObj.testGroupsAnswerAnalyze[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            return analyzeObj.testGroupsAnswerAnalyze[index].ai_analysis_result ? [analyzeObj.testGroupsAnswerAnalyze[index].ai_analysis_result.open_ended_summary] : []
                        } else {
                            let col = analyzeObj.testGroupsAnswerAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                            // console.log(col,analyzeObj.testGroupsAnswerAnalyze[index])
                            if (col) {
                                let result_message = col.ai_analysis_result ? [col.ai_analysis_result.open_ended_summary] : []
                                return result_message;
                            } else {
                                return []
                            }
                        }
                    }
                } else {
                    // 人群对比
                    let index = analyzeObj.segmentsAnswer.findIndex((i: any) => i.id == id);
                    if (analyzeObj.testGroupsAnswerAnalyze2 && analyzeObj.testGroupsAnswerAnalyze2[index]) {
                        if (analyzeObj.selected_rounds == 0) {
                            return analyzeObj.testGroupsAnswerAnalyze2[index].ai_analysis_result ? [analyzeObj.testGroupsAnswerAnalyze2[index].ai_analysis_result.open_ended_summary] : []
                        } else {
                            let col = analyzeObj.testGroupsAnswerAnalyze2[index].collections[analyzeObj.selected_rounds - 1];
                            if (col) {
                                let result_message = col.ai_analysis_result ? [col.ai_analysis_result.open_ended_summary] : []
                                return result_message;
                            } else {
                                return []
                            }
                        }
                    }
                }

                return [];
            },

            getTestGroupInfo: async () => {
                analyzeObj.testGroupInfo = await getTestGroupInfoApi({
                    uuid: props.item?.message.project_uuid,
                    testGroupId: props.item?.message.test_group.id,
                    observer: observer
                })

                analyzeObj.testGroupInfo.material.options.map((item: any, index: any) => {
                    let obj = {
                        id: item.id,
                        name: item.text,
                        selected: item.id,
                        options: analyzeObj.testGroupInfo.material.options,
                    }
                    analyzeObj.testGroups.push(obj)
                    supportRateInfo.testGroups2.push(obj)
                    analyzeObj.testGroupsAnswer.push(obj)
                })
                analyzeObj.selected_test_group = analyzeObj.testGroupInfo?.material.options[0]?.id
                if (supportRateInfo.testGroups2.length > 0) {
                    supportRateInfo.selected_test_group2 = supportRateInfo.testGroups2[0].id
                }

                analyzeObj.selected_test_group_answer = analyzeObj.testGroupsAnswer.map((i: any) => i.id);

            },

            testGroupRounds: [{
                id: 0,
                name: root.$t('projectTestGroup.allRounds')
            }] as any[],
            selected_rounds: 0,
            selected_round_name: computed(() => {
                return analyzeObj.testGroupRounds.find((i: any) => i.id == analyzeObj.selected_rounds) && analyzeObj.testGroupRounds.find((i: any) => i.id == analyzeObj.selected_rounds).name
            }),

            overallSegment: {
                id: 0,
                name: root.$t('all'),
                collection: props.item?.collection,
                word_cloud: props.item?.word_cloud
            },
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null,
                        ai_analysis_result: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),

            showComments: (answer: any) => {
                return !showHightRate.value || (showHightRate.value && answer.participants >= 10 && answer.agree_rate >= 0.8)
            },

            getParticipants: (id: any) => {
                // if (analyzeObj.selected_analyze_type == 1) {
                //     let index = analyzeObj.testGroupsAnswer.findIndex((i: any) => i.id == id);
                //     if (analyzeObj.testGroupsAnswerAnalyze && analyzeObj.testGroupsAnswerAnalyze[index]) {
                //         if (analyzeObj.selected_rounds == 0) {

                //             return analyzeObj.testGroupsAnswerAnalyze[index].collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //         } else {
                //             let col = analyzeObj.testGroupsAnswerAnalyze[index].collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // } else {
                //     let index = analyzeObj.segmentsAnswer.findIndex((i: any) => i.id == id);
                //     if (analyzeObj.testGroupsAnswerAnalyze2 && analyzeObj.testGroupsAnswerAnalyze2[index]) {
                //         if (analyzeObj.selected_rounds == 0) {

                //             return analyzeObj.testGroupsAnswerAnalyze2[index].collections.reduce((i: any, j: any) => {
                //                 return j.result.participants + i;
                //             }, 0);
                //         } else {
                //             let col = analyzeObj.testGroupsAnswerAnalyze2[index].collections[analyzeObj.selected_rounds - 1];
                //             if (col) {
                //                 return col.result.participants;
                //             }
                //         }
                //     }
                // }

                // return 0;
                if (id === null) {
                    return 0;
                }else{
                   return analyzeObj.testGroupsAnswerAnalyzeText(id).filter((opt: any) => !opt.is_hidden).length
                }
            },

            getOpinions: (segment: any) => {
                if (!segment?.collection) {
                    return {}
                }
                return segment?.collection?.result?.opinions
            },

            getPercentage: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return Math.round(opinion.agree_rate * 100)
            },

            getNumber: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return opinion.agree + opinion.disagree + opinion.lose
            },

            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            },

            getTestGroupMessageAnalyze: async (id: any) => {

                //Todo:这里的display:none参数是必须要加的，原因是不加就会返回所有文本
                //加了之后，就会返回需要的数据。
                //暂时不清楚，为什么要加display:none

                let result: any = null;
                if (analyzeObj.selected_analyze_type == 1) {
                    //如果是概念对比，id 就是人群的id
                    result = await testGroupInfo.getTestGroupMessageAnalyze(id, analyzeObj.selected_segment, [])
                } else {
                    //如果是人群对比，id 就是概念的id
                    result = await testGroupInfo.getTestGroupMessageAnalyze(analyzeObj.selected_test_group, id, [])
                }
                return result;
            },

            handleAnalyzeTypeCommand: ({ analyze_type }: any) => {
                if (analyzeObj.selected_analyze_type != analyze_type.id) {
                    analyzeObj.selected_analyze_type = analyze_type.id;
                    analyzeObj.refreshTestGroupMessageAnalyze()
                }

            },

            handleSegmentCommand: ({ segment }: any) => {
                if (analyzeObj.selected_segment != segment.id) {
                    analyzeObj.selected_segment = segment.id;
                    analyzeObj.refreshTestGroupMessageAnalyze()
                }
            },

            handleTestGroupCommand: ({ testGroup }: any) => {
                if (analyzeObj.selected_test_group != testGroup.id) {
                    analyzeObj.selected_test_group = testGroup.id;
                    analyzeObj.refreshTestGroupMessageAnalyze()
                }
            },

            handleTestGroupAnswerCommand: ({ test_group_answer, index }: any) => {
                analyzeObj.selected_test_group_answer[index] = test_group_answer.id;
                //由于更改了TestGroupsAnswerAnalyze属性，页面没有刷新，所以增加了一个参数，来强制刷新
                showTestGroupsAnswerAnalyze.value = !showTestGroupsAnswerAnalyze.value
            },

            handleSegmentAnswerCommand: ({ segments_answer, index }: any) => {
                analyzeObj.selected_segments_answer[index] = segments_answer.id;
                //由于更改了TestGroupsAnswerAnalyze属性，页面没有刷新，所以增加了一个参数，来强制刷新
                showTestGroupsAnswerAnalyze.value = !showTestGroupsAnswerAnalyze.value
            },

            handleTestGroupRoundsCommand: ({ testGroupRound }: any) => {
                analyzeObj.selected_rounds = testGroupRound.id;
                //analyzeObj.refreshTestGroupMessageAnalyze()
            },

            refreshTestGroupMessageAnalyze: async () => {
                // loadingData.value = true;
                if (analyzeObj.selected_analyze_type == 1) {
                    analyzeObj.testGroupsAnswerAnalyze = await Promise.all(
                        analyzeObj.selected_test_group_answer.map(async (test_group_answer: any) => {
                            return await analyzeObj.getTestGroupMessageAnalyze(test_group_answer);
                        })
                    )
                } else {
                    analyzeObj.testGroupsAnswerAnalyze2 = await Promise.all(
                        analyzeObj.selected_segments_answer.map(async (segments_answer: any) => {
                            return await analyzeObj.getTestGroupMessageAnalyze(segments_answer);
                        })
                    )
                }
                loadingData.value = false;
            },
        })

        onMounted(async () => {
            initData()
        })

        onUnmounted(() => {
            supportRateInfo.segments.forEach((segment: any) => {
                segment.collection = null
            })
            supportRateInfo.filterSegment.segments.forEach((segment: any) => {
                segment.collection = null
            })
            updateAnalyzeConceptAskOpinion(props.item?.message.project_uuid, { analyzeObj: analyzeObj, supportRateInfo: supportRateInfo, wordCloudInfo: wordCloudInfo }, props.item?.message.message_entity_id)

        })

        const initData = async () => {
            analyzeObj.testGroupRounds = [{
                id: 0,
                name: root.$t('projectTestGroup.allRounds')
            }]
            analyzeObj.current_round = props.item?.message.test_group.current_round
            for (let i = 0; i < analyzeObj.current_round; i++) {
                analyzeObj.testGroupRounds.push({
                    id: i + 1,
                    name: `${root.$t('projectTestGroup.rounds', { round: i + 1 })}`
                });
            }
            let analyzeConceptAskOpinion = getAnalyzeConceptAskOpinion(props.item?.message.project_uuid, props.item?.message.message_entity_id)

            if (analyzeConceptAskOpinion != null) {
                analyzeObj.contentType = analyzeConceptAskOpinion.analyzeObj.contentType
                analyzeObj.segments = analyzeConceptAskOpinion.analyzeObj.segments
                analyzeObj.selected_rounds = analyzeConceptAskOpinion.analyzeObj.selected_rounds
                analyzeObj.selected_segments_answer = analyzeConceptAskOpinion.analyzeObj.selected_segments_answer
                analyzeObj.selected_analyze_type = analyzeConceptAskOpinion.analyzeObj.selected_analyze_type
                analyzeObj.segmentsAnswer = analyzeConceptAskOpinion.analyzeObj.segmentsAnswer
                analyzeObj.selected_segments_answer = analyzeConceptAskOpinion.analyzeObj.selected_segments_answer

                analyzeObj.testGroups = analyzeConceptAskOpinion.analyzeObj.testGroups
                analyzeObj.selected_test_group = analyzeConceptAskOpinion.analyzeObj.selected_test_group
                analyzeObj.testGroupsAnswerAnalyze = analyzeConceptAskOpinion.analyzeObj.testGroupsAnswerAnalyze
                analyzeObj.testGroupsAnswerAnalyze2 = analyzeConceptAskOpinion.analyzeObj.testGroupsAnswerAnalyze2
                analyzeObj.testGroupsAnswer = analyzeConceptAskOpinion.analyzeObj.testGroupsAnswer
                analyzeObj.selected_test_group_answer = analyzeConceptAskOpinion.analyzeObj.selected_test_group_answer

                supportRateInfo.answerSegment = analyzeConceptAskOpinion.supportRateInfo.answerSegment
                supportRateInfo.segments = analyzeConceptAskOpinion.supportRateInfo.segments
                supportRateInfo.testGroupsAnswerRateAnalyze = analyzeConceptAskOpinion.supportRateInfo.testGroupsAnswerRateAnalyze
                supportRateInfo.testGroups2 = analyzeConceptAskOpinion.supportRateInfo.testGroups2
                supportRateInfo.answers = analyzeConceptAskOpinion.supportRateInfo.answers
                supportRateInfo.selected_test_group2 = analyzeConceptAskOpinion.supportRateInfo.selected_test_group2
                supportRateInfo.filterSegment.segments = analyzeConceptAskOpinion.supportRateInfo.filterSegment.segments

                wordCloudInfo.testGroupsAnswerCloudAnalyze = analyzeConceptAskOpinion.wordCloudInfo.testGroupsAnswerCloudAnalyze
                wordCloudInfo.list = analyzeConceptAskOpinion.wordCloudInfo.list
                wordCloudInfo.currentTag = analyzeConceptAskOpinion.wordCloudInfo.currentTag
                wordCloudInfo.curSegment = analyzeConceptAskOpinion.wordCloudInfo.curSegment
                wordCloudInfo.filterSegment.curFilter = analyzeConceptAskOpinion.wordCloudInfo.filterSegment.curFilter
            } else {
                await analyzeObj.getTestGroupInfo()

                const segs = segments.value;
                for (let i = 0; i < segs.length; i++) {
                    let obj = {
                        id: segs[i].id,
                        name: segs[i].name
                    }
                    analyzeObj.segments.push(obj)
                    analyzeObj.segmentsAnswer.push(obj)
                }
                analyzeObj.selected_segments_answer = analyzeObj.segmentsAnswer.map((i: any) => i.id);
                await analyzeObj.refreshTestGroupMessageAnalyze();
                supportRateInfo.segments = segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 3)
                if (supportRateInfo.segments.length < 3) {
                    const diff = 3 - supportRateInfo.segments.length
                    for (let i = 0; i < diff; i++) {
                        supportRateInfo.segments.push({
                            name: '--',
                            collection: null,
                            ai_analysis_result: null
                        })
                    }
                }

                supportRateInfo.filterSegment.segments = []
                if (supportRateInfo.filterSegment.segments.length < 3) {
                    for (let i = 0; i < 3; i++) {
                        supportRateInfo.filterSegment.segments.push({
                            id: -1 - i,
                            name: '--',
                            selected: [],
                            collection: null,
                            ai_analysis_result: null
                        })
                    }
                }
                await supportRateInfo.refreshTestGroupMessageAnalyze();
                await wordCloudInfo.refreshTestGroupMessageAnalyze();

            }

        }


        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )

        let needReload = false;
        let reloading = false;
        let isUpdating = false;

        watch(
            () => props.item,
            async (value, old) => {
                if (isUpdating) return;

                isUpdating = true;
                try {
                    analyzeObj.loading = !isEqual(value, old);
                    if (analyzeObj.contentType === AnalyzeTab.Answer||analyzeObj.contentType === AnalyzeTab.AIAnalyze) {
                        await analyzeObj.refreshTestGroupMessageAnalyze();
                    } else if (analyzeObj.contentType === AnalyzeTab.SupportRate) {
                        await supportRateInfo.refreshTestGroupMessageAnalyze();
                    } else {
                        await wordCloudInfo.refreshTestGroupMessageAnalyze();
                    }
                } finally {
                    isUpdating = false;
                }
            }
        );
        watch(
            () => roleMng.allowShowWordCloud,
            (val) => {
                if (!val) {
                    analyzeObj.contentType.value = AnalyzeTab.Answer
                }
            }
        )
        watch(
            () => roleMng.allowShowAiAnalyse,
            (val) => {
                if (!val) {
                    analyzeObj.contentType.value = AnalyzeTab.Answer
                }
            }
        )


        watch(
            () => wordCloudInfo.wordList,
            (val, old) => {
                const isChange = JSON.stringify(val) !== JSON.stringify(old)
                if (isChange || wordCloudInfo.list.length === 0) {

                    wordCloudInfo.list = val
                }
            },
            { deep: true }
        )

        const refreshAnalysis = async (test_group_answer: any, index: any) => {
            try {
                let segmentAnswers = analyzeObj.testGroupsAnswerAnalyzeAnalysis(test_group_answer)
                if (segmentAnswers.length > 0) {
                    if (segmentAnswers[0].id == 0) return
                    const result = await getAnalysisOpenSummaries({ id: segmentAnswers[0].id })
                    if (analyzeObj.selected_analyze_type == 1) {
                        analyzeObj.testGroupsAnswerAnalyze[index] = await analyzeObj.getTestGroupMessageAnalyze(test_group_answer);
                    } else {
                        analyzeObj.testGroupsAnswerAnalyze2[index] = await analyzeObj.getTestGroupMessageAnalyze(test_group_answer);
                    }
                }
            } catch (error) {
                showError(error)
            }
        }

        const isRefreshAnalysis = (test_group_answer: any) => {
            let segmentAnswers = analyzeObj.testGroupsAnswerAnalyzeAnalysis(test_group_answer)
            if (segmentAnswers.length > 0) {
                if (segmentAnswers[0].id == 0) return false
                if (segmentAnswers[0].status == 'done' || segmentAnswers[0].status == 'error') return true
            }
            return false
        }

        const respondents = (token: any) => {
            if (props.item?.respondents) {
                const ops: any[] = props.item.respondents.filter((item: any) => item.token == token)
                if (ops.length > 0) {
                    const seenIds = new Set();
                    let segments = ops[0]
                    let token_segments=segments.token_segments?segments.token_segments:[];
                    const uniqueData = token_segments.filter((item: any) => {
                        if (!seenIds.has(item.id)) {
                            seenIds.add(item.id);
                            return true;
                        }
                        return false;
                    });
                    segments.uniqueData = uniqueData
                    return segments
                } else {
                    return {
              link_id:'',
              uniqueData: []
            }
                }
            } else {
                return {
              link_id:'',
              uniqueData: []
            }
            }
        }
        const disabledTooltipVisible = ref(false)
        const hiddenAnswer = (token: any) => {
            disabledTooltipVisible.value=true
            root.$msgbox({
                showCancelButton: true,
                dangerouslyUseHTMLString: true,
                showClose: true,
                message: <string>root.$i18n.t("projectLive.hiddenAnswer.message"),
                title: <string>root.$i18n.t("projectLive.hiddenAnswer.title"),
                async beforeClose(action, instance, done) {
                    if (action === 'confirm') {
                        try {
                            instance.confirmButtonLoading = true
                            await setHiddenAnswerApi({
                                uuid: props.item?.message.project_uuid,
                                token: token,
                                hidden: true,
                                messageId:  props.item?.message.message_identifier
                            })
                            root.$message.success(<string>root.$i18n.t("projectLive.hiddenAnswer.successMessage"))
                            done()
                            instance.confirmButtonLoading = false
                        } catch (error) {
                            showError(error)
                            instance.confirmButtonLoading = false
                        }
                    } else {
                        done()
                    }
                    setTimeout(() => {
                      disabledTooltipVisible.value=false
                    }, 500);
                }
            }).catch(() => { })
        }

        return {
            OrderType,
            showDrawer,
            showFilters,
            analyzeObj,
            getPercentageVarietyStyle,
            onClosed,
            wordCloudRef,
            wordCloudInfo,
            onShowWordCloudDialog,
            AnalyzeTab,
            supportRateInfo,
            roleMng,
            getHighlightText,
            showTestGroupsAnswerAnalyze,
            loadingData,
            showHightRate,
            refreshAnalysis,
            isRefreshAnalysis,
            time,
            respondents,
            hiddenAnswer,
            disabledTooltipVisible
        }
    }
})
