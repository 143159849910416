






























































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi } from '@/api/project'
import { EntityType, OrderType } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentage } from '@/utils/common'
import { getPercentageStyle } from '@/utils/percentage-color'
import { computed, defineComponent, inject, onMounted, nextTick, onUnmounted, reactive, ref, watch } from '@vue/composition-api'
import {
  Drawer, Dropdown, DropdownMenu, DropdownItem, Table,
  TableColumn,
  Progress,
} from 'element-ui'
import { isEqual, isNil, cloneDeep } from 'lodash'
import Filters from './components/Filters.vue'
import CommonTooltip from "./components/CommonTooltip.vue";

export default defineComponent({
  components: {
    [Drawer.name]: Drawer,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Progress.name]: Progress,
    Filters,
    CommonTooltip
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    }
  },
  setup(props, { root, emit }) {
    const showDrawer = ref(props.value)
    watch(
      () => props.value,
      () => {
        showDrawer.value = props.value
      }
    )
    const onClosed = () => {
      emit('input', showDrawer.value)
    }
    const showFilters = ref(false)
    const segments: any = inject('segments')
    const driver: any = inject('driver')
    const observer: boolean = inject('observer', false)

    const projectStore = useProjectStore()
    const { getAnalyzeSegments, updateAnalyzeSegments, getFilterSegments, updateFilterSegments } = projectStore

    const tooltipRef = ref()
    const handleDropdownVisibleChange = (visible: any) => {
      if (visible) {
        nextTick(() => {
          // 确保 CommonTooltip 已经渲染且可见
          if (tooltipRef.value) {
            const tooltipElement = tooltipRef.value; // 假设 CommonTooltip 传递了 ref 到内部元素
            tooltipRef.value.map((item: any) => {
              item.checkOverflowAndHandle()
            })
          }
        });
      }
    };
    watch(
      () => {
        return props.item
      },
      (value, old) => {
        analyzeObj.loading = !isEqual(value, old)
        analyzeObj.segments.forEach((segment: any) => {
          setCollection(segment)
        })
        analyzeObj.orderObj.refreshOrder()
      }
    )


    const analyzeObj: any = reactive({
      loading: true,
      options: [] as any[],
      testGroupInfo: computed(() => {
        return props.item?.message.test_group
      }),
      overallSegment: {
        id: 0,
        name: root.$t('all'),
        collection: props.item?.collection
      },
      originSegments: computed(() => {
        const items = segments.value.map((segment: any) => {
          const item = {
            id: segment.id,
            name: segment.name,
            oldId: segment.id,
            collection: null
          }
          return item
        })
        return [analyzeObj.overallSegment, ...items]
      }),
      segments: [] as any[],
      orderObj: {
        type: OrderType.None,
        curOrderSegment: {} as any,
        getType: (segment: any) => {
          if (segment.id !== analyzeObj.orderObj.curOrderSegment.id) {
            return OrderType.None
          }
          return analyzeObj.orderObj.type
        },
        clearAllOrder: () => {
          analyzeObj.orderObj.type = OrderType.None
          analyzeObj.orderObj.refreshOrder()
        },
        changeOrder: (segment: any, orderType?: OrderType) => {
          if (isNil(segment.id)) {
            return
          }

          if (segment.id != analyzeObj.orderObj.curOrderSegment.id) {
            analyzeObj.orderObj.type = OrderType.None
            analyzeObj.orderObj.curOrderSegment = segment

          }
          const dataMap = analyzeObj.getDataMap(segment)
          let keys = Object.keys(dataMap).map((key) => Number(key))
          if (keys.length == 0) {
            return
          }
          if (!orderType) {

            if (analyzeObj.orderObj.type == OrderType.None) {
              orderType = OrderType.DESC
            } else if (analyzeObj.orderObj.type == OrderType.DESC) {
              orderType = OrderType.ASC
            } else if (analyzeObj.orderObj.type == OrderType.ASC) {
              orderType = OrderType.None
            }
          }
          analyzeObj.orderObj.type = orderType


          const type = analyzeObj.orderObj.type
          switch (type) {
            case OrderType.None:
              keys = keys.sort((a, b) => a - b)
              break
            case OrderType.DESC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return analyzeObj.options.find((option: any) => option.id === a).sequence - analyzeObj.options.find((option: any) => option.id === b).sequence
                } else {
                  return dataMap[b] - dataMap[a]
                }
              })
              break
            case OrderType.ASC:
              keys.sort((a: any, b: any) => {
                if (dataMap[a] === dataMap[b]) {
                  return analyzeObj.options.find((option: any) => option.id === a).sequence - analyzeObj.options.find((option: any) => option.id === b).sequence
                } else {
                  return dataMap[a] - dataMap[b]
                }
              })
              break
          }
          analyzeObj.options.sort((a: any, b: any) => {
            return keys.indexOf(a.id) - keys.indexOf(b.id)
          })
        },
        refreshOrder: () => {
          analyzeObj.orderObj.changeOrder(analyzeObj.orderObj.curOrderSegment, analyzeObj.orderObj.type)
        },
        canReset: computed(() => {
          return analyzeObj.orderObj.type != OrderType.None
        })
      },
      getParticipants: (item: any) => {
        if (!item?.collection) {
          return 0
        }
        return item.collection.result.participants
      },
      getDataMap: (item: any) => {
        const valueMap: any = {}
        if (item.collection?.result) {
          analyzeObj.options.forEach((option: any) => {
            valueMap[option.id] = percentage(item.collection?.result.ops[option.id], item.collection?.result)
          })
        }
        return valueMap
      },
      filterSegment: {
        formIndex: 0,
        segments: [] as any[],
        curFilter: computed(() => {
          if (analyzeObj.filterSegment.segments.length == 0) {
            return null
          }
          return analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex]
        }),
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return '--'
          }
          return selected
            .map((item: any) => {
              return item.text
            })
            .join(',')
        },
        handleChange: async (options: any[]) => {

          console.log(`handleChange`)
          analyzeObj.filterSegment.curFilter.selected = options
          analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(options)
          await setCollection(analyzeObj.filterSegment.curFilter)
          analyzeObj.orderObj.refreshOrder()
        },
        reset: async () => {

          analyzeObj.filterSegment.curFilter.selected = []
          analyzeObj.filterSegment.curFilter.name = '--'
          setCollection(analyzeObj.filterSegment.curFilter)
          await analyzeObj.orderObj.clearAllOrder()
        }
      },
      enableClass: (segment: any, itemSeg: any) => {
        if (segment.id >= 0) {
          return segment.id == itemSeg.id ? 'item-enable' : ''
        } else {
          return itemSeg.id < 0 ? 'item-enable' : ''
        }
      }
    })

    onMounted(() => {
      initData()
    })

    onUnmounted(() => {
      analyzeObj.segments.forEach((segment: any) => {
        segment.collection = null
      })
      analyzeObj.filterSegment.segments.forEach((segment: any) => {
        segment.collection = null
      })
      updateAnalyzeSegments(props.item?.message.project_uuid, analyzeObj.segments)
      updateFilterSegments(props.item?.message.project_uuid, analyzeObj.filterSegment.segments)
    })

    const initData = async () => {
      analyzeObj.options = props.item?.message.message_options.map((option: any) => {
        return {
          id: option.id,
          text: option.text,
          link: option.link,
          sequence: option.sequence,
        }
      })
      analyzeObj.selected_options = analyzeObj.options[0].id;

      const segs = getAnalyzeSegments(props.item?.message.project_uuid)
      analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 3)

      if (analyzeObj.segments.length < 3) {
        const diff = 3 - analyzeObj.segments.length
        for (let i = 0; i < diff; i++) {
          analyzeObj.segments.push({
            name: '--',
            collection: null
          })
        }
      }

      analyzeObj.originSegments.forEach((segmentOrigin: any) => {
        let segment = analyzeObj.segments.find((s: any) => s.oldId == segmentOrigin.oldId);
        if (!segment) {
          // segment = segmentOrigin;
        } else {
          if (segment.id > -1) {
            segment.name = analyzeObj.originSegments.filter((item: any) => segment.id == item.id)[0].name;
          }
        }
      });


      const filterSegments = getFilterSegments(props.item?.message.project_uuid)
      analyzeObj.filterSegment.segments = filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : []
      if (analyzeObj.filterSegment.segments.length < 3) {
        for (let i = 0; i < 3; i++) {
          analyzeObj.filterSegment.segments.push({
            id: -1 - i,
            name: '--',
            selected: [],
            collection: null
          })
        }
      }

      const ids = segments.value.map((item: any) => item.id) ?? []
      console.log(`样本分组IDs: ${ids}`)

      analyzeObj.segments.forEach((segment: any) => {
        if (segment.id > 0 && !ids.includes(segment.id)) {
          segment.name = '--'
          segment.id = null
          segment.collection = null
          segment.selected = []
        }
        setCollection(segment)
      })
    }

    const setCollection = async (item: any) => {
      if (item.id == 0) {
        item.collection = props.item?.collection
      } else if (item.id > 0) {
        const res = await getMessageSegmentAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          mid: props.item?.message.message_identifier,
          id: item.id,
          driver: driver.value,
          observer: observer
        })
        if (res.length > 0) {
          item.collection = res[0].collection
        }
      } else if (item.id < 0) {
        if (item.selected.length > 0) {
          const optionIds = item.selected.map((option: any) => option.id)
          const res: any = await getMessageOptionsAnalyzeApi({
            uuid: props.item?.message.project_uuid,
            mid: props.item?.message.message_identifier,
            options: optionIds,
            driver: driver.value,
            observer: observer
          })
          item.collection = res[0].collection
        } else {
          item.collection = null
        }
      }
    }

    const percentage = (option: any, result: any) => {
      let percentage = 0
      if (result) {
        const id = option.id ? String(option.id) : ''
        const opParticipants = result.ops[id]?.participants ?? 0
        const allParticipants = result.participants
        if (allParticipants == 0) {
          return percentage
        }
        percentage = getPercentage(opParticipants, allParticipants)
      }
      return percentage
    }



    const handleCommand = async (item: any) => {
      if (!item.segment) {
        const segment = analyzeObj.filterSegment.segments[item.index]
        // console.log(`filter segment: ${JSON.stringify(segment)}`)
        analyzeObj.segments[item.index] = segment
        analyzeObj.filterSegment.formIndex = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
        showFilters.value = true
        // console.log(`selectedOptions:${JSON.stringify(item.segment?.selected)}`)
        await setCollection(analyzeObj.segments[item.index])
      } else {
        showFilters.value = false
        let segmentItem = analyzeObj.originSegments.find((segment: any) => {
          return segment.id === item.segment.id
        })
        console.log(segmentItem)
        if (segmentItem) {
          await setCollection(segmentItem)
          item.index = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
          analyzeObj.segments.splice(item.index, 1, JSON.parse(JSON.stringify(segmentItem)))
        }
      }
    }

    return {
      EntityType,
      OrderType,
      showDrawer,
      showFilters,
      analyzeObj,
      handleCommand,
      getPercentageStyle,
      onClosed,
      handleDropdownVisibleChange,
      tooltipRef
    }
  }
})
