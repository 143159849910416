














































































































































































































































































































































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi, getTestGroupMessageAnalyzeApi, getTestGroupInfoApi } from '@/api/project'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentage } from '@/utils/common'

import { computed, defineComponent,nextTick, inject, onMounted, onUnmounted, reactive, ref, watch } from '@vue/composition-api'
import {
    Drawer, Dropdown, DropdownMenu, DropdownItem, Table,
    TableColumn,
    Progress
} from 'element-ui'
import VideoDialog from "@/components/VideoDialog.vue";
import { isEqual, cloneDeep } from 'lodash'
import Filters from './components/Filters.vue'
import  CommonTooltip  from "./components/CommonTooltip.vue";
export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Progress.name]: Progress,
        Filters,
        VideoDialog,
        CommonTooltip
    },
    props: {
        value: {
            type: Boolean,
            default: true
        },
        item: {
            type: Object
        }
    },
    setup(props, { root, emit }) {
        const videoDialogRef = ref();
        const showDrawer = ref(props.value)
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )
        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)
        const showConceptFilters = ref(false)
        const isCollapse = ref(true)
        const tooltipRef=ref()
        const handleDropdownVisibleChange=(visible:any)=> {
          if (visible) {
            nextTick(() => {
              // 确保 CommonTooltip 已经渲染且可见
              if (tooltipRef.value) {
                const tooltipElement =tooltipRef.value; // 假设 CommonTooltip 传递了 ref 到内部元素
                tooltipRef.value.map((item:any)=>{
                  item.checkOverflowAndHandle()
                })
              }
            });
          }
      };
        const segments: any = inject('segments')

        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        const projectStore = useProjectStore()
        const { getAnalyzeConceptAskRank, updateAnalyzeConceptAskRank } = projectStore


        watch(
            () => {
                return props.item
            },
            (value, old) => {
                analyzeObj.loading = !isEqual(value, old)
                if (analyzeObj.selectedAnalyzeType == 1) {
                    analyzeObj.testGroups.forEach((segment: any, index: any) => {
                        setTestGroupCollection(segment, index)
                    })
                    console.log(analyzeObj.testGroups,'analyzeObj.testGroups')
                } else {
                    analyzeObj.segments.forEach((segment: any, index: any) => {
                        setCollection(segment, index)
                    })
                }
            }
        )

        const getRankScore: any = inject('getRankScore')

        const analyzeObj: any = reactive({
            loading: true,
            currentRound: null,
            selectedAnalyzeType: 1,
            analyzeTypes: [{
                id: 1,
                name: root.$t('projectTestGroup.conceptualContrast')
            }, {
                id: 2,
                name: root.$t('projectTestGroup.populationComparison')
            }],
            selectedSegment: 0,
            selectedConcept: {
                id: null,
                text: ''
            },
            selectedRounds: {
                value: -1,
                text: root.$t("projectTestGroup.allRounds")
            },
            testGroupInfo: {},
            testGroups: [] as any[],
            options: [] as any[],
            overallSegment: {
                id: 0,
                oldId:0,
                name: root.$t('all'),
                collection: props.item?.collection
            },
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        oldId:segment.id,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            segments: [] as any[],

            getParticipants: (item: any) => {
                if (!item?.collection) {
                    return 0
                }
                if (!item.collection.result) {
                    return 0
                }
                return item.collection.result.participants
            },
            getOptions: (segment: any, id: number) => {
                if (!segment.collection) {
                    return []
                }
                if (!segment.collection.result) {
                    return []
                }
                const options: any[] = []
                const ops = segment.collection.result.ops
                const keys = Object.keys(ops)
                keys.forEach((key: string) => {
                    const op = ops[key]
                    options.push({
                        id: op.id,
                        text: op.text,
                        sequence: op.sequence,
                        score: getRankScore(ops, key),
                        votes: op.votes
                    })
                })
                options.sort((a: any, b: any) => {
                    return b.score - a.score
                })
                return options
            },
            getVoteParticipants: (votes: any[], rank: number) => {
                if (!votes) {
                    return 0
                }
                return votes.filter((vote: any) => {
                    return vote.rank == rank
                }).length
            },
            getVotePercentage: (votes: any[], rank: number) => {
                let num = 0
                let total = 0
                if (votes && votes.length > 0) {
                    num = votes.filter((vote: any) => {
                        return vote.rank == rank
                    }).length
                    total = votes.length
                }
                return getPercentage(num, total)
            },
            filterSegment: {
                formIndex: 0,
                segments: [] as any[],
                curFilter: computed(() => {
                    if (analyzeObj.filterSegment.segments.length == 0) {
                        return null
                    }
                    return analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    analyzeObj.filterSegment.curFilter.selected = options
                    analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(options)
                    await setCollection(analyzeObj.filterSegment.curFilter, analyzeObj.filterSegment.formIndex)
                },
                reset: async () => {
                    console.log(`reset`)
                    analyzeObj.filterSegment.curFilter.selected = []
                    analyzeObj.filterSegment.curFilter.name = '--'
                    setCollection(analyzeObj.filterSegment.curFilter, analyzeObj.filterSegment.formIndex)
                }
            },
            filterConcept: {
                formIndex: 0,
                filtersName: root.$t('projectTestGroup.allSegment'),
                concepts: [] as any[],
                selected: [],
                curFilter: computed(() => {
                    if (analyzeObj.filterConcept.concepts.length == 0) {
                        return null
                    }
                    return analyzeObj.filterConcept.concepts[analyzeObj.filterConcept.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {

                    console.log(`handleChange`)
                    analyzeObj.filterConcept.selected = options
                    analyzeObj.filterConcept.filtersName = analyzeObj.filterConcept.getName(options)

                    analyzeObj.testGroups.map((group: any, index: any) => {
                        setTestGroupCollection(group, index)
                    })
                },
                reset: async () => {

                    analyzeObj.filterConcept.selected = []
                    analyzeObj.filterConcept.filtersName = '--'
                    analyzeObj.testGroups.map((group: any, index: any) => {
                        setTestGroupCollection(group, index)
                    })
                }
            },

            enableClass: (segment: any, itemSeg: any) => {
                // console.log(`enableClass`,segment.id,itemSeg.id)
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            },
            getTestGroupInfo: async () => {
                analyzeObj.testGroupInfo = await getTestGroupInfoApi({
                    uuid: props.item?.message.project_uuid,
                    testGroupId: props.item?.message.test_group.id,
                    observer: observer
                })
                analyzeObj.selectedConcept.id = analyzeObj.testGroupInfo?.material.options[0]?.id
                analyzeObj.selectedConcept.text = analyzeObj.testGroupInfo?.material.options[0]?.text
                analyzeObj.testGroupInfo?.material.options.map((item: any, index: any) => {
                    let obj = {
                        id: item.id,
                        name: item.text,
                        value: item.id,
                        selected: [],
                        link: item.link,
                        options: analyzeObj.testGroupInfo.material.options,
                        entity_type: analyzeObj.testGroupInfo.material.entity_type,
                        collection: null,
                        collections: null,
                    }
                    analyzeObj.testGroups.push(obj)
                    analyzeObj.filterConcept.concepts.push(obj)
                    setTestGroupCollection(obj, index)
                })
            },
            handleTypes: async (id: number) => {
                if (analyzeObj.selectedAnalyzeType == id) return;
                analyzeObj.selectedAnalyzeType = id;
                showConceptFilters.value = false
                showFilters.value = false
                // analyzeObj.selectedRounds=-1
                if (analyzeObj.selectedAnalyzeType == 2) {
                    // getSegments()
                    analyzeObj.segments.forEach((segment: any, index: any) => {
                        setCollection(segment, index)
                    })
                }else{
                analyzeObj.testGroups.map((group: any, index: any) => {
                    setTestGroupCollection(group, index)
                    })
                }
            },
            handleSegment: async (item: any) => {
                if (!item.segment) {
                    if (analyzeObj.filterConcept.selected.length > 0) {
                        analyzeObj.filterConcept.filtersName = analyzeObj.filterConcept.getName(analyzeObj.filterConcept.selected)
                    } else {
                        analyzeObj.filterConcept.filtersName = '--'
                    }
                    showConceptFilters.value = true
                    analyzeObj.selectedSegment = -1
                    analyzeObj.testGroups.map((group: any, index: any) => {
                        setTestGroupCollection(group, index)
                    })
                } else {
                    showConceptFilters.value = false
                    analyzeObj.selectedSegment = item.segment.id
                    analyzeObj.filterConcept.filtersName = item.segment.name
                    analyzeObj.testGroups.map((group: any, index: any) => {
                        setTestGroupCollection(group, index)
                    })
                }
            },
            chageConcept: (item: any) => {
                let obj = {
                    id: item.id,
                    name: item.text,
                    value: item.value,
                    selected: [],
                    options: analyzeObj.testGroupInfo.material.options,
                    collection: null,
                    collections: null,
                }
                analyzeObj.testGroups[item.index].value = item.value;
                analyzeObj.testGroups[item.index].name = item.text;
                analyzeObj.testGroups[item.index].link = item.link;
                setTestGroupCollection(obj, item.index)
            },
            chageTestGroupRound: async (item: any) => {
                analyzeObj.selectedRounds.value = item.index;
                analyzeObj.selectedRounds.text = item.text;
                if (analyzeObj.selectedAnalyzeType == 1) {
                    analyzeObj.testGroups.map((group: any, index: any) => {
                        group.collection = deepClone(getTestGroupCollection(group.collections, analyzeObj.selectedRounds.value))
                        analyzeObj.filterConcept.concepts[index].collection = deepClone(getTestGroupCollection(group.collections, analyzeObj.selectedRounds.value))
                    })
                } else if (analyzeObj.selectedAnalyzeType == 2) {
                    analyzeObj.segments.map((segment: any, index: any) => {
                        segment.collection = deepClone(getCollection(segment.collections, analyzeObj.selectedRounds.value))
                        analyzeObj.filterSegment.segments[index].collection = deepClone(getCollection(segment.collections, analyzeObj.selectedRounds.value))
                    })
                }




            },
            handleConcept: async (item: any) => {
                analyzeObj.selectedConcept.id = item.id;
                analyzeObj.selectedConcept.text = item.text;
                if (showConceptFilters.value) {
                    await setCollection(analyzeObj.filterSegment.curFilter, analyzeObj.filterSegment.formIndex)
                } else {
                    analyzeObj.segments.forEach((segment: any, index: any) => {
                        setCollection(segment, index)
                    })
                }
            },
             showVideoDialog: (url: string) => {
                if (url) {
                videoDialogRef.value.open(url);
                }
            },
        })

        watch(
        () => analyzeObj.originSegments,
        () => {
            getSegments([], []);
        }
        );

        onMounted(() => {
            initData()
        })

        onUnmounted(() => {
            analyzeObj.segments.forEach((segment: any) => {
                segment.collection = null
            })
            analyzeObj.filterSegment.segments.forEach((segment: any) => {
                segment.collection = null
            })
            updateAnalyzeConceptAskRank(props.item?.message.project_uuid, analyzeObj, props.item?.message.message_entity_id)
        })

        const initData = () => {
            analyzeObj.currentRound = props.item?.message.test_group.current_round
            let analyzeConceptAskRank = getAnalyzeConceptAskRank(props.item?.message.project_uuid, props.item?.message.message_entity_id)
            analyzeObj.options = props.item?.message.message_options.map((option: any) => {
                return {
                    id: option.id,
                    text: option.text,
                    link: option.link,
                    sequence: option.sequence
                }
            })
            analyzeObj.selectedOptions = analyzeObj.options[0].id;
            if (analyzeConceptAskRank != null) {
                analyzeObj.selectedAnalyzeType = analyzeConceptAskRank.selectedAnalyzeType
                analyzeObj.selectedSegment = analyzeConceptAskRank.selectedSegment

                analyzeObj.selectedConcept = analyzeConceptAskRank.selectedConcept
                analyzeObj.groups = analyzeConceptAskRank.groups
                analyzeObj.selectedRounds = analyzeConceptAskRank.selectedRounds
                analyzeObj.selectedOptions = analyzeConceptAskRank.selectedOptions
                analyzeObj.options = analyzeConceptAskRank.options
                analyzeObj.testGroupInfo = analyzeConceptAskRank.testGroupInfo
                analyzeObj.testGroups = analyzeConceptAskRank.testGroups
                analyzeObj.segments = analyzeConceptAskRank.segments
                analyzeObj.filterConcept.selected = analyzeConceptAskRank.filterConcept.selected
                
                if(analyzeObj.selectedSegment>-1){
                    let segmentId=analyzeObj.originSegments.filter((item:any)=>{return item.id==analyzeObj.selectedSegment})
                    if(segmentId.length>0){
                        analyzeObj.filterConcept.filtersName=segmentId[0].name
                    }else{
                        analyzeObj.selectedSegment =analyzeObj.originSegments[0].id;
                        analyzeObj.filterConcept.filtersName = analyzeObj.originSegments[0].name;
                        analyzeObj.testGroups.map((group: any, index: any) => {
                        setTestGroupCollection(group, index)
                        })
                    }
                    }
                analyzeObj.filterConcept.concepts = analyzeConceptAskRank.filterConcept.concepts
                getSegments(analyzeConceptAskRank.segments, analyzeConceptAskRank.filterSegment.segments)
            } else {
                analyzeObj.getTestGroupInfo()
                getSegments([], [])
            }

        }

        const getSegments = (segs: any, filterSegments: any) => {
            // analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : []
            // if (analyzeObj.segments.length <= 0) {
            //     analyzeObj.originSegments.forEach((segment: any, index: any) => {
            //         if (index < 3) {
            //             let clonesegment = cloneDeep(segment)
            //             analyzeObj.segments.push(clonesegment)
            //         }
            //     })
            // }

            // analyzeObj.filterSegment.segments = filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : []
            // if (analyzeObj.filterSegment.segments.length < 3) {
            //     for (let i = 0; i < 3; i++) {
            //         analyzeObj.filterSegment.segments.push({
            //             id: -1 - i,
            //             name: '--',
            //             selected: [],
            //             collection: null,
            //             collections: null
            //         })
            //     }
            // }

            analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : [];
            // 新增: 创建一个基于 originSegments 的 ID 集合
            const originSegmentIds = new Set(analyzeObj.originSegments.map((segment:any)=> segment.oldId));
            analyzeObj.segments = analyzeObj.segments.filter((segment:any)=> originSegmentIds.has(segment.oldId));
            analyzeObj.originSegments.forEach((segmentOrigin:any) => {
                let segment = analyzeObj.segments.find((s:any)=> s.oldId == segmentOrigin.oldId);
                if (!segment) {
                segment = cloneDeep(segmentOrigin);
                analyzeObj.segments.push(segment);
                } else {
                if(segment.id>-1){
                    segment.name =  analyzeObj.originSegments.filter((item:any)=> segment.id==item.id)[0].name;
                }
                }
            });

            analyzeObj.filterSegment.segments =
                filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : [];
                if (analyzeObj.filterSegment.segments.length <= 0) {
                analyzeObj.segments.forEach((segment:any,index:any)=>{
                    analyzeObj.filterSegment.segments.push({
                    id: -1 - index,
                    name: "--",
                    oldId:segment.oldId,
                    selected: [],
                    collection: null,
                    collections: null,
                    });
                })
            }

            const ids = segments.value.map((item: any) => item.id) ?? []
            console.log(`样本分组IDs: ${ids}`)
            // console.log( analyzeObj.segments)
            analyzeObj.segments.forEach((segment: any, index: any) => {
                if (segment.id > 0 && !ids.includes(segment.id)) {
                    segment.name = '--'
                    segment.id = null
                    segment.collection = null
                    segment.selected = []
                }
                setCollection(segment,index)
            })
        }

        const setCollection = async (item: any, index: any) => {
            if (item.id > -1) {
                const res = await getTestGroupMessageAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    tgid: props.item?.message.test_group.id,
                    project_uuid: props.item?.message.project_uuid,
                    test_group_id: props.item?.message.test_group.id,
                    test_option_id: analyzeObj.selectedConcept.id,
                    segment_id: item.id,
                    message_entity_id: props.item?.message.message_entity_id,
                    type: analyzeObj.selectedAnalyzeType,
                    observer: observer
                })
                if (res.collections.length > 0) {
                    analyzeObj.segments[index].collections = res.collections
                    analyzeObj.segments[index].collection = getCollection(res.collections, analyzeObj.selectedRounds.value)
                    analyzeObj.filterSegment.segments[index].collections = res.collections
                    analyzeObj.filterSegment.segments[index].collection = analyzeObj.segments[index].collection
                }
            } else {
                if (item.collections != null && item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res = await getTestGroupMessageAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        tgid: props.item?.message.test_group.id,
                        project_uuid: props.item?.message.project_uuid,
                        test_group_id: props.item?.message.test_group.id,
                        filter_option_ids: optionIds,
                        test_option_id: analyzeObj.selectedConcept.id,
                        segment_id: item.id,
                        message_entity_id: props.item?.message.message_entity_id,
                        type: analyzeObj.selectedAnalyzeType,
                        observer: observer
                    })
                    if (res.collections.length > 0) {
                        analyzeObj.segments[index].collections = res.collections
                        analyzeObj.segments[index].collection = getCollection(res.collections, analyzeObj.selectedRounds.value)
                        analyzeObj.filterSegment.segments[index].collections = res.collections
                        analyzeObj.filterSegment.segments[index].collection = analyzeObj.segments[index].collection
                    }
                } else {
                    analyzeObj.segments[index].collections = []
                    analyzeObj.segments[index].collection = null
                    analyzeObj.filterSegment.segments[index].collections = []
                    analyzeObj.filterSegment.segments[index].collection = null
                }
            }

        }

        const getCollection = (data: any, index: any) => {
            if (index > -1) {
                let collection = data ? data.filter((item: any) => (item.message.message_round - 1) == index) : []
                return collection.length > 0 ? collection[0] : null
            } else {
                let collection = data ? deepClone(data[0]) : { result: {} }
                collection.result = {
                    ops: {},
                    participants: 0
                }
                analyzeObj.options.map((option: any) => {
                    collection.result.ops[option.id] = {
                        id: option.id,
                        text: option.text,
                        sequence: option.sequence,
                        votes: []
                    }
                })
                data.map((item: any) => {
                    if (item.result != null) {
                        collection.result.participants += item.result.participants
                        Object.keys(item.result.ops).map((key: any) => {
                            if (item.result.ops[key].votes != null) {
                                collection.result.ops[key].votes = collection.result.ops[key].votes.concat(item.result.ops[key].votes)
                            }
                            // collection.result.ops[key].sequence+=item.result.ops[key].sequence
                        })
                    }
                })
                return collection
            }

        }

        const setTestGroupCollection = async (item: any, index: any) => {
            if (showConceptFilters.value) {
                const selected = analyzeObj.filterConcept.selected
                if (selected.length > 0) {
                    const optionIds = selected.map((option: any) => option.id)
                    const res = await getTestGroupMessageAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        tgid: props.item?.message.test_group.id,
                        project_uuid: props.item?.message.project_uuid,
                        test_group_id: props.item?.message.test_group.id,
                        test_option_id: item.value,
                        filter_option_ids: optionIds,
                        message_entity_id: props.item?.message.message_entity_id,
                        type: analyzeObj.selectedAnalyzeType,
                        observer: observer
                    })
                    if (res.collections.length > 0) {
                        analyzeObj.testGroups[index].collections = res.collections
                        analyzeObj.testGroups[index].collection = getTestGroupCollection(res.collections, analyzeObj.selectedRounds.value)
                        analyzeObj.filterConcept.concepts[index].collections = res.collections
                        analyzeObj.filterConcept.concepts[index].collection = analyzeObj.testGroups[index].collection
                    }
                } else {
                    analyzeObj.testGroups[index].collections = []
                    analyzeObj.testGroups[index].collection = null
                    analyzeObj.filterConcept.concepts[index].collections = []
                    analyzeObj.filterConcept.concepts[index].collection = null
                }

            } else {
                const res = await getTestGroupMessageAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    tgid: props.item?.message.test_group.id,
                    project_uuid: props.item?.message.project_uuid,
                    test_group_id: props.item?.message.test_group.id,
                    test_option_id: item.value,
                    segment_id: analyzeObj.selectedSegment,
                    message_entity_id: props.item?.message.message_entity_id,
                    type: analyzeObj.selectedAnalyzeType,
                    observer: observer
                })
                if (res.collections.length > 0) {
                    analyzeObj.testGroups[index].collections = res.collections
                    analyzeObj.testGroups[index].collection = getTestGroupCollection(res.collections, analyzeObj.selectedRounds.value)
                    analyzeObj.filterConcept.concepts[index].collections = res.collections
                    analyzeObj.filterConcept.concepts[index].collection = analyzeObj.testGroups[index].collection
                }
            }

        }

        const getTestGroupCollection = (data: any, index: any) => {
            if (index > -1) {
                let collection = data ? data.filter((item: any) => (item.message.message_round - 1) == index) : []
                return collection.length > 0 ? collection[0] : null
            } else {
                let collection = deepClone(data[0])
                collection.result = {
                    ops: {},
                    participants: 0
                }
                analyzeObj.options.map((option: any) => {
                    collection.result.ops[option.id] = {
                        id: option.id,
                        text: option.text,
                        sequence: option.sequence,
                        votes: []
                    }
                })
                data.map((item: any) => {
                    if (item.result != null) {
                        collection.result.participants += item.result.participants
                        Object.keys(item.result.ops).map((key: any) => {
                            if (item.result.ops[key].votes != null) {
                                collection.result.ops[key].votes = collection.result.ops[key].votes.concat(item.result.ops[key].votes)
                            }
                            // collection.result.ops[key].sequence+=item.result.ops[key].sequence
                        })
                    }
                })
                return collection
            }
        }


        const handleCommand = async (item: any) => {
            if (!item.segment) {
                const segment = analyzeObj.filterSegment.segments[item.index]
                analyzeObj.segments[item.index] = deepClone(segment)
                analyzeObj.filterSegment.formIndex = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                showFilters.value = true
                await setCollection(analyzeObj.segments[item.index], analyzeObj.filterSegment.formIndex)

            } else {
                showFilters.value = false
                let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                    return segment.id === item.segment.id
                })
                console.log()
                if (segmentItem) {
                    await setCollection(segmentItem, item.index)
                    analyzeObj.segments[item.index].name = segmentItem.name
                    analyzeObj.segments[item.index].id = segmentItem.id
                    item.index = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                    analyzeObj.segments.splice(item.index, 1, JSON.parse(JSON.stringify(analyzeObj.segments[item.index])))
                }else{
                    analyzeObj.segments[analyzeObj.filterSegment.formIndex].name=item.segment.name
                }
            }
        }
        //已封装的深拷贝函数
        const deepClone = (obj: any = {}, hashMap = new WeakMap()) => {
            let objClone: any = null,
                hashKey = hashMap.get(obj);
            if (obj instanceof RegExp) return new RegExp(obj);
            if (obj instanceof Date) return new Date(obj);
            if (hashKey) return hashKey;
            //判断是否需要继续进行递归
            if (typeof obj == "object" && obj !== null) {
                objClone = obj instanceof Array ? [] : {};
                hashMap.set(obj, objClone);
                //进行下一层递归克隆
                for (const i in obj) {
                    if (obj.hasOwnProperty(i)) {
                        objClone[i] = deepClone(obj[i], hashMap);
                    }
                }
            } else {
                objClone = obj;
            }

            return objClone;
        }

        return {
            showDrawer,
            showFilters,
            isCollapse,
            analyzeObj,
            handleCommand,
            showConceptFilters,
            onClosed,
            videoDialogRef,
            handleDropdownVisibleChange,
            tooltipRef
        }
    }
})
