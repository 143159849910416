


























































import { defineComponent, inject, computed, ref } from "@vue/composition-api";
export default defineComponent({
  components: {},
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {
    const head = inject("head");
    const previewList = computed(() => {
      const data: any = props.item?.message.message_options;
      if (typeof data === "string") {
        return JSON.parse(data).map((v: any) => v.link);
      } else {
        return props.item?.message.message_options.map((v: any) => v.link);
      }
    });

    const messageOptions = computed(() => {
      const data: any = props.item?.message.message_options;
      if (typeof data === "string") {
        return JSON.parse(data);
      } else {
        return props.item?.message.message_options;
      }
    });
    return {
      head,
      previewList,
      messageOptions,
    };
  },
});
