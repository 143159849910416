







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'NewMessagesBadge',
    props: {
        messageCount: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const handleClick = () => {
            emit('click');
        };

        return {
            handleClick,
        };
    }
});
