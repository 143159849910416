











import { defineComponent, inject, reactive } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { EntityType } from '@/api/project/model'
import { typeDurationOption } from '@/api/project/duration-option'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'

export default defineComponent({
    components: {
        Editor,
        TextCheckbox,
        TimeCustomSelect
    },
    setup() {
        const itemObj: any = inject('itemObj')
        const itemWay: any = inject('itemWay')

        if (itemWay.value == 'create') {
            itemObj.message_entity.entity_type = EntityType.rank
            itemObj.message_entity.duration = typeDurationOption[EntityType.rank].value
        }
        return {
            itemObj,
            EntityType
        }
    }
})
