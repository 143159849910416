/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "pbPush";

export enum Event {
  D = 0,
  ReloadDiscuss = 1,
  ReloadSegments = 2,
  FetchMessage = 3,
  NLPCompleteComputing = 4,
  UNRECOGNIZED = -1,
}

export function eventFromJSON(object: any): Event {
  switch (object) {
    case 0:
    case "D":
      return Event.D;
    case 1:
    case "ReloadDiscuss":
      return Event.ReloadDiscuss;
    case 2:
    case "ReloadSegments":
      return Event.ReloadSegments;
    case 3:
    case "FetchMessage":
      return Event.FetchMessage;
    case 4:
    case "NLPCompleteComputing":
      return Event.NLPCompleteComputing;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Event.UNRECOGNIZED;
  }
}

export function eventToJSON(object: Event): string {
  switch (object) {
    case Event.D:
      return "D";
    case Event.ReloadDiscuss:
      return "ReloadDiscuss";
    case Event.ReloadSegments:
      return "ReloadSegments";
    case Event.FetchMessage:
      return "FetchMessage";
    case Event.NLPCompleteComputing:
      return "NLPCompleteComputing";
    case Event.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PushReq {
  projectUUID: string;
  messageId: Long[];
  groupTicket: string;
  messageIdentifier: string;
}

export interface ErrorResp {
  message: string;
}

export interface RoomCounterReq {
  groupTicket: string;
}

export interface RoomCounterResp {
  count: number;
}

export interface PushNotifyReq {
  projectUUID: string;
  groupTicket: string;
  event: Event;
  messageID: string;
  messageEntityID: Long;
}

function createBasePushReq(): PushReq {
  return {
    projectUUID: "",
    messageId: [],
    groupTicket: "",
    messageIdentifier: "",
  };
}

export const PushReq = {
  encode(
    message: PushReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.projectUUID !== "") {
      writer.uint32(10).string(message.projectUUID);
    }
    writer.uint32(18).fork();
    for (const v of message.messageId) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.groupTicket !== "") {
      writer.uint32(26).string(message.groupTicket);
    }
    if (message.messageIdentifier !== "") {
      writer.uint32(34).string(message.messageIdentifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.projectUUID = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.messageId.push(reader.int64() as Long);
            }
          } else {
            message.messageId.push(reader.int64() as Long);
          }
          break;
        case 3:
          message.groupTicket = reader.string();
          break;
        case 4:
          message.messageIdentifier = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PushReq {
    return {
      projectUUID: isSet(object.projectUUID) ? String(object.projectUUID) : "",
      messageId: Array.isArray(object?.messageId)
        ? object.messageId.map((e: any) => Long.fromValue(e))
        : [],
      groupTicket: isSet(object.groupTicket) ? String(object.groupTicket) : "",
      messageIdentifier: isSet(object.messageIdentifier)
        ? String(object.messageIdentifier)
        : "",
    };
  },

  toJSON(message: PushReq): unknown {
    const obj: any = {};
    message.projectUUID !== undefined &&
      (obj.projectUUID = message.projectUUID);
    if (message.messageId) {
      obj.messageId = message.messageId.map((e) => (e || Long.ZERO).toString());
    } else {
      obj.messageId = [];
    }
    message.groupTicket !== undefined &&
      (obj.groupTicket = message.groupTicket);
    message.messageIdentifier !== undefined &&
      (obj.messageIdentifier = message.messageIdentifier);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PushReq>, I>>(object: I): PushReq {
    const message = createBasePushReq();
    message.projectUUID = object.projectUUID ?? "";
    message.messageId = object.messageId?.map((e) => Long.fromValue(e)) || [];
    message.groupTicket = object.groupTicket ?? "";
    message.messageIdentifier = object.messageIdentifier ?? "";
    return message;
  },
};

function createBaseErrorResp(): ErrorResp {
  return { message: "" };
}

export const ErrorResp = {
  encode(
    message: ErrorResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ErrorResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ErrorResp {
    return {
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: ErrorResp): unknown {
    const obj: any = {};
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ErrorResp>, I>>(
    object: I
  ): ErrorResp {
    const message = createBaseErrorResp();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseRoomCounterReq(): RoomCounterReq {
  return { groupTicket: "" };
}

export const RoomCounterReq = {
  encode(
    message: RoomCounterReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.groupTicket !== "") {
      writer.uint32(10).string(message.groupTicket);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomCounterReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomCounterReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.groupTicket = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RoomCounterReq {
    return {
      groupTicket: isSet(object.groupTicket) ? String(object.groupTicket) : "",
    };
  },

  toJSON(message: RoomCounterReq): unknown {
    const obj: any = {};
    message.groupTicket !== undefined &&
      (obj.groupTicket = message.groupTicket);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RoomCounterReq>, I>>(
    object: I
  ): RoomCounterReq {
    const message = createBaseRoomCounterReq();
    message.groupTicket = object.groupTicket ?? "";
    return message;
  },
};

function createBaseRoomCounterResp(): RoomCounterResp {
  return { count: 0 };
}

export const RoomCounterResp = {
  encode(
    message: RoomCounterResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomCounterResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomCounterResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.count = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RoomCounterResp {
    return {
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: RoomCounterResp): unknown {
    const obj: any = {};
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RoomCounterResp>, I>>(
    object: I
  ): RoomCounterResp {
    const message = createBaseRoomCounterResp();
    message.count = object.count ?? 0;
    return message;
  },
};

function createBasePushNotifyReq(): PushNotifyReq {
  return {
    projectUUID: "",
    groupTicket: "",
    event: 0,
    messageID: "",
    messageEntityID: Long.ZERO,
  };
}

export const PushNotifyReq = {
  encode(
    message: PushNotifyReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.projectUUID !== "") {
      writer.uint32(10).string(message.projectUUID);
    }
    if (message.groupTicket !== "") {
      writer.uint32(18).string(message.groupTicket);
    }
    if (message.event !== 0) {
      writer.uint32(24).int32(message.event);
    }
    if (message.messageID !== "") {
      writer.uint32(34).string(message.messageID);
    }
    if (!message.messageEntityID.isZero()) {
      writer.uint32(40).int64(message.messageEntityID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushNotifyReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushNotifyReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.projectUUID = reader.string();
          break;
        case 2:
          message.groupTicket = reader.string();
          break;
        case 3:
          message.event = reader.int32() as any;
          break;
        case 4:
          message.messageID = reader.string();
          break;
        case 5:
          message.messageEntityID = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PushNotifyReq {
    return {
      projectUUID: isSet(object.projectUUID) ? String(object.projectUUID) : "",
      groupTicket: isSet(object.groupTicket) ? String(object.groupTicket) : "",
      event: isSet(object.event) ? eventFromJSON(object.event) : 0,
      messageID: isSet(object.messageID) ? String(object.messageID) : "",
      messageEntityID: isSet(object.messageEntityID)
        ? Long.fromValue(object.messageEntityID)
        : Long.ZERO,
    };
  },

  toJSON(message: PushNotifyReq): unknown {
    const obj: any = {};
    message.projectUUID !== undefined &&
      (obj.projectUUID = message.projectUUID);
    message.groupTicket !== undefined &&
      (obj.groupTicket = message.groupTicket);
    message.event !== undefined && (obj.event = eventToJSON(message.event));
    message.messageID !== undefined && (obj.messageID = message.messageID);
    message.messageEntityID !== undefined &&
      (obj.messageEntityID = (message.messageEntityID || Long.ZERO).toString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PushNotifyReq>, I>>(
    object: I
  ): PushNotifyReq {
    const message = createBasePushNotifyReq();
    message.projectUUID = object.projectUUID ?? "";
    message.groupTicket = object.groupTicket ?? "";
    message.event = object.event ?? 0;
    message.messageID = object.messageID ?? "";
    message.messageEntityID =
      object.messageEntityID !== undefined && object.messageEntityID !== null
        ? Long.fromValue(object.messageEntityID)
        : Long.ZERO;
    return message;
  },
};

export interface PushService {
  PushMessageToUser(request: PushReq): Promise<ErrorResp>;
  RoomCounter(request: RoomCounterReq): Promise<RoomCounterResp>;
  PushNotify(request: PushNotifyReq): Promise<ErrorResp>;
}

export class PushServiceClientImpl implements PushService {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.PushMessageToUser = this.PushMessageToUser.bind(this);
    this.RoomCounter = this.RoomCounter.bind(this);
    this.PushNotify = this.PushNotify.bind(this);
  }
  PushMessageToUser(request: PushReq): Promise<ErrorResp> {
    const data = PushReq.encode(request).finish();
    const promise = this.rpc.request(
      "pbPush.PushService",
      "PushMessageToUser",
      data
    );
    return promise.then((data) => ErrorResp.decode(new _m0.Reader(data)));
  }

  RoomCounter(request: RoomCounterReq): Promise<RoomCounterResp> {
    const data = RoomCounterReq.encode(request).finish();
    const promise = this.rpc.request("pbPush.PushService", "RoomCounter", data);
    return promise.then((data) => RoomCounterResp.decode(new _m0.Reader(data)));
  }

  PushNotify(request: PushNotifyReq): Promise<ErrorResp> {
    const data = PushNotifyReq.encode(request).finish();
    const promise = this.rpc.request("pbPush.PushService", "PushNotify", data);
    return promise.then((data) => ErrorResp.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
