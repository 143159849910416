
























































































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi,getTestGroupInfoApi,getTestGroupMessageAnalyzeApi } from '@/api/project'
import { OrderType } from '@/api/project/model'
import { getPercentage } from '@/utils/common'
import { computed, defineComponent, inject, onMounted, reactive, ref, set, watch,onUnmounted } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { isNil, cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useProjectStore } from '@/pinia/modules/project'

export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { root, emit }) {
        const showDrawer = ref(props.value)
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
               
            }
        )
        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)

        const segments: any = inject('segments')
        console.log(segments)
        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        const projectStore = useProjectStore()
        const {  getAnalyzeConceptAskExperience,updateAnalyzeConceptAskExperience } = projectStore


        watch(
            () => {
                return props.item
            },
            (value, old) => {
                if(analyzeObj.selectedAnalyzeType==1){
                    analyzeObj.testGroups.forEach((segment: any,index:any)=>{
                        setTestGroupCollection(segment,index)
                    })
                    analyzeObj.orderConcept.refreshOrder()
                }else if(analyzeObj.selectedAnalyzeType==2){
                    analyzeObj.segments.forEach((segment: any,index:any) => {
                        setCollection(segment,index)
                    })
                    analyzeObj.orderObj.refreshOrder()
                }
            }
        )

        const analyzeObj: any = reactive({
            loading: false,
            current_round:null,
            selectedAnalyzeType:1,
            analyzeTypes:[{
                id:1,
                name:root.$t('projectTestGroup.conceptualContrast')
            },{
                id:2,
                name:root.$t('projectTestGroup.populationComparison')
            }],
            selectedSegment:0,
            selectedConcept:null,
            selectedRounds:-1,
            testGroupInfo:{},
            testGroups:[] as any[],
            options: [] as any[],
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                participants: 0,
                orderType: OrderType.None,
                categorys: [] as any[],
                collection: props.item?.collection
            },
            categorys: computed(() => {
                return props.item?.message?.message_options ?? []
            }),
            selecteds: [] as any[],
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            segments: [] as any[],
            orderObj: {
                curOrderSegments: {},
                setType: (index: number, orderType: OrderType) => {
                    set(analyzeObj.orderObj.curOrderSegments, index, orderType)
                },
                getType: (index: number) => {
                    let orderType = analyzeObj.orderObj.curOrderSegments[index] ?? OrderType.None
                    analyzeObj.orderObj.curOrderSegments[index] = orderType
                    return orderType
                },
                clearAllOrder: () => {
                    analyzeObj.orderObj.curOrderSegments = {}
                    analyzeObj.orderObj.refreshOrder()
                },
                changeOrder: (index: number, orderType?: OrderType) => {
                    if (isNil(index)) {
                        return
                    }
                    const type = analyzeObj.orderObj.getType(index)

                    if (type == OrderType.None) {
                        orderType = OrderType.DESC
                    } else if (type == OrderType.DESC) {
                        orderType = OrderType.ASC
                    } else if (type == OrderType.ASC) {
                        orderType = OrderType.None
                    }
                    analyzeObj.orderObj.setType(index, orderType)
                    console.log(`orderType: ${analyzeObj.orderObj.getType(index)}`)
                    analyzeObj.orderObj.curOrderSegments = { ...analyzeObj.orderObj.curOrderSegments }
                },
                refreshOrder: () => {
                    analyzeObj.segments.forEach((segment: any) => {
                        analyzeObj.orderObj.setType(segment, analyzeObj.orderObj.getType(segment))
                    })
                }
            },
            orderConcept: {
                curOrderSegments: {} as any,
                setType: (index: number, orderType: OrderType) => {
                    set(analyzeObj.orderConcept.curOrderSegments, index, orderType)
                },
                getType: (index: number) => {
                    let orderType = analyzeObj.orderConcept.curOrderSegments[index] ?? OrderType.None
                    analyzeObj.orderConcept.curOrderSegments[index] = orderType
                    return orderType
                },
                clearAllOrder: () => {
                    analyzeObj.orderConcept.curOrderSegments = {}
                    analyzeObj.orderConcept.refreshOrder()
                },
                changeOrder: (index: number, orderType?: OrderType) => {
                    if (isNil(index)) {
                        return
                    }
                    const type = analyzeObj.orderConcept.getType(index)

                    if (type == OrderType.None) {
                        orderType = OrderType.DESC
                    } else if (type == OrderType.DESC) {
                        orderType = OrderType.ASC
                    } else if (type == OrderType.ASC) {
                        orderType = OrderType.None
                    }
                    analyzeObj.orderConcept.setType(index, orderType)
                    console.log(`orderType: ${analyzeObj.orderConcept.getType(index)}`)
                    console.log( analyzeObj.testGroups[index],' analyzeObj.testGroups.')

                    analyzeObj.orderConcept.curOrderSegments = { ...analyzeObj.orderConcept.curOrderSegments }
                },
                refreshOrder: () => {
                    analyzeObj.testGroups.forEach((segment: any) => {
                        analyzeObj.orderConcept.setType(segment, analyzeObj.orderConcept.getType(segment))
                    })
                }
            },
            collapseObj: {
                collapseMap: {},
                isCollapse: (op: any, segIndex: number) => {
                    return analyzeObj.collapseObj.collapseMap[`${segIndex}-${op.id}`] ?? false
                },
                handleChange: (op: any, segIndex: number) => {
                    set(analyzeObj.collapseObj.collapseMap, `${segIndex}-${op.id}`, !analyzeObj.collapseObj.isCollapse(op, segIndex))
                }
            },
            filterConcept: {
                formIndex: 0,
                filtersName:root.$t('projectTestGroup.allSegment'),
                concepts: [] as any[],
                selected:[],
                curFilter: computed(() => {
                    if (analyzeObj.filterConcept.concepts.length == 0) {
                        return null
                    }
                    return analyzeObj.filterConcept.concepts[analyzeObj.filterConcept.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            },
            getCategorys: (segment: any, index: number = 0) => {
                let originCategorys: any[] = []
                if (!segment.collection) {
                    return originCategorys
                }
                const categoryObj = segment.collection.result?.category

                if (categoryObj) {
                    originCategorys = Object.values(categoryObj).filter((category: any) => {
                        return analyzeObj.selecteds.includes(category.id)
                    })
                    originCategorys.forEach((category: any) => {
                        category.key = uuidv4()
                        category.percentage = getPercentage(category.participants, analyzeObj.getParticipants(segment))
                    })
                    let orderType=OrderType.None
                    if(analyzeObj.selectedAnalyzeType==1){
                         orderType = analyzeObj.orderConcept.getType(index)
                    }else if(analyzeObj.selectedAnalyzeType==2){
                         orderType = analyzeObj.orderObj.getType(index)
                    }
                    switch (orderType) {
                        case OrderType.None:
                            originCategorys.sort((a: any, b: any) => {
                                return a.sequence - b.sequence
                            })
                            break
                        case OrderType.DESC:
                            originCategorys.sort((a: any, b: any) => {
                                if (a.participants === b.participants) {
                                    return a.sequence - b.sequence
                                } else {
                                    return b.participants - a.participants
                                }
                            })
                            break
                        case OrderType.ASC:
                            originCategorys.sort((a: any, b: any) => {
                                if (a.participants === b.participants) {
                                    return a.sequence - b.sequence
                                } else {
                                    return a.participants - b.participants
                                }
                            })
                            break
                    }
                }
                return originCategorys
            },
            getParticipants: (segment: any) => {
                if (!segment?.collection) {
                    return 0
                }
                if(!segment.collection.result){
                    return 0
                }
                return segment.collection.result.participants
            },
            handleSelect: (option: any) => {

                if (analyzeObj.selecteds.includes(option.id)) {
                    analyzeObj.selecteds = analyzeObj.selecteds.filter((id: any) => {
                        return id !== option.id
                    })
                } else {
                    analyzeObj.selecteds.push(option.id)
                }
            },
            getTestGroupInfo:async () => {
                analyzeObj.testGroupInfo =  await getTestGroupInfoApi({
                            uuid: props.item?.message.project_uuid,
                            testGroupId:props.item?.message.test_group.id,
                            observer: observer
                })
                analyzeObj.selectedConcept=analyzeObj.testGroupInfo?.material.options[0]?.id
                analyzeObj.testGroupInfo?.material.options.map((item:any,index:any)=>{
                    let obj={
                        id:item.id,
                        name:item.text,
                        value:item.id,
                        selected: [],
                        options: analyzeObj.testGroupInfo.material.options,
                        collection: null,
                        collections:null,
                    }
                    analyzeObj.testGroups.push(obj)
                    setTestGroupCollection(obj,index)
                })

            },
            handleTypes : async ()=>{
                if(analyzeObj.selectedAnalyzeType==2){
                    analyzeObj.segments.forEach((segment: any,index:any) => {
                    setCollection(segment,index)
             })
                }
            },
            handleSegment:async (item: any)=>{
                 analyzeObj.selectedSegment=item.segment.id
                 analyzeObj.filterConcept.filtersName=item.segment.name
                 analyzeObj.testGroups.map((group:any,index:any)=>{
                    console.log(group,'===group')
                     setTestGroupCollection(group,index)
                 })
            },
            chageConcept:(id:any,index:any)=>{
                console.log(analyzeObj.testGroups,analyzeObj.testGroups[index])
                let obj={
                        id:id,
                        name:analyzeObj.testGroups[index].name,
                        value:id,
                        selected: [],
                        options: analyzeObj.testGroupInfo.material.options,
                        collection: null,
                        collections:null,
                    }
                setTestGroupCollection(obj,index)
            },
            chageTestGroupRound:async ()=>{
                if(analyzeObj.selectedAnalyzeType==1){
                    analyzeObj.testGroups.map((group:any,index:any)=>{
                    group.collection=deepClone(getCollection(group.collections,analyzeObj.selectedRounds))
                 })
                }else if(analyzeObj.selectedAnalyzeType==2){
                    analyzeObj.segments.map((segment:any,index:any)=>{
                    segment.collection=deepClone(getCollection(segment.collections,analyzeObj.selectedRounds))
                    })
                }
               

               

            },
            handleConcept:async ()=>{
                analyzeObj.segments.forEach((segment: any,index:any) => {
                setCollection(segment,index)
                })
            },
        })

        onMounted(() => {
            initData()
           
        })

        onUnmounted(() => {
            analyzeObj.segments.forEach((segment: any) => {
                segment.collection = null
            })
            updateAnalyzeConceptAskExperience(props.item?.message.project_uuid, analyzeObj,props.item?.message.message_entity_id)
        })

        const initData = async () => {
            analyzeObj.current_round=props.item?.message.test_group.current_round
            let analyzeConceptAskExperience=getAnalyzeConceptAskExperience(props.item?.message.project_uuid,props.item?.message.message_entity_id)
            analyzeObj.selecteds = analyzeObj.categorys.map((category: any) => {
                return category.id
            })
            if(analyzeConceptAskExperience!=null){
                analyzeObj.selectedAnalyzeType = analyzeConceptAskExperience.selectedAnalyzeType
                analyzeObj.selectedSegment=analyzeConceptAskExperience.selectedSegment
                analyzeObj.selectedConcept=analyzeConceptAskExperience.selectedConcept
                analyzeObj.groups=analyzeConceptAskExperience.groups
                analyzeObj.selectedRounds=analyzeConceptAskExperience.selectedRounds
                analyzeObj.selectedOptions=analyzeConceptAskExperience.selectedOptions
                analyzeObj.options=analyzeConceptAskExperience.options
                analyzeObj.testGroupInfo=analyzeConceptAskExperience.testGroupInfo
                analyzeObj.testGroups=analyzeConceptAskExperience.testGroups
                analyzeObj.segments=analyzeConceptAskExperience.segments
                analyzeObj.intersect=analyzeConceptAskExperience.intersect
                analyzeObj.filterConcept.selected =analyzeConceptAskExperience.filterConcept.selected
                analyzeObj.filterConcept.filtersName = analyzeConceptAskExperience.filterConcept.filtersName
                analyzeObj.filterConcept.concepts=analyzeConceptAskExperience.filterConcept.concepts
                getSegments( analyzeConceptAskExperience.segments)
            }else{
                analyzeObj.getTestGroupInfo()
                getSegments([])

            }

        }


        const setCollection = async (item: any,index:any) => {
            if(item.id>-1){
                    const res = await getTestGroupMessageAnalyzeApi({
                    uuid:  props.item?.message.project_uuid,
                    tgid: props.item?.message.test_group.id,
                    project_uuid:props.item?.message.project_uuid,
                    test_group_id:props.item?.message.test_group.id,
                    test_option_id:analyzeObj.selectedConcept,
                    segment_id:item.id,
                    message_entity_id:props.item?.message.message_entity_id,
                    type:analyzeObj.selectedAnalyzeType,
                    observer: observer
                })
                    if (res.collections.length > 0) {
                        analyzeObj.segments[index].collections = res.collections
                        analyzeObj.segments[index].collection=getCollection(res.collections,-1)
                    }
                }else{
                   
                 if (item.collections!=null&&item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res = await getTestGroupMessageAnalyzeApi({
                        uuid:  props.item?.message.project_uuid,
                        tgid: props.item?.message.test_group.id,
                        project_uuid:props.item?.message.project_uuid,
                        test_group_id:props.item?.message.test_group.id,
                        filter_option_ids:optionIds,
                        test_option_id:analyzeObj.selectedConcept,
                        segment_id:item.id,
                        message_entity_id:props.item?.message.message_entity_id,
                        type:analyzeObj.selectedAnalyzeType,
                        observer: observer
                    })
                    if (res.collections.length > 0) {
                        analyzeObj.segments[index].collections = res.collections
                        analyzeObj.segments[index].collection=getCollection(res.collections,-1)
                    }
                } else {
                    analyzeObj.segments[index].collections =[]
                    analyzeObj.segments[index].collection=null
                }
                }
              
        }
        const getSegments=(segs:any)=>{
            analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : []
            if(analyzeObj.segments.length<=0){
                analyzeObj.originSegments.forEach((segment: any,index:any)=>{
                    if(index<2){
                        let clonesegment = cloneDeep(segment)
                        analyzeObj.segments.push(clonesegment)
                    }
                })
            }
        }

        const setTestGroupCollection=async (item: any,index:any) => {
          
                const res = await getTestGroupMessageAnalyzeApi({
                    uuid:  props.item?.message.project_uuid,
                    tgid: props.item?.message.test_group.id,
                    project_uuid:props.item?.message.project_uuid,
                    test_group_id:props.item?.message.test_group.id,
                    test_option_id:item.value,
                    segment_id:analyzeObj.selectedSegment,
                    message_entity_id:props.item?.message.message_entity_id,
                    type:analyzeObj.selectedAnalyzeType,
                    observer: observer
                })
                 if (res.collections.length > 0) {
                    analyzeObj.testGroups[index].collections = res.collections
                    analyzeObj.testGroups[index].collection=getCollection(res.collections,-1)
                }

        }
        const getCollection=(data: any,index:any)=>{
           if(index>-1){
                let collection= data?data.filter((item: any) => (item.message.message_round-1) == index):[]
                return collection.length>0?collection[0]:null
            }else{
             let collection =data?deepClone(data[0]):{result:{}}
             collection.result={
                category:{},
                participants:0
             }
             analyzeObj.categorys.map((option: any)=>{
                collection.result.category[option.id]={
                    id:option.id,
                    text:option.text,
                    sequence:option.sequence,
                    participants:0,
                    participant_reply:[]
                }
             })
             data.map((item:any)=>{
                if(item.result!=null){
                    collection.result.participants+=item.result.participants
                    Object.keys(item.result.category).map((key:any)=>{
                        if(item.result.category[key].participant_reply!=null){
                            collection.result.category[key].participant_reply=collection.result.category[key].participant_reply.concat(item.result.category[key].participant_reply)
                        }
                        collection.result.category[key].participants+=item.result.category[key].participants
                    })
                }
             })
             return collection
            }
           
        }
        

        const handleCommand = async (item: any) => {
            console.log(item.index)
            const segmentItem = analyzeObj.originSegments.find((segment: any) => {
                return segment.id === item.segment.id
            })
            const segment = JSON.parse(JSON.stringify(segmentItem))
            analyzeObj.segments.splice(item.index, 1, segment)
            await setCollection(segment,item.index)
        }

           //已封装的深拷贝函数
           const deepClone=(obj:any = {}, hashMap = new WeakMap()) =>{
            let objClone:any = null,
                hashKey = hashMap.get(obj);
            if (obj instanceof RegExp) return new RegExp(obj); 
            if (obj instanceof Date) return new Date(obj); 
            if (hashKey) return hashKey;
            //判断是否需要继续进行递归
            if (typeof obj == "object" && obj !== null) {
                objClone = obj instanceof Array ? [] : {};
                hashMap.set(obj, objClone);
                //进行下一层递归克隆
                for (const i in obj) {
                if (obj.hasOwnProperty(i)) {
                    objClone[i] = deepClone(obj[i], hashMap);
                }
                }
            } else {
                objClone = obj;
            }
            
            return objClone;
        }

        return {
            OrderType,
            showDrawer,
            showFilters,
            analyzeObj,
            handleCommand,
            onClosed
        }
    }
})
