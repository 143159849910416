

































import { EntityType } from '@/api/project/model'
import { timeFormatStr } from '@/utils/time-format'
import { defineComponent, reactive, ref, Ref, watch } from '@vue/composition-api'
import _ from 'lodash'
import { typeDurationOption } from '@/api/project/duration-option'

export default defineComponent({
    props: {
        value: {
            type: Number
        },
        entityType: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, emit }) {
        const timeCustomNumberRef: Ref<any> = ref()
        const isCustom = ref(false)
        const customInfo = reactive({
            value: '',
            onChange: () => {
                const value = ~~customInfo.value
                if (value) {
                    const item = timeInfo.options.find((v) => v.value == value)
                    if (item) {
                        timeInfo.value = item.value
                    } else {
                        timeInfo.options.push({
                            value: value,
                            label: timeFormatStr(value)
                        })
                        const list = timeInfo.options.filter((v: any) => v.value !== 'custom')
                        timeInfo.options = _.orderBy(list, ['value'], ['asc'])
                        timeInfo.options.push({
                            value: 'custom',
                            label: root.$t('custom')
                        })
                        timeInfo.value = value
                    }
                } else {
                    timeInfo.value = timeInfo.options[0].value
                }
                isCustom.value = false
            }
        })
        const timeInfo = reactive({
            value: props.value as any,
            options: [] as any[],
            onChange() {
                isCustom.value = timeInfo.value === 'custom'
                if (timeInfo.value === 'custom') {
                    customInfo.value = ''
                    setTimeout(() => timeCustomNumberRef.value.focus())
                }
            }
        })

        const handleOptions = () => {

            const durationInfo = _.cloneDeep(typeDurationOption[props.entityType!])

            if (!timeInfo.value) {
                timeInfo.value = durationInfo.value
            }

            timeInfo.options = durationInfo.options

            const exist = durationInfo.options.some((v: any) => v.value == timeInfo.value)
            if (!exist) {
                timeInfo.options.push({
                    value: timeInfo.value,
                    label: timeFormatStr(timeInfo.value!)
                })
                timeInfo.options = _.orderBy(timeInfo.options, ['value'], ['asc'])
            }
            timeInfo.options.push({
                value: 'custom',
                label: root.$t('custom')
            })
        }
        handleOptions()

        watch(
            () => timeInfo.value,
            () => {
                if (timeInfo.value !== 'custom') {
                    emit('input', timeInfo.value)
                    emit('change', timeInfo.value)
                }
            }
        )
        return { isCustom, customInfo, timeInfo, timeCustomNumberRef }
    }
})
