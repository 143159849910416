import { w3cwebsocket, IMessageEvent, ICloseEvent } from 'websocket'
import { Buffer } from 'buffer'
import log from 'loglevel-es'
import { Command, LogicPkt, MagicBasicPktInt, Ping } from './packet'
import { Flag, Status } from './proto/common'
import { LoginReq, LoginResp, KickoutNotify, RefuseObserver } from './proto/protocol'
import { Conversation, Reply, ErrorResp, MessageAck, Message, PullMessageReq, PullMessageResp } from './proto/message'
import Long from 'long'
import localforage from 'localforage'
import { PushNotifyReq } from './proto/push'


const sendTimeout = 5 * 1000
const loginTimeout = 10 * 1000
const heartbeatInterval = 55 * 1000


const retryLogin = 5

const maxRetryLogin = 100

enum TimeUnit {
    Second = 1000,
    Millisecond = 1
}

export let sleep = async (second: number, Unit: TimeUnit = TimeUnit.Second): Promise<void> => {
    return new Promise((resolve, _) => {
        setTimeout(() => {
            resolve()
        }, second * Unit)
    })
}

export enum LoginGuard {
    Interviewee = 'interviewee',
    Admin = 'admin',
    Observer = 'observer',
    Moderator = 'moderator'
}

export enum State {
    INIT,
    CONNECTING,
    CONNECTED,
    RECONNECTING,
    CLOSEING,
    CLOSED
}


export enum ARTStatus {
    RequestTimeout = 10,
    SendFailed = 11
}

export enum ARTEvent {
    Reconnecting = 'Reconnecting',
    Reconnected = 'Reconnected',
    Closed = 'Closed',
    Kickout = 'Kickout',
    Notify = 'Notify',
    Refuse = 'Refuse'
}

export enum ARTNotifyEventType {
    QuestionUpdate = 1,
    SegmentUpdate = 2,
    NLPCompleteComputing = 4
}


export class LoginBody {
    public token: string
    public guard: string
    public projectUuid: string
    constructor(token: string, projectUuid: string, guard: string) {
        this.token = token
        this.guard = guard
        this.projectUuid = projectUuid
    }
}
export class Response {
    public status: number
    public payload: Uint8Array
    constructor(status: number, payload: Uint8Array = new Uint8Array()) {
        this.status = status
        this.payload = payload
    }
}

export class Request {
    public sendTime: number
    public data: LogicPkt
    public callback: (response: LogicPkt) => void
    constructor(data: LogicPkt, callback: (response: LogicPkt) => void) {
        this.sendTime = Date.now()
        this.data = data
        this.callback = callback
    }
}

export class MessageBody {
    public id: Long
    public tokenId: Long
    public projectUuid: string
    public messageId: string
    public replyId: Long
    public sequence: Long
    public conversation: Conversation | undefined
    public reply: Reply[]
    public isNewest?: boolean
    constructor(id: Long, tokenId: Long, projectUuid: string, messageId: string, replyId: Long, sequence: Long, conversation: Conversation | undefined, reply: Reply[]) {
        this.id = id
        this.tokenId = tokenId
        this.projectUuid = projectUuid
        this.messageId = messageId
        this.replyId = replyId
        this.sequence = sequence
        this.conversation = conversation
        this.reply = reply
    }
}

export class SendMessageBody {
    public conversationBody?: ConversationBody
    public replyBody?: ReplyBody
    constructor(conversationBody?: ConversationBody, replyBody?: ReplyBody) {
        this.conversationBody = conversationBody
        this.replyBody = replyBody
    }
}

export class ReplyBody {
    public projectUuid: string
    public token?: string = undefined
    public replyContent?: string = undefined
    public replyIndex?: string = undefined
    public messageId?: string = undefined
    public replyStage?: string = undefined
    public replyType?: string = undefined
    public replyTime?: string = undefined
    public messageMeta?: string = undefined
    public ranks?: string = undefined
    constructor(projectUuid: string, token?: string, replyContent?: string, replyIndex?: string, messageId?: string, replyStage?: string, replyType?: string, replyTime?: string, messageMeta?: string, ranks?: string) {
        this.messageId = messageId
        this.projectUuid = projectUuid
        this.replyIndex = replyIndex
        this.replyContent = replyContent
        this.token = token
        this.replyStage = replyStage
        this.replyType = replyType
        this.replyTime = replyTime
        this.messageMeta = messageMeta
        this.ranks = ranks
    }
}

export class ConversationBody {
    public projectUuid: string
    public sequence?: number
    public messageEntityId?: string
    public messageStage?: string
    public messageId?: string
    public messageReplyExpiredAt?: string
    public messageReplyQuantity?: number
    public messageType?: string
    public messageDuration?: number
    public messageContent?: string
    public messageLink?: string
    public messageMeta?: string
    public messageOptions?: string
    public messageCreatedAt?: string
    constructor(
        projectUuid: string,
        sequence?: number,
        messageEntityId?: string,
        messageStage?: string,
        messageId?: string,
        messageReplyExpiredAt?: string,
        messageReplyQuantity?: number,
        messageType?: string,
        messageDuration?: number,
        messageContent?: string,
        messageLink?: string,
        messageMeta?: string,
        messageOptions?: string,
        messageCreatedAt?: string
    ) {
        this.projectUuid = projectUuid
        this.sequence = sequence
        this.messageEntityId = messageEntityId
        this.messageStage = messageStage
        this.messageId = messageId
        this.messageReplyExpiredAt = messageReplyExpiredAt
        this.messageReplyQuantity = messageReplyQuantity
        this.messageType = messageType
        this.messageDuration = messageDuration
        this.messageContent = messageContent
        this.messageLink = messageLink
        this.messageMeta = messageMeta
        this.messageOptions = messageOptions
        this.messageCreatedAt = messageCreatedAt
    }
}

export class ARTClient {
    public wsurl: string

    public state = State.INIT

    public channelId: string

    private req?: LoginBody

    private conn?: w3cwebsocket

    private lastRead: number

    private lastMessage?: MessageBody

    private retryLogin: number = retryLogin

    private maxRetryLogin: number = maxRetryLogin

    private listeners = new Map<string, (e: ARTEvent, d: any) => void>()

    private sendq = new Map<number, Request>()

    private messageCallback: (m: MessageBody) => void

    private offmessageCallback: (m: MessageBody[]) => void

    private closeCallback?: () => void

    constructor(url: string) {
        this.wsurl = url
        this.lastRead = Date.now()
        this.channelId = ''
        this.messageCallback = (m: MessageBody) => {
            log.warn(`Error, Please check you had register a messageCallback method before login`)
        }
        this.offmessageCallback = (m: MessageBody[]) => {
            log.warn(`Error, Please check you had register a offmessageCallback method before login`)
        }
    }

    public register(events: string[], callback: (e: ARTEvent, data?: any) => void) {
        events.forEach((event) => {
            this.listeners.set(event, callback)
        })
    }

    public onmessage(cb: (m: MessageBody) => void) {
        this.messageCallback = cb
    }

    public onofflinemessage(cb: (m: MessageBody[]) => void) {
        this.offmessageCallback = cb
    }

    public async talk(req: SendMessageBody, retry: number = 3): Promise<{ status: number, resp?: MessageAck, err?: ErrorResp }> {
        let pbMessage = null
        let command = null

        if (!!req.conversationBody) {
            console.log(req.conversationBody)
            pbMessage = Conversation.encode(Conversation.fromJSON(req.conversationBody)).finish()
            command = Command.ModeratorTalk
        }

        if (!!req.replyBody) {
            pbMessage = Reply.encode(Reply.fromJSON(req.replyBody)).finish()
            command = Command.ChatUserTalk
        }

        if (pbMessage == null || command == null) {
            return { status: ARTStatus.SendFailed, err: new Error('SendMessageBody or command is null') }
        }

        for (let index = 0; index < retry + 1; index++) {
            const pkt = LogicPkt.build(command, pbMessage)
            const resp = await this.request(pkt)
            if (resp.status == Status.Success) {
                return { status: Status.Success, resp: MessageAck.decode(resp.payload) }
            }
            if (resp.status >= 300 && resp.status < 400) {

                log.warn('Retry send message')
                await sleep(2)
                continue
            }

            log.info(resp)
            const err = ErrorResp.decode(resp.payload)
            return { status: resp.status, err }
        }
        return { status: ARTStatus.SendFailed, err: new Error('Over max retry times') }
    }

    public async request(data: LogicPkt): Promise<Response> {
        return new Promise((resolve, _) => {
            const seq = data.sequence

            const tr = setTimeout(() => {

                this.sendq.delete(seq)
                resolve(new Response(ARTStatus.RequestTimeout))
            }, sendTimeout)


            const callback = (pkt: LogicPkt) => {
                clearTimeout(tr)

                this.sendq.delete(seq)

                resolve(new Response(pkt.status, pkt.payload))
            }
            log.debug(`request seq:${seq} command:${data.command}`)

            this.sendq.set(seq, new Request(data, callback))
            if (!this.send(data.bytes())) {
                resolve(new Response(ARTStatus.SendFailed))
            }
        })
    }

    public async login(req: LoginBody | undefined): Promise<{ success: boolean, err?: Error }> {
        this.req = req

        if (this.req == undefined) {
            return { success: false, err: new Error('undefined login params') }
        }

        if (this.state == State.CONNECTED) {
            return { success: false, err: new Error('client has already been connected') }
        }

        this.state = State.CONNECTING

        const { success, err, channelId, conn } = await this.doLogin(this.wsurl, this.req)

        if (!success) {
            this.state = State.INIT
            return { success, err }
        }


        conn.onmessage = (evt: IMessageEvent) => {
            try {
                this.lastRead = Date.now()
                const buf = Buffer.from(evt.data as ArrayBuffer)
                const magic = buf.readInt32BE(0)

                if (magic == MagicBasicPktInt) {
                    log.info('server response pong')
                    return
                }
                log.info('connection fire【onmessage】')
                const pkt = LogicPkt.from(buf)
                this.packetHandler(pkt)
            } catch (error) {
                log.error(evt.data, error)
            }
        }

        conn.onerror = (error) => {
            log.info('connection fire【onerror】')
            this.errorHandler(error)
        }

        conn.onclose = (e: ICloseEvent) => {
            log.info('connection fire【onclose】')
            if (this.state == State.CLOSEING) {
                this.onclose('logout')
                return
            }
            this.errorHandler(new Error(e.reason))
        }
        log.info('websocket connected')
        this.conn = conn
        this.channelId = channelId || ''
        await this.loadOfflineMessage(this.req.token, this.req.projectUuid)
        this.state = State.CONNECTED
        this.heartbeatLoop()
        this.readDeadlineLoop()
        return { success, err }
    }

    public logout(): Promise<void> {
        return new Promise((resolve, _) => {
            if (this.state === State.CLOSEING) {
                return
            }
            this.state = State.CLOSEING
            if (!this.conn) {
                return
            }
            const tr = setTimeout(() => {
                log.debug('logout is timeout~')
                this.onclose('logout')
                resolve()
            }, 1500)

            this.closeCallback = async () => {
                clearTimeout(tr)
                await sleep(1)
                resolve()
            }

            this.conn.close()
            log.info('Connection closing...')
        })
    }

    private fireEvent(event: ARTEvent, data?: any) {
        const listener = this.listeners.get(event)
        if (!!listener) {
            listener(event, data)
        }
    }

    private async packetHandler(pkt: LogicPkt) {
        if (pkt.status >= 400) {
            log.info(`need relogin due to status ${pkt.status}`)
            this.conn?.close()
            return
        }
        if (pkt.flag == Flag.Response) {
            const req = this.sendq.get(pkt.sequence)
            if (req) {
                log.debug(`response seq: ${pkt.sequence} trigger callback`, pkt)
                req.callback(pkt)
            } else {
                log.error(`response of ${pkt.sequence} no found in sendq`)
            }
            return
        }
        switch (pkt.command) {

            case Command.MessageIn:
                const message = Message.decode(pkt.payload)

                const messageBody = new MessageBody(
                    message.id,
                    message.tokenId,
                    message.projectUuid,
                    message.messageId,
                    message.replyId,
                    message.sequence,
                    message.conversation,
                    message.reply
                )

                if (!await Store.exist(message.id)) {
                    if (this.state == State.CONNECTED) {


                        if (!this.lastMessage && message.sequence.toNumber() == 1) {
                            messageBody.isNewest = true
                        }

                        if (this.lastMessage && this.lastMessage.sequence.toNumber() == (message.sequence.toNumber() - 1)) {
                            messageBody.isNewest = true
                        }

                        this.lastMessage = messageBody

                        try {
                            this.messageCallback(messageBody)
                        } catch (error) {
                            log.error(error)
                        }
                    }

                    await Store.insert(message)
                }

                break
            case Command.SignIn:
                const ko = KickoutNotify.decode(pkt.payload)
                if (ko.channelId == this.channelId) {
                    this.logout()
                    this.fireEvent(ARTEvent.Kickout)
                }
                break
            case Command.RefuseObserver:

                const refuse = RefuseObserver.decode(pkt.payload)
                if (refuse.channelId == this.channelId) {
                    this.logout()
                    this.fireEvent(ARTEvent.Refuse)
                }
                break
            case Command.Notify:
                const notify = PushNotifyReq.decode(pkt.payload)
                this.fireEvent(ARTEvent.Notify, notify)
                break
        }
    }


    private heartbeatLoop() {
        log.debug('heartbeatLoop start')
        let start = Date.now()
        const loop = () => {
            if (this.state != State.CONNECTED) {
                log.debug('heartbeatLoop exited')
                return
            }
            if (Date.now() - start >= heartbeatInterval) {
                log.debug(`>>> send ping ; state is ${this.state}`)
                start = Date.now()
                this.send(Ping)
            }
            setTimeout(loop, 500)
        }
        setTimeout(loop, 500)
    }



    private readDeadlineLoop() {
        log.debug('deadlineLoop start')
        const loop = () => {
            if (this.state != State.CONNECTED) {
                log.debug('deadlineLoop exited')
                return
            }
            if ((Date.now() - this.lastRead) > 3 * heartbeatInterval) {
                this.errorHandler(new Error('read timeout'))
            }
            setTimeout(loop, 500)
        }
        setTimeout(loop, 500)
    }

    private async loadOfflineMessage(token: string, projectUuid: string) {
        const loadIndex = async (id: number, token: string, projectUuid: string): Promise<{ status: number, messages?: MessageBody[] }> => {
            const req = PullMessageReq.encode({ id, token, projectUuid })
            const pkt = LogicPkt.build(Command.OfflineIndex, req.finish())
            const resp = await this.request(pkt)
            if (resp.status != Status.Success) {
                log.debug('No new message', resp)
                return { status: resp.status }
            }
            const respbody = PullMessageResp.decode(resp.payload)
            return { status: resp.status, messages: respbody.messages }
        }
        let offmessages = new Array<MessageBody>()
        let messageId = await Store.lastId()
        while (true) {
            const { status, messages } = await loadIndex(messageId.toNumber(), token, projectUuid)
            if (status != Status.Success) {
                break
            }
            if (!messages || !messages.length) {
                break
            }
            messageId = messages[messages.length - 1].id
            offmessages = offmessages.concat(messages)
        }
        log.info(`load offline indexes - ${offmessages.map((msg) => msg.messageId.toString())}`)
        this.offmessageCallback(offmessages)
    }


    private onclose(reason: string) {
        if (this.state == State.CLOSED) {
            return
        }
        this.state = State.CLOSED

        log.info('connection closed due to ' + reason)
        this.conn = undefined
        this.channelId = ''
        this.fireEvent(ARTEvent.Closed)
        if (this.closeCallback) {
            this.closeCallback()
        }
    }








    private async errorHandler(error: Error) {
        if (this.state == State.CLOSED || this.state == State.CLOSEING) {
            return
        }
        this.state = State.RECONNECTING
        this.fireEvent(ARTEvent.Reconnecting)

        for (; this.retryLogin > 0;) {
            this.retryLogin -= 1
            this.maxRetryLogin -= 1

            if (this.maxRetryLogin <= 0) {
                this.logout()
                return
            }

            await sleep(3)
            try {
                const { success, err } = await this.login(this.req)
                if (success) {
                    this.retryLogin = retryLogin
                    this.fireEvent(ARTEvent.Reconnected)
                    return
                }
                log.info(err)
            } catch (error) {
                log.warn(error)
            }
        }
        this.onclose('reconnect timeout')
    }

    private send(data: Buffer | Uint8Array): boolean {
        try {
            if (this.conn == null) {
                return false
            }
            this.conn.send(data)
        } catch (error) {

            this.errorHandler(new Error('write timeout'))
            return false
        }
        return true
    }

    private doLogin = async (url: string, req: LoginBody): Promise<{ success: boolean, err?: Error, channelId?: string, conn: w3cwebsocket }> => {
        return new Promise((resolve, _) => {
            const conn = new w3cwebsocket(url)
            conn.binaryType = 'arraybuffer'

            const tr = setTimeout(() => {
                clearTimeout(tr)
                resolve({ success: false, err: new Error('timeout'), conn })
            }, loginTimeout)

            conn.onopen = () => {
                if (conn.readyState == w3cwebsocket.OPEN) {
                    log.info(`connection established, send ${req.token}`)

                    const pbreq = LoginReq.encode(LoginReq.fromJSON(req)).finish()
                    const loginpkt = LogicPkt.build(Command.SignIn, pbreq)
                    const buf = loginpkt.bytes()
                    conn.send(buf)
                }
            }

            conn.onerror = (error: Error) => {
                clearTimeout(tr)
                resolve({ success: false, err: error, conn })
            }

            conn.onclose = () => {
                clearTimeout(tr)
                resolve({ success: false, err: new Error('Server side closed'), conn })
            }

            conn.onmessage = (evt) => {
                if (typeof evt.data === 'string') {
                    log.warn('Received: \'' + evt.data + '\'')
                    return
                }
                clearTimeout(tr)
                const buf = Buffer.from(evt.data)
                const loginResp = LogicPkt.from(buf)
                if (loginResp.status != Status.Success) {
                    const m = LoginResp.decode(loginResp.payload)
                    resolve({ success: false, err: new Error(`response status is ${loginResp.status}, err: ${m.message}`), conn })
                    return
                }
                const resp = LoginResp.decode(loginResp.payload)
                resolve({ success: true, channelId: resp.channelId, conn })
            }
        })
    }
}
class MsgStorage {
    constructor() {
        localforage.config({
            name: 'ART',
            storeName: 'ART'
        })
    }

    public async insert(msg: MessageBody): Promise<boolean> {
        await localforage.setItem(this.keymsg(msg.id), msg)
        return true
    }

    public async exist(id: Long): Promise<boolean> {
        try {
            const val = await localforage.getItem(this.keymsg(id))
            return !!val
        } catch (err) {
            log.warn(err)
        }
        return false
    }
    public async get(id: Long): Promise<MessageBody | null> {
        try {
            const message = await localforage.getItem(this.keymsg(id))
            return message as MessageBody
        } catch (err) {
            log.warn(err)
        }
        return null
    }
    public async setAck(id: Long): Promise<boolean> {
        await localforage.setItem(this.keylast(), id)
        return true
    }
    public async lastId(): Promise<Long> {
        const id = await localforage.getItem(this.keylast())
        return (id as Long) || Long.ZERO
    }
    private keymsg(id: Long): string {
        return `msg_${id.toString()}`
    }
    private keylast(): string {
        return `last_id`
    }
}

export let Store = new MsgStorage()
