












import { defineComponent, inject, ref } from '@vue/composition-api'
import { EntityType } from '@/api/project/model'
import Editor from '@/components/Editor.vue'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
export default defineComponent({
    components: {
        Editor,
        TimeCustomSelect
    },
    setup(props, { emit }) {
        const itemObj: any = inject('itemObj')
        return {
            itemObj,
            EntityType
        }
    }
})
