


































import { EntityType } from '@/api/project/model'
import { computed, defineComponent, inject, ref, reactive,watch } from '@vue/composition-api'
import { Checkbox } from 'element-ui'
import { useSettingStore } from '@/pinia/modules/setting'
import { LocaleType } from '@/interface/common'

export default defineComponent({
    components: {
        [Checkbox.name]: Checkbox
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const settingStore = useSettingStore()
        const itemObj: any = inject('itemObj')
        const detailObj: any = inject('detailObj')
        const randomized = computed(() => ![EntityType.ask_opinion, EntityType.onboarding_ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type))
        const otherAndNone = computed(() => ![EntityType.ask_opinion, EntityType.onboarding_ask_opinion, EntityType.branch, EntityType.rank].includes(itemObj.message_entity.entity_type))
        const enableVoting = computed(() => [EntityType.ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type))
        const enableAI = computed(() =>
            //[EntityType.ask_opinion,EntityType.onboarding_ask_opinion].includes(itemObj.message_entity.entity_type)
            [EntityType.onboarding_ask_opinion].includes(itemObj.message_entity.entity_type)
        )
        const disableAI = computed(() =>
            props.disabled || ![LocaleType.en_US, LocaleType.zh_CN, LocaleType.zh_TW].includes(detailObj.language)
        )
        const minmaxEnable = computed(() =>
            [EntityType.poll_multi,EntityType.picture_poll_multi,EntityType.onboarding_poll_picture_multi,EntityType.onboarding_poll_multi].includes(itemObj.message_entity.entity_type)
        )
        const handleChange = () => {
            emit('change')
        }
        
        const minlimitmax = computed(() => {
            if(itemObj.message_entity.settings[0].options.option_max_enabled){
                return itemObj.message_entity.settings[0].options.option_max_limit
            }
            if(itemObj.message_entity.settings[0].options.none){
                return itemObj.message_entity.options.length - 1
            }
            return itemObj.message_entity.options.length
            
        })
        const maxlimitmin = computed(() => {
            if(itemObj.message_entity.settings[0].options.option_min_enabled){
                return itemObj.message_entity.settings[0].options.option_min_limit
            }else{
                return 1
            }
        })
        const maxlimitmax = computed(() => {
            if(itemObj.message_entity.settings[0].options.none){
                return itemObj.message_entity.options.length - 1
            }
            return itemObj.message_entity.options.length
        })
        return {
            itemObj,
            randomized,
            otherAndNone,
            enableVoting,
            enableAI,
            handleChange,
            disableAI,
            minmaxEnable,
            minlimitmax,
            maxlimitmin,
            maxlimitmax
        }
    }
})
