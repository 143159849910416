













































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi } from '@/api/project'
import { useProjectStore } from '@/pinia/modules/project'
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch, watchEffect } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { isEqual } from 'lodash'
import Filters from './components/Filters.vue'
export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        Filters
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object
        }
    },
    setup(props, { root, emit }) {
        const showFilters = ref(false)
        const showDrawer = ref(props.value)
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )
        const onClosed = () => {
            emit('input', showDrawer.value)
        }


        // watch(showFilters, (newVal, oldVal) => {
        //     if (!showFilters.value) {
        //         analyzeObj.answerSegment = analyzeObj.overallSegment
        //     }
        // })


        watch(
            () => {
                return props.item
            },
            (value, old) => {
                analyzeObj.loading = !isEqual(value, old)
                console.log(`setCollection`)

                setCollection(analyzeObj.answerSegment)
            }
        )

        const segments: any = inject('segments')
        // console.log(segments)
        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        const projectStore = useProjectStore()
        const { getAnswerSegment, updateAnswerSegment, getAnswerFilterSegment, updateAnswerFilterSegment } = projectStore

        const analyzeObj: any = reactive({
            loading: true,
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                collection: props.item?.collection
            },
            answerSegment: {},
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            getAnswers() {
                let opinions = []
                if (analyzeObj.answerSegment.collection) {
                    opinions = analyzeObj.answerSegment.collection.result.opinions
                }
                return opinions
            },
            getParticipants: () => {
                if (!analyzeObj.answerSegment.collection) {
                    return 0
                }
                return analyzeObj.answerSegment.collection.result.participants
            },
            answerFilterSegment: {
                segment: {
                    id: -1,
                    selected: [] as any[],
                    name: '--',
                    collection: null
                },
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    analyzeObj.answerFilterSegment.segment.selected = options
                    analyzeObj.answerFilterSegment.segment.name = analyzeObj.answerFilterSegment.getName(options)
                    await setCollection(analyzeObj.answerFilterSegment.segment)
                },
                reset: async () => {
                    analyzeObj.answerFilterSegment.segment.selected = []
                    analyzeObj.answerFilterSegment.segment.name = '--'
                    setCollection(analyzeObj.answerFilterSegment.segment)
                }
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            }
        })

        onMounted(async () => {
            console.log(`onMounted`)
            const answerSegment = getAnswerSegment(props.item?.message.project_uuid)
            analyzeObj.answerSegment = answerSegment ?? analyzeObj.overallSegment

            analyzeObj.answerFilterSegment.segment = getAnswerFilterSegment(props.item?.message.project_uuid) ?? {
                id: -1,
                selected: [] as any[],
                name: '--',
                collection: null
            }
            console.log(analyzeObj.answerFilterSegment.segment)

            setCollection(analyzeObj.answerSegment)
        })

        onUnmounted(() => {
            updateAnswerSegment(props.item?.message.project_uuid, analyzeObj.answerSegment)
            updateAnswerFilterSegment(props.item?.message.project_uuid, analyzeObj.answerFilterSegment.segment)
        })

        const setCollection = async (item: any) => {
            if (item.id == 0) {
                item.collection = props.item?.collection
            } else if (item.id > 0) {
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: item.id,
                    driver: driver.value,
                    display: 'none',
                    observer: observer
                })
                if (res.length > 0) {
                    item.collection = res[0].collection
                }
            } else if (item.id < 0) {
                if (item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res: any = await getMessageOptionsAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        mid: props.item?.message.message_identifier,
                        options: optionIds,
                        display: 'none',
                        driver: driver.value,
                        observer: observer
                    })
                    item.collection = res[0].collection
                } else {
                    item.collection = props.item?.collection
                }
            }
        }


        const handleSegmentChange = async (segment: any) => {
            if (segment.id == -1) {
                showFilters.value = true
                console.log(`handleSegmentChange`)
                // console.log(segment)
            } else {
                showFilters.value = false
            }
            analyzeObj.answerSegment = segment
            setCollection(analyzeObj.answerSegment)
        }

        const copy_text = ()=>{
            let opinions = analyzeObj.getAnswers()
            let texts = []
            for(let i in opinions){
                texts.push(opinions[i]);
            }
            root.$doCopy(texts.map((i:any)=>'"' + i.text.replace('"','\"') + '"').join('\n'))
        }

        return {
            showFilters,
            showDrawer,
            analyzeObj,
            handleSegmentChange,
            onClosed,
            copy_text
        }
    }
})
