















import { computed, defineComponent, inject } from '@vue/composition-api'
import { ConversationStatusType, EntityType, ProjectFormType } from '@/api/project/model'
import { allEntityTypeItems } from '@/api/project/entity-type-option'
import { timeFormatLocaleStr } from '@/utils/time-format'
export default defineComponent({
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')

        const messageInfo = computed(() => {
            const { entity_type,duration } = itemInfo.message_entity
            const entityTypeOption = allEntityTypeItems.find((v) => v.value == entity_type)
            return {
                name: entityTypeOption?.label,
                icon: entityTypeOption?.icon,
                duration: timeFormatLocaleStr(duration)
            }
        })

        return {
            ConversationStatusType,
            itemInfo,
            EntityType,
            ProjectFormType,
            messageInfo
        }
    }
})
