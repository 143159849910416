

































































































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  provide,
  reactive,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  ProjectStatus,
} from '@/api/project/model'
import { getAnalysisImageTags } from '@/api/project'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import CalculateTime from "@/components/CalculateTime.vue";
import AnalyzeUploadImage from "@/components/form-common-analyze/AnalyzeUploadImage.vue";
import VideoDialog from "@/components/VideoDialog.vue";
import WordCloud from "@/components/WordCloudImage.vue";
import { AnalyzeTab, wordCloudTags, WordCloudType, wordColors } from "@/utils/word-cloud";
import { isNil, cloneDeep, isEqual } from "lodash";
import { EntityType } from "../../api/project/model";
import { getHighlightText, showError } from "@/utils/common";

export default defineComponent({
  components: { VideoDialog, CalculateTime, WordCloud, AnalyzeUploadImage },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {
    const head = inject("head");
    const messageItem: any = props.item;
    provide("messageItem", messageItem);
    const observerInfo: any = inject("observerInfo", { isObserver: false });

    const projectStore = useProjectStore()
    const { projectInfo } = storeToRefs(projectStore)
    const wordCloudRef: Ref<any> = ref(null);
    const wordCloudInfo = reactive({
      isLoading: computed(() => {
        return props.item?.word_cloud?.loading;
      }),
      list: [] as any[],
      shape: computed(() => {
        return props.item?.message?.nlp_settings?.shape ?? "circle";
      }),
      colors: computed(() => {
        const color = props.item?.message?.nlp_settings?.color;
        return wordColors.find((item) => item.color === color)?.list ?? ["#000000"];
      }),
      currentTag: WordCloudType.Keywords,
      getTagClass: (tag: string) => {
        return tag === wordCloudInfo.currentTag ? "active" : "";
      },

      tagClick: (tag: any) => {
        wordCloudInfo.currentTag = tag.value;
      },
      keywordTags: [] as any[],
      nlpTags: [] as any[],
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
        if (props.item?.collection) {
          let ops: any[] = Object.values(props.item.collection.result?.opinions) ?? [];
          const retrieveMap = wordCloudInfo.imageTags ? wordCloudInfo.imageTags : []
          if (retrieveMap) {
            const tags: any[] = wordCloudInfo.getWordTags();
            const idSet = new Set();
            if (tags.length > 0) {
              const keys = tags.map((tag) => tag.name);
              keys.forEach((key) => {
                retrieveMap.filter((imtags: any) => {
                  if (imtags.image_tags && imtags.image_tags.includes(key)) {
                    idSet.add(Number(imtags.reply_id))
                  }
                })
              });
              ops = ops.filter((op) => idSet.has(op.reply_id));
            }
          }
          return ops;
        }
        return [];
      }),
      imageTags: [] as any[],
      imageFinished: computed(() => {
        if (props.item?.collection?.ai_analysis_result?.image_tags?.length > 0) {
          return  props.item?.collection?.ai_analysis_result?.image_tags.filter((imtags: any) => {
                return imtags.status=='done'
            }).length
        }else{
          return 0
        }
      }),
      imageError: computed(() => {
        if (props.item?.collection?.ai_analysis_result?.image_tags?.length > 0) {
          return  props.item?.collection?.ai_analysis_result?.image_tags.filter((imtags: any) => {
                return imtags.status=='error'
            }).length
        }else{
          return 0
        }
      }),
      imageAnalyzing:computed(() => {
        if (props.item?.collection?.ai_analysis_result?.image_tags) {
          const tags = props.item.collection.ai_analysis_result.image_tags;
          const analyzingCount = tags.filter((imtag:any )=> imtag.status != 'done'&&imtag.status != 'error').length;
          return analyzingCount === tags.length;
        } else {
          return false;
        }
      }),
      isEmpty:computed(() => {
        if(!props.item?.collection?.ai_analysis_result||Object.keys(props.item?.collection?.ai_analysis_result).length === 0)  return true;
        if(props.item?.collection?.ai_analysis_result?.image_tags.length>0) {
          return false;
        } else {
          return true;
        }
      }),
      wordList: computed(() => {
        const wordCloud = props.item?.collection?.ai_analysis_result ?? {};
        let tagList: any[] = []
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud.image_tags;
        if (!result) {
          return [];
        }

        result.map((key: any) => {
          if (key.image_tags && key.image_tags.length > 0) {
            tagList.push(...key.image_tags)
          }
        });
        // 创建一个对象来存储每个标签的出现次数
        const tagCounts: {
          [tag: string]: number;
        } = {};

        // 遍历数组并统计每个标签的出现次数
        tagList.forEach((tag: any) => {
          if (tagCounts[tag]) {
            tagCounts[tag]++;
          } else {
            tagCounts[tag] = 1;
          }
        });
        // 将对象转换为所需的数组格式
        const arr = Object.keys(tagCounts).map(key => ({
          name: key,
          value: tagCounts[key]
        }));
        return arr;
      }),
      downloadWord: () => wordCloudRef.value.downloadWord(),
      refreshWord: () => wordCloudRef.value.initViewData(),
      clickWord: (params: any) => {
        const { data } = params;
        const tags: any[] = wordCloudInfo.getWordTags();
        if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
          return;
        }
        wordCloudInfo.getWordTags().push(data);
      },
      clearAllWork: () => {
        const tags = wordCloudInfo.getWordTags();
        tags.splice(0, tags.length);
      },
      clearWork: (tag: any, i: number) => {
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.splice(i, 1);
      },
      getWordTags() {
        return wordCloudInfo.currentTag == WordCloudType.Keywords
          ? wordCloudInfo.keywordTags
          : wordCloudInfo.nlpTags;
      },
    });
    const drawerInfo = reactive({
      isAnalyze: false,
      showDrawer: false,
      onOpen: () => {
        drawerInfo.isAnalyze = true;
        nextTick(() => {
          drawerInfo.showDrawer = true;
        });
      },
      onClose: () => {
        drawerInfo.showDrawer = false;
        nextTick(() => {
          drawerInfo.isAnalyze = false;
        });
      },
    });

    const talkMngObj = reactive({
      type: computed(() => messageItem.message.message_type),
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
        if (props.item?.collection) {
          const ops: any[] = Object.values(props.item.collection.result?.opinions) ?? [];
          if (ops.length > 1) {
            ops.sort((a, b) => {
              return a.reply_id > b.reply_id ? 1 : -1;
            });
          }
          return ops;
        }
        return [];
      }),
    });

    const roleMng: any = reactive({
      showConfig: computed(() => {
        return !observerInfo.isObserver;
      }),
      allowShowWordCloud: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return (
            (observerInfo.shareInfo?.nlp && wordCloud.nlp_settings.nlp_enable) ||
            (observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable)
          );
        } else {
          return (
            wordCloud.nlp_settings.keywords_enable || wordCloud.nlp_settings.nlp_enable
          );
        }
      }),
      allowShowAiAnalyse: computed(() => {
        const aiAnalyse = props.item?.message.ai_settings;
        if (isNil(aiAnalyse)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable;
        } else {
          return aiAnalyse.ai_enable;
        }
       
      }),
      allowShow: (type: WordCloudType) => {
        const item = type === WordCloudType.Keywords ? "keyword" : "nlp";
        const res = observerInfo.shareInfo?.[item];
        return !observerInfo.isObserver || res;
      },
      isAllowRefresh: () => {
        if (props.item?.collection) {
          const statuses = ['done', 'error'];
          const incompleteStatuses = ['ready', 'waiting', 'analyzing'];
          const hasIncomplete = wordCloudInfo.imageTags.some((item: any) => incompleteStatuses.includes(item.status));
          if (hasIncomplete) return false;
          return wordCloudInfo.imageTags.every((item: any) => statuses.includes(item.status));
        }
        return false;
      },
      allowRefresh: false
    });

    const contentMngInfo = reactive({
      contentType: AnalyzeTab.SupportRate,
    });

    const liveObj: any = inject("liveObj");
    const time = computed(() => {
      let remain = 0;
      if (
        liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
      ) {
        remain = liveObj?.timeInfo?.timeRemain;
      }
      return remain;
    });

    const videoDialogRef = ref();
    const showVideoDialog = (url: string) => {
      if (url) {
        videoDialogRef.value.open(url);
      }
    };

    const onShowWordCloudDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowWordCloudDialog");

    if (!onShowWordCloudDialog) {
      throw new Error("onShowWordCloudDialog is not provided");
    }
    const onShowAIAnalyzeDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowAIAnalyzeDialog");

    if (!onShowAIAnalyzeDialog) {
      throw new Error("onShowAIAnalyzeDialog is not provided");
    }

    const refreshAnalysis = async () => {
      try {
        const result = await getAnalysisImageTags({ id: props.item?.collection.message_id })
        roleMng.allowRefresh = false
      } catch (error) {
        showError(error)
      }
    }

    watch(
      () => roleMng.allowShowWordCloud,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );
    watch(
      () => roleMng.allowShowAiAnalyse,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );

    watch(
      () => wordCloudInfo.wordList,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old)
        if (isChange) {
          wordCloudInfo.list = cloneDeep(val)
        }
      },
      { deep: true }
    );
    watch(
      () => props.item?.collection,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old)
        if (isChange) {
          wordCloudInfo.imageTags = props.item?.collection.ai_analysis_result?.
            image_tags ?? [];
          roleMng.allowRefresh = roleMng.isAllowRefresh()
        }
      },
      { deep: true }
    );


    onMounted(() => {
      wordCloudInfo.list = cloneDeep(wordCloudInfo.wordList);
      wordCloudInfo.imageTags = props.item?.collection?.ai_analysis_result?.
        image_tags ?? [];
      roleMng.allowRefresh = roleMng.isAllowRefresh()
    })

    return {
      head,
      talkMngObj,
      time,
      drawerInfo,
      videoDialogRef,
      showVideoDialog,
      EntityType,
      AnalyzeTab,
      roleMng,
      observerInfo,
      wordCloudRef,
      contentMngInfo,
      wordCloudInfo,
      onShowWordCloudDialog,
      onShowAIAnalyzeDialog,
      getHighlightText,
      projectInfo,
      ProjectStatus,
      refreshAnalysis
    };
  },
});
