



























import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import { ConversationStatusType, EntityType, ProjectFormType } from '@/api/project/model'
import { allEntityTypeItems } from '@/api/project/entity-type-option'
import TextCheckbox from './components/TextCheckbox.vue'
import { timeFormatLocaleStr } from '@/utils/time-format'
import TestGroupStatus from './components/TestGroupStatus.vue'
export default defineComponent({
    components: {
        TextCheckbox,
        TestGroupStatus
    },
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')
        const isCollapse = ref(false)
        const messageInfo = computed(() => {
            const { entity_type, duration } = itemInfo.message_entity
            const entityTypeOption = allEntityTypeItems.find((v) => v.value == entity_type)
            return {
                name: entityTypeOption?.label,
                icon: entityTypeOption?.icon,
                duration: timeFormatLocaleStr(duration)
            }
        })
        return {
            ConversationStatusType,
            EntityType,
            ProjectFormType,
            messageInfo,
            isCollapse,
            itemInfo
        }
    }
})
