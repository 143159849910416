














































import { computed, defineComponent, inject, reactive, ref, set } from '@vue/composition-api'
import { ConversationStatusType, EntityType, ProjectFormType } from '@/api/project/model'
import { allEntityTypeItems } from '@/api/project/entity-type-option'
import { timeFormatLocaleStr } from '@/utils/time-format'
import TestGroupStatus from './components/TestGroupStatus.vue'
export default defineComponent({
    components: {TestGroupStatus},
    setup(props, { emit }) {
        const itemInfo: any = inject('itemInfo')
        const isCollapse = ref(true)
        const messageInfo = computed(() => {
            const { entity_type, parent_message_entity, duration } = itemInfo.message_entity
            const entityTypeOption = allEntityTypeItems.find((v) => v.value == entity_type)
            return {
                parentContent: parent_message_entity?.content ?? '',
                name: entityTypeOption?.label,
                icon: entityTypeOption?.icon,
                duration: timeFormatLocaleStr(duration)
            }
        })
        return {
            ConversationStatusType,
            EntityType,
            ProjectFormType,
            messageInfo,
            isCollapse,
            itemInfo
        }
    }
})
