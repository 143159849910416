




































import { EntityType } from '@/api/project/model'
import { getPercentage } from '@/utils/common'
import { computed, defineComponent, inject, reactive, set, toRef, toRefs } from '@vue/composition-api'
import { stopCoverage } from 'v8'
import CountTo from 'vue-count-to'

export default defineComponent({
    components: { CountTo },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit, root }) {

        const liveObj: any = inject('liveObj')


        const participantInfo = reactive({
            isShow: computed({
                get: () => {
                    return props.value || false
                },
                set: (value) => {
                    emit('input', value)
                }
            }),

            pollList: computed(() => {
                const polls = liveObj.pollList
                    .filter((poll: any) => [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(poll.message.message_type))
                    .map((poll: any) => {
                        const message = poll.message
                        const result = poll?.collection?.result
                        const item = {
                            id: message.id,
                            message_content: message.message_content,
                            options: message.message_options,
                            ops:
                                result?.ops ??
                                message.message_options.map((option: any) => {
                                    return {
                                        id: option.id,
                                        text: option.text
                                    }
                                }),
                            participants: result?.participants ?? 0
                        }
                        return item
                    })
                return polls
            }),

            getPercent: (poll: any, option: any) => {
                if (poll.ops[option.id]) {
                    const nums = poll.ops[option.id].participants
                    return getPercentage(nums, poll.participants)
                } else {
                    return 0
                }
            }
        })


        const collapseMngObj: any = reactive({
            isCollapse: (poll: any) => {
                return collapseMngObj.collapseMap[poll.id] || false
            },
            collapseMap: {},
            handleCollapse: (poll: any) => {
                if (collapseMngObj.collapseMap[poll.id]) {
                    set(collapseMngObj.collapseMap, poll.id, false)
                } else {
                    set(collapseMngObj.collapseMap, poll.id, true)
                }
            }
        })

        return {
            participantInfo,
            collapseMngObj,
            liveObj
        }
    }
})
