





























































































































































































































































































































































































































































































































































































































































































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi, getAnalysisOpenSummaries,setHiddenAnswerApi } from '@/api/project'
import { OrderType } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentageVarietyStyle } from '@/utils/percentage-color'
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch, Ref } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem, Empty } from 'element-ui'
import { isEqual, isNil, cloneDeep } from 'lodash'
import Filters from './components/Filters.vue'
import WordCloud from '@/components/WordCloud.vue'
import { wordCloudTags, WordCloudType, AnalyzeTab, wordColors } from '@/utils/word-cloud'
import { getHighlightText, showError } from '@/utils/common'

export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Empty.name]: Empty,
        Filters,
        WordCloud
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true,
        }
    },
    setup(props, { root, emit }) {
        const showDrawer = ref(props.value)
        const onClosed = () => {
            emit('input', showDrawer.value)
        }

        const liveObj: any = inject("liveObj");
        const time = computed(() => {
            let remain = 0;
            // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
            //     remain = liveObj?.timeInfo?.timeRemain
            // }
            if (
                liveObj?.timeInfo?.id == props.item?.message?.id &&
                liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
            ) {
                return (remain = liveObj?.timeInfo?.timeRemain);
            }
            return remain;
        });
        const showFilters = ref(false)

        const segments: any = inject('segments')
        const driver: any = inject('driver')

        const observerInfo: any = inject('observerInfo', { isObserver: false })

        const projectStore = useProjectStore()
        const { getAnalyzeSegments, updateAnalyzeSegments, getFilterSegments, updateFilterSegments } = projectStore


        const onShowWordCloudDialog = observerInfo.isObserver ? () => { } : inject<(message_entity_id: number) => void>('onShowWordCloudDialog')

        if (!onShowWordCloudDialog) {
            throw new Error('onShowWordCloudDialog is not provided');
        }

        const contentType = ref(AnalyzeTab.Answer)
        const wordCloudRef: Ref<any> = ref(null)


        const roleMng: any = reactive({
            showConfig: computed(() => {
                return !observerInfo.isObserver
            }),
            allowShowWordCloud: computed(() => {
                const wordCloud = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return false
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable
                } else {
                    return wordCloud.nlp_settings.keywords_enable
                }
            }),
            allowShowAiAnalyse: computed(() => {
                const aiAnalyse = props.item?.message.ai_settings;
                if (isNil(aiAnalyse)) {
                    return false;
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable
                } else {
                    return aiAnalyse.ai_enable
                }
            }),
            allowVoting: computed(() => {
                try {
                    return props.item?.message.settings[0].recommend_enable
                } catch (error) {
                    return true
                }
            }),
            allowShow: (type: WordCloudType) => {
                const item = type === WordCloudType.Keywords ? 'keyword' : 'nlp'
                const res = observerInfo.shareInfo?.[item]
                return !observerInfo.isObserver || res
            }
        })

        const supportRateInfo: any = reactive({
            answerSegment: {
                id: 0,
                name: root.$t('all'),
                answers: [] as any[],
                analysis: [] as any[]
            },
            segments: [] as any[],
            orderObj: {
                type: OrderType.None,
                curOrderSegment: {} as any,
                getType: (segment: any) => {
                    if (segment.id !== supportRateInfo.orderObj.curOrderSegment.id) {
                        return OrderType.None
                    }
                    return supportRateInfo.orderObj.type
                },
                clearAllOrder: () => {
                    supportRateInfo.orderObj.type = OrderType.None
                    supportRateInfo.orderObj.refreshOrder()
                },
                changeOrder: (segment: any, orderType?: OrderType) => {
                    if (isNil(segment.id)) {
                        return
                    }

                    if (segment.id != supportRateInfo.orderObj.curOrderSegment.id) {
                        supportRateInfo.orderObj.type = OrderType.None
                        supportRateInfo.orderObj.curOrderSegment = segment

                    }

                    let keys = supportRateInfo.answerSegment.answers.map((answer: any) => answer.reply_id)
                    if (keys.length == 0) {
                        return
                    }

                    if (!orderType) {

                        if (supportRateInfo.orderObj.type == OrderType.None) {
                            orderType = OrderType.DESC
                        } else if (supportRateInfo.orderObj.type == OrderType.DESC) {
                            orderType = OrderType.ASC
                        } else if (supportRateInfo.orderObj.type == OrderType.ASC) {
                            orderType = OrderType.None
                        }
                    }
                    supportRateInfo.orderObj.type = orderType


                    const type = supportRateInfo.orderObj.type
                    const opinions = analyzeObj.getOpinions(segment)
                    switch (type) {
                        case OrderType.None:
                            keys = keys.sort((a: any, b: any) => a - b)
                            break
                        case OrderType.DESC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a]?.agree_rate === opinions[b]?.agree_rate) {
                                    return a - b
                                } else {
                                    return opinions[b]?.agree_rate - opinions[a]?.agree_rate
                                }
                            })
                            break
                        case OrderType.ASC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a].agree_rate === opinions[b].agree_rate) {
                                    return (
                                        supportRateInfo.answerSegment.answers.find((answer: any) => answer.reply_id === a).reply_id -
                                        supportRateInfo.answerSegment.answers.find((answer: any) => answer.reply_id === b).reply_id
                                    )
                                } else {
                                    return opinions[a]?.agree_rate - opinions[b]?.agree_rate
                                }
                            })
                            break
                    }
                    supportRateInfo.answerSegment.answers.sort((a: any, b: any) => {
                        return keys.indexOf(a.reply_id) - keys.indexOf(b.reply_id)
                    })

                },
                refreshOrder: () => {
                    supportRateInfo.orderObj.changeOrder(supportRateInfo.orderObj.curOrderSegment, supportRateInfo.orderObj.type)
                },
                canReset: computed(() => {
                    return supportRateInfo.orderObj.type != OrderType.None
                })
            },

            filterSegment: {
                formIndex: 0,
                segments: [] as any[],
                curFilter: computed(() => {
                    if (supportRateInfo.filterSegment.segments.length == 0) {
                        return null
                    }
                    return supportRateInfo.filterSegment.segments[supportRateInfo.filterSegment.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    supportRateInfo.filterSegment.curFilter.selected = options
                    supportRateInfo.filterSegment.curFilter.name = supportRateInfo.filterSegment.getName(options)
                    await setCollection(supportRateInfo.filterSegment.curFilter)
                    supportRateInfo.orderObj.refreshOrder()
                },
                handleAnswerChange: async (options: any[]) => {
                    supportRateInfo.filterSegment.curFilter.selected = options
                    supportRateInfo.filterSegment.curFilter.name = supportRateInfo.filterSegment.getName(options)
                    await setCollection(supportRateInfo.filterSegment.curFilter, true)
                    supportRateInfo.orderObj.refreshOrder()
                },
                reset: async () => {
                    supportRateInfo.filterSegment.curFilter.selected = []
                    supportRateInfo.filterSegment.curFilter.name = '--'
                    setCollection(supportRateInfo.filterSegment.curFilter)
                    await supportRateInfo.orderObj.clearAllOrder()
                }
            },

            handleSegmentChange: async (segment: any) => {
                supportRateInfo.answerSegment.name = segment.name
                supportRateInfo.answerSegment.id = segment.id
                let segmentAnswers = await supportRateInfo.getSegmentAnswers(segment.id)
                supportRateInfo.answerSegment.answers = segmentAnswers.answers
                supportRateInfo.answerSegment.analysis = segmentAnswers.analysis
                supportRateInfo.orderObj.refreshOrder()
            },
            getSegmentAnswers: async (segmentId: number) => {
                let answers, analysis
                if (segmentId == 0) {
                    answers = props.item?.collection?.result.opinions ? Object.values(props.item.collection?.result.opinions) : []
                    analysis = props.item?.collection?.ai_analysis_result ? [props.item.collection?.ai_analysis_result.open_ended_summary] : []
                    return { answers, analysis }
                }
                if (segmentId == 0) {
                    answers = props.item?.collection?.result.opinions ? Object.values(props.item.collection?.result.opinions) : []
                    analysis = props.item?.collection?.ai_analysis_result?.open_ended_summary ? [props.item.collection?.ai_analysis_result?.open_ended_summary] : []
                    return { answers, analysis }
                }
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: segmentId,
                    display: 'none',
                    driver: driver.value,
                    observer: observerInfo.isObserver,
                    analysis_type: contentType.value
                })
                answers = res[0]?.collection?.result?.opinions ? Object.values(res[0].collection.result.opinions) : []
                analysis = res[0]?.collection?.ai_analysis_result?.open_ended_summary ? [res[0].collection.ai_analysis_result?.open_ended_summary] : []
                return { answers, analysis }
            },


            handleCommand: async (item: any) => {
                if (!item.segment) {
                    const segment = supportRateInfo.filterSegment.segments[item.index]
                    supportRateInfo.segments[item.index] = segment
                    supportRateInfo.filterSegment.formIndex = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                    showFilters.value = true
                    await setCollection(supportRateInfo.segments[item.index])
                } else {
                    showFilters.value = false
                    let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const cloneItem = cloneDeep(segmentItem)
                        await setCollection(cloneItem)
                        item.index = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                        supportRateInfo.segments.splice(item.index, 1, cloneItem)
                    }
                }
            },
            segmentAnswers: [] as any[],
            filterAnswers: computed(() => {
                let answers, analysis: any
                if (showFilters.value) {
                    if (supportRateInfo.filterSegment.curFilter?.collection) {
                        answers = Object.values(supportRateInfo.filterSegment.curFilter.collection.result?.opinions) ?? []
                        analysis = [supportRateInfo.filterSegment.curFilter.collection.ai_analysis_result?.open_ended_summary] ?? []
                        return { answers, analysis }
                    }
                } else {
                    if (supportRateInfo.curSegment?.collection) {
                        answers = Object.values(supportRateInfo.curSegment.collection.result?.opinions) ?? []
                        analysis = [supportRateInfo.curSegment.collection.ai_analysis_result?.open_ended_summary] ?? []
                        return { answers, analysis }
                    }
                }

                return { answers: [], analysis: [] }
            }),
            showAnswers: async (item: any) => {
                console.log(item.segment, 'item.segment')
                if (!item.segment) {
                    const segment = supportRateInfo.filterSegment.segments[item.index]
                    supportRateInfo.segments[item.index] = segment
                    supportRateInfo.filterSegment.formIndex = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                    showFilters.value = true
                    await setCollection(supportRateInfo.filterSegment.curFilter, true)
                } else {
                    showFilters.value = false
                    let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const cloneItem = cloneDeep(segmentItem)
                        item.index = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0
                        supportRateInfo.segments.splice(item.index, 1, cloneItem)
                        supportRateInfo.segmentAnswers[item.index] = await supportRateInfo.getSegmentAnswers(item.segment.id)
                        await setCollection(cloneItem)
                    }
                }
            },
        })


        const wordCloudInfo: any = reactive({
            isLoading: computed(() => {
                return props.item?.word_cloud?.loading
            }),
            list: [] as any[],
            shape: computed(() => {
                return props.item?.word_cloud?.nlp_settings?.shape ?? 'circle'
            }),
            colors: computed(() => {
                const color = props.item?.word_cloud?.nlp_settings?.color
                return wordColors.find((item) => item.color === color)?.list ?? ['#000000']
            }),
            currentTag: WordCloudType.Keywords,
            getTagClass: (tag: string) => {
                return tag === wordCloudInfo.currentTag ? 'active' : ''
            },
            typeTags: computed(() => {
                const tags = [] as any[]

                const wordCloud: any = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return tags
                }

                if (wordCloud.nlp_settings?.keywords_enable && roleMng.allowShow(WordCloudType.Keywords)) {
                    tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Keywords))
                }
                if (wordCloud.nlp_settings?.nlp_enable && roleMng.allowShow(WordCloudType.Nlp)) {

                    tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Nlp))
                }

                if (tags.length > 0 && !tags.find((tag) => tag.value === wordCloudInfo.currentTag)) {
                    wordCloudInfo.currentTag = tags[0].value
                }
                return tags
            }),
            tagClick: (tag: any) => {
                wordCloudInfo.currentTag = tag.value
            },
            keywordTags: [] as any[],
            nlpTags: [] as any[],
            participants: computed(() => {
                if (props.item?.collection) {
                    return props.item.collection.result.participants
                }
                return 0
            }),
            opinions: computed(() => {
                if (showFilters.value) {
                    if (wordCloudInfo.filterSegment.curFilter?.collection) {
                        let ops: any[] = Object.values(wordCloudInfo.filterSegment.curFilter.collection.result?.opinions) ?? []
                        const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                        if (retrieveMap) {
                            const tags: any[] = wordCloudInfo.getWordTags()
                            const idSet = new Set()
                            if (tags.length > 0) {
                                const keys = tags.map((tag) => tag.name)
                                keys.forEach((key) => {
                                    const item = retrieveMap[key]
                                    if (item && item.frequency > 0) {
                                        item.replies.forEach((id: any) => idSet.add(Number(id)))
                                    }
                                })
                                ops = ops.filter((op) => idSet.has(op.reply_id))
                            }
                        }
                        return ops
                    }
                } else {
                    if (wordCloudInfo.curSegment?.collection) {
                        let ops: any[] = Object.values(wordCloudInfo.curSegment.collection.result?.opinions) ?? []
                        const retrieveMap = props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`]
                        if (retrieveMap) {
                            const tags: any[] = wordCloudInfo.getWordTags()
                            const idSet = new Set()
                            if (tags.length > 0) {
                                const keys = tags.map((tag) => tag.name)
                                keys.forEach((key) => {
                                    const item = retrieveMap[key]
                                    if (item && item.frequency > 0) {
                                        item.replies.forEach((id: any) => idSet.add(Number(id)))
                                    }
                                })
                                ops = ops.filter((op) => idSet.has(op.reply_id))
                            }
                        }
                        return ops
                    }
                }

                return []
            }),
            wordList: computed(() => {
                const wordCloud = showFilters.value ? wordCloudInfo.filterSegment.curFilter?.word_cloud : wordCloudInfo.curSegment?.word_cloud
                if (!wordCloud) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`]
                if (!wordCloud || isNil(result)) {
                    return []
                }
                const arr = Object.keys(result).map((key) => {
                    const item = result[key]
                    return {
                        name: key,
                        value: item.frequency
                    }
                })
                return arr
            }),
            downloadWord: () => wordCloudRef.value.downloadWord(),
            refreshWord: () => wordCloudRef.value.initViewData(),
            clickWord: (params: any) => {
                const { data } = params
                const tags: any[] = wordCloudInfo.getWordTags()
                if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
                    return
                }
                wordCloudInfo.getWordTags().push(data)
            },
            clearAllWork: () => {
                const tags = wordCloudInfo.getWordTags()
                tags.splice(0, tags.length)
            },
            clearWork: (tag: any, i: number) => {
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.splice(i, 1)
            },
            getWordTags() {
                return wordCloudInfo.currentTag == WordCloudType.Keywords ? wordCloudInfo.keywordTags : wordCloudInfo.nlpTags
            },
            getHighlightTags: (opinion: any) => {
                const wordCloud = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`]
                if (isNil(result)) {
                    return []
                }
                const hightlightSet = new Set()
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.forEach((tag) => {
                    const item = result[tag.name]
                    if (item.replies.includes(opinion.reply_id)) {
                        item.highlight_tags?.forEach((tag: any) => {
                            hightlightSet.add(tag)
                        })
                    }
                })
                return Array.from(hightlightSet)
            },
            curSegment: {
                id: 0,
                name: root.$t('all'),
                collection: null as any,
                word_cloud: null as any,
                selected: [] as any[]
            },
            filterSegment: {
                curFilter: {
                    id: -1,
                    name: '--',
                    collection: null as any,
                    word_cloud: null as any,
                    selected: [] as any[]
                },
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    wordCloudInfo.filterSegment.curFilter.selected = options
                    wordCloudInfo.filterSegment.curFilter.name = wordCloudInfo.filterSegment.getName(options)
                    await setCollection(wordCloudInfo.filterSegment.curFilter, true)
                },
                reset: async () => {
                    wordCloudInfo.filterSegment.curFilter.selected = []
                    wordCloudInfo.filterSegment.curFilter.name = '--'
                    setCollection(wordCloudInfo.filterSegment.curFilter, true)
                }
            },
            handleCommand: async (item: any) => {
                if (!item.segment) {
                    showFilters.value = true
                    wordCloudInfo.curSegment = wordCloudInfo.filterSegment.curFilter
                    await setCollection(wordCloudInfo.filterSegment.curFilter, true)
                } else {
                    showFilters.value = false
                    let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const item = cloneDeep(segmentItem)
                        await setCollection(item, true)
                        wordCloudInfo.curSegment = item
                    }
                }
                wordCloudInfo.clearAllWork()
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg?.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            }
        })
        wordCloudInfo.list = wordCloudInfo.wordList


        const analyzeObj: any = reactive({
            loading: true,
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                collection: props.item?.collection,
                word_cloud: props.item?.word_cloud
            },
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        collection: null
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),

            getParticipants: (item: any,index: number) => {
                if (!item?.collection) {
                    return 0
                }
                if(index==-1){
                    return  supportRateInfo.filterAnswers.answers.filter((opt: any) => !opt.is_hidden).length
                }else if(index==-2){
                    return  wordCloudInfo.opinions.filter((opt: any) => !opt.is_hidden).length
                }else{
                    return supportRateInfo.segmentAnswers[index]?.answers.filter((opt: any) => !opt.is_hidden).length||0;
                }
                // return item.collection.result.participants
            },

            getOpinions: (segment: any) => {
                if (!segment?.collection) {
                    return {}
                }
                return segment?.collection?.result?.opinions
            },
            getPercentage: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return Math.round(opinion.agree_rate * 100)
            },

            getNumber: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return opinion.agree + opinion.disagree + opinion.lose
            },

            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            }
        })


        const setCollection = async (item: any, hidden = false) => {
            if (item.id == 0) {
                item.collection = props.item?.collection
                item.word_cloud = props.item?.word_cloud
            } else if (item.id > 0) {
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: item.id,
                    driver: driver.value,
                    display: hidden ? 'none' : '',
                    observer: observerInfo.isObserver,
                    analysis_type: contentType.value
                })
                if (res.length > 0) {
                    item.collection = res[0].collection
                    item.word_cloud = res[0].word_cloud
                }
            } else if (item.id < 0) {
                if (item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res: any = await getMessageOptionsAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        mid: props.item?.message.message_identifier,
                        options: optionIds,
                        driver: driver.value,
                        display: hidden ? 'none' : '',
                        observer: observerInfo.isObserver
                    })
                    item.collection = res[0].collection
                    item.word_cloud = res[0].word_cloud
                } else {
                    item.collection = null
                    item.word_cloud = null
                }
            }
        }

        onMounted(async () => {
            initData()
        })

        onUnmounted(() => {
            supportRateInfo.segments.forEach((segment: any) => {
                segment.collection = null
            })
            supportRateInfo.filterSegment.segments.forEach((segment: any) => {
                segment.collection = null
            })
            updateAnalyzeSegments(props.item?.message.project_uuid, supportRateInfo.segments)
            updateFilterSegments(props.item?.message.project_uuid, supportRateInfo.filterSegment.segments)
        })

        const initData = async () => {
            supportRateInfo.answerSegment.answers = props.item?.collection?.result.opinions ? Object.values(props.item?.collection?.result.opinions) : []
            supportRateInfo.answerSegment.analysis = props.item?.collection?.ai_analysis_result?.open_ended_summary ? props.item.collection?.ai_analysis_result?.open_ended_summary : null
            const segs = getAnalyzeSegments(props.item?.message.project_uuid)
            supportRateInfo.segments = segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 3)

            if (supportRateInfo.segments.length < 3) {
                const diff = 3 - supportRateInfo.segments.length
                for (let i = 0; i < diff; i++) {
                    supportRateInfo.segments.push({
                        name: '--',
                        collection: null
                    })
                }
            }


            const filterSegments = getFilterSegments(props.item?.message.project_uuid)
            supportRateInfo.filterSegment.segments = filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : []
            if (supportRateInfo.filterSegment.segments.length < 3) {
                for (let i = 0; i < 3; i++) {
                    supportRateInfo.filterSegment.segments.push({
                        id: -1 - i,
                        name: '--',
                        selected: [],
                        collection: null
                    })
                }
            }

            const ids = segments.value.map((item: any) => item.id) ?? []

            supportRateInfo.segments.forEach((segment: any) => {
                if (segment.id > 0 && !ids.includes(segment.id)) {
                    segment.name = '--'
                    segment.id = null
                    segment.collection = null
                    segment.selected = []
                }
                setCollection(segment)
            })

            supportRateInfo.segmentAnswers = await Promise.all(
                supportRateInfo.segments.map(async (segment: any) => {
                    const answers = await supportRateInfo.getSegmentAnswers(segment.id);
                    return answers;
                })
            );
            setCollection(wordCloudInfo.curSegment, true)
        }

        const copy_text = (segment_index: any) => {
            root.$doCopy(supportRateInfo.segmentAnswers[segment_index].answers.map((i: any) => '"' + i.text.replace('"', '\"') + '"').join('\n'))
        }

        const copyMarkdownSummary = (index: any) => {
            let segmentAnswers = supportRateInfo.segmentAnswers[index].analysis
            let summary = ''
            if (segmentAnswers.length > 0) {
                if (segmentAnswers[0].id == 0) {
                    summary = ''
                }
                if (segmentAnswers[0].status == 'done') {
                    summary = segmentAnswers[0].summary
                }

            }
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = root.$markdownToHtml(summary);

            // 从div中提取纯文本
            const textToCopy = tempDiv.textContent || '';
            root.$doCopy(textToCopy);
            tempDiv.remove();
        };
        const respondents = (token: any) => {
            if (props.item?.respondents) {
                const ops: any[] = props.item.respondents.filter((item: any) => item.token == token)
                if (ops.length > 0) {
                    const seenIds = new Set();
                    let segments = ops[0]
                    let token_segments = segments.token_segments ? segments.token_segments : [];
                    const uniqueData = token_segments.filter((item: any) => {
                        if (!seenIds.has(item.id)) {
                            seenIds.add(item.id);
                            return true;
                        }
                        return false;
                    });
                    segments.uniqueData = uniqueData
                    return segments
                } else {
                    return {
                        link_id: '',
                        uniqueData: []
                    }
                }
            } else {
                return {
                    link_id: '',
                    uniqueData: []
                }
            }
        }
        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )


        let isUpdating = false;

        watch(
            () => props.item,
            async (value, old) => {
                if (isUpdating) {
                    return;
                }

                isUpdating = true;
                try {
                    analyzeObj.loading = !isEqual(value, old);
                    if (showFilters.value) {
                        await setCollection(supportRateInfo.filterSegment);
                    } else {
                        await Promise.all(
                            supportRateInfo.segments.map(async (segment: any, index: any) => {
                                await setCollection(segment);
                                supportRateInfo.segmentAnswers[index] = await supportRateInfo.getSegmentAnswers(segment.id);
                            })
                        );
                        await setCollection(wordCloudInfo.curSegment, true);
                    }
                    await supportRateInfo.handleSegmentChange(supportRateInfo.answerSegment);
                } finally {
                    isUpdating = false;
                }
            }
        );


        watch(
            () => roleMng.allowShowWordCloud,
            (val) => {
                if (!val) {
                    contentType.value = AnalyzeTab.Answer
                }
            }
        )
        watch(
            () => roleMng.allowShowAiAnalyse,
            (val) => {
                if (!val) {
                    contentType.value = AnalyzeTab.Answer
                }
            }
        )

        watch(
            () => wordCloudInfo.wordList,
            (val, old) => {
                const isChange = JSON.stringify(val) !== JSON.stringify(old)
                if (isChange || wordCloudInfo.list.length === 0) {

                    wordCloudInfo.list = val
                }
            },
            { deep: true }
        )

        const refreshAnalysis = async (index: any) => {
            try {
                let segmentAnswers = supportRateInfo.segmentAnswers[index].analysis
                if (segmentAnswers.length > 0) {
                    if (segmentAnswers[0].id == 0) return
                    const result = await getAnalysisOpenSummaries({ id: segmentAnswers[0].id })
                    supportRateInfo.segmentAnswers[index].analysis = [result]
                }
            } catch (error) {
                showError(error)
            }
        }

        const isRefreshAnalysis = (index: any) => {
            let segmentAnswers = supportRateInfo.segmentAnswers[index].analysis
            if (segmentAnswers.length > 0) {
                if (segmentAnswers[0].id == 0) return false
                if (segmentAnswers[0].status == 'done' || segmentAnswers[0].status == 'error') return true
            }
            return false
        }
        const disabledTooltipVisible = ref(false)
        const hiddenAnswer = (token: any) => {
            disabledTooltipVisible.value=true
            root.$msgbox({
                showCancelButton: true,
                dangerouslyUseHTMLString: true,
                showClose: true,
                message: <string>root.$i18n.t("projectLive.hiddenAnswer.message"),
                title: <string>root.$i18n.t("projectLive.hiddenAnswer.title"),
                async beforeClose(action, instance, done) {
                    if (action === 'confirm') {
                        try {
                            instance.confirmButtonLoading = true
                            await setHiddenAnswerApi({
                                uuid: props.item?.message.project_uuid,
                                token: token,
                                hidden: true,
                                messageId:  props.item?.message.message_identifier
                            })
                            root.$message.success(<string>root.$i18n.t("projectLive.hiddenAnswer.successMessage"))
                            done()
                            instance.confirmButtonLoading = false
                        } catch (error) {
                            showError(error)
                            instance.confirmButtonLoading = false
                        }
                    } else {
                        done()
                    }
                    setTimeout(() => {
                      disabledTooltipVisible.value=false
                    }, 500);
                }
            }).catch(() => { })
        }

        return {
            OrderType,
            showDrawer,
            showFilters,
            analyzeObj,
            getPercentageVarietyStyle,
            onClosed,
            wordCloudRef,
            wordCloudInfo,
            contentType,
            onShowWordCloudDialog,
            AnalyzeTab,
            supportRateInfo,
            roleMng,
            getHighlightText,
            copy_text,
            refreshAnalysis,
            isRefreshAnalysis,
            time,
            copyMarkdownSummary,
            respondents,
            hiddenAnswer,
            disabledTooltipVisible
        }
    }
})
