






import { defineComponent, getCurrentInstance, reactive, computed } from "@vue/composition-api";
import TalkBasicPoll from "@/components/form-common-talk/TalkBasicPoll.vue";
import TalkBasicAskOpinion from "@/components/form-common-talk/TalkBasicAskOpinion.vue";
import TalkSpeak from "@/components/form-common-talk/TalkSpeak.vue";
import TalkPoll from "@/components/form-common-talk/TalkPoll.vue";
import TalkImage from "@/components/form-common-talk/TalkImage.vue";
import TalkVideo from "@/components/form-common-talk/TalkVideo.vue";
import TalkRank from "@/components/form-common-talk/TalkRank.vue";
import TalkAskOpinion from "@/components/form-common-talk/TalkAskOpinion.vue";
import TalkAskExperience from "@/components/form-common-talk/TalkAskExperience.vue";
import TalkBranch from "@/components/form-common-talk/TalkBranch.vue";
import TalkUpload from "@/components/form-common-talk/TalkUpload.vue";
import { EntityType, ProjectStatus } from "@/api/project/model";

export default defineComponent({
  name: 'TalkItem',
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    TalkBasicPoll,
    TalkBasicAskOpinion,
    TalkSpeak,
    TalkPoll,
    TalkImage,
    TalkVideo,
    TalkRank,
    TalkAskOpinion,
    TalkAskExperience,
    TalkBranch,
    TalkUpload,
  },
  setup(props: { index?: number; source?: object, list?: any[] }, { emit }: { emit: (event: string, ...args: any[]) => void }) {
    const isType = (type: EntityType): EntityType => {
      const pollTypeList = [
        EntityType.onboarding_poll,
        EntityType.onboarding_poll_multi,
        EntityType.onboarding_poll_picture,
        EntityType.onboarding_poll_picture_multi,
      ];
      const conversationPollTypeList = [
        EntityType.poll,
        EntityType.poll_multi,
        EntityType.picture_poll,
        EntityType.picture_poll_multi,
      ];
      const conversationUploadTypeList = [
        EntityType.poll,
        EntityType.poll_multi,
        EntityType.picture_poll,
        EntityType.picture_poll_multi,
      ];
      if (pollTypeList.includes(type)) {
        return EntityType.onboarding_poll;
      } else if (conversationPollTypeList.includes(type)) {
        return EntityType.poll;
      } else if (conversationUploadTypeList.includes(type)) {
        return EntityType.upload_image;
      } else {
        return type;
      }
    };
    const instance = getCurrentInstance();

    const getComponentToRender = (item: any) => {
      const entityType = isType(item.message.message_type);
      switch (entityType) {
        case EntityType.onboarding_poll:
          return 'TalkBasicPoll';
        case EntityType.onboarding_ask_opinion:
          return 'TalkBasicAskOpinion';
        case EntityType.speak:
          return 'TalkSpeak';
        case EntityType.poll:
          return 'TalkPoll';
        case EntityType.image:
          return 'TalkImage';
        case EntityType.video:
          return 'TalkVideo';
        case EntityType.rank:
          return 'TalkRank';
        case EntityType.ask_opinion:
          return 'TalkAskOpinion';
        case EntityType.ask_experience:
          return 'TalkAskExperience';
        case EntityType.branch:
          return 'TalkBranch';
        case EntityType.upload_image:
        case EntityType.upload_video:
          return 'TalkUpload';
        default:
          return '';
      }
    };

    const createBranch = (itemObj: any) => {
      if (instance && instance.proxy && instance.proxy.$parent) {
        let parent = instance.proxy.$parent;
        while (parent && !parent.$options.name) {
          parent = parent.$parent;
        }
        if (parent) {
          parent.$emit('createBranch', itemObj);
        }
      } else {
        console.error('Parent component not found');
      }
    };
    return {
      EntityType,
      isType,
      createBranch,
      getComponentToRender,
    };
  },
});
