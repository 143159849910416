











import { defineComponent, inject } from "@vue/composition-api";
export default defineComponent({
  components: {},
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {
    const head = inject("head");
    return {
      head,
    };
  },
});
