import { shape1, shape2, shape3, shape4, shape5, shape6, shape7 } from '@/config/word-cloud-other-shape'
import i18n from '@/locale'

export enum AnalyzeTab {
    Answer = 'Answer ',
    SupportRate = 'SupportRate',
    WordCloud = 'WordCloud  ',
    AIAnalyze='AI'
}


export enum WordCloudType {
    Keywords = 'keywords',
    Nlp = 'nlp',
    Branch_Keywords = 'branch_keywords',
    Branch_Nlp = 'branch_nlp',
}

export const wordCloudTags = [
    {
        name: i18n.t('projectSurvey.wordCloudKeywords'),
        value: WordCloudType.Keywords
    },
    {
        name: i18n.t('projectSurvey.wordCloudNLP'),
        value: WordCloudType.Nlp
    },
]

export const branchWordCloudTags = [
    {
        name: i18n.t('projectSurvey.wordCloudKeywords'),
        value: WordCloudType.Branch_Keywords
    },
    {
        name: i18n.t('projectSurvey.wordCloudNLP'),
        value: WordCloudType.Branch_Nlp
    },
]

/**
 * 词云颜色
 */
export const wordColors = [
    {
        color: 'color1',
        list: ['#E3C24C', '#F1D569', '#000000', '#858574']
    },
    {
        color: 'color2',
        list: ['#9D6F06', '#E3C24C', '#AEAE9F', '#D4D4C9']
    },
    {
        color: 'color3',
        list: ['#416BB2', '#3B95D5', '#6AA540', '#B2E263']
    },
    {
        color: 'color4',
        list: ['#FF5000', '#802AB7', '#0060FF', '#FEDB00']
    },
    {
        color: 'color5',
        list: ['#9A7E91', '#A6A5E9', '#FE9B8F', '#FBD2AA']
    }
]

/**
 * 词云形状
 */
export const wordShapes = [
    {
        shape: 'shape1'
    },
    {
        shape: 'shape2'
    },
    {
        shape: 'shape3'
    },
    {
        shape: 'shape4'
    },
    {
        shape: 'shape5'
    },
]

export const otherShapeMap: any = {
    shape1,
    shape2,
    shape3,
    shape4,
    shape5,
    shape6,
    shape7,
}
