












import { computed, defineComponent,nextTick, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    item: Object,
  },
  setup(props) {
    const conceptConetent = ref(null);
    const iserflow = ref(true);
    const isOverflow = () => {
      if (!conceptConetent.value) return false;
      const outerWidth = getWidth(conceptConetent.value);
      const textWidth = scrollWidth(conceptConetent.value);
      return outerWidth < textWidth;
    };

    const getWidth = (el: any) => {
      const { width } = el.getBoundingClientRect();
      return width || 0;
    };

    const scrollWidth = (el: any) => {
      const width = el.scrollWidth;
      return width || 0;
    };  

    const checkOverflowAndHandle = () => {
      iserflow.value=!isOverflow()
    };


    return {
      conceptConetent,
      checkOverflowAndHandle,
      iserflow
    };
  },
});
