
















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    props: {
        modal: {
            type: Boolean,
            default: true
        },
    },
    setup() {
        const show = ref(false)
        const videoUrl = ref('')
        const videoRef = ref()
        const open = (url: string) => {
            videoUrl.value = url
            show.value = true
        }
        const closed = () => {
            videoUrl.value = ''
        }
        return {
            show,
            open,
            closed,
            videoRef,
            videoUrl
        }
    }
})
