import { render, staticRenderFns } from "./TimeCustomSelect.vue?vue&type=template&id=ed2b2328&scoped=true&"
import script from "./TimeCustomSelect.vue?vue&type=script&lang=ts&"
export * from "./TimeCustomSelect.vue?vue&type=script&lang=ts&"
import style0 from "./TimeCustomSelect.vue?vue&type=style&index=0&id=ed2b2328&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed2b2328",
  null
  
)

export default component.exports