var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkbox-container"},[_c('div',{staticClass:"header-box"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"left-name",on:{"click":function($event){_vm.isCollapse = !_vm.isCollapse}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapse),expression:"!isCollapse"}],staticClass:"ri-arrow-down-s-fill"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapse),expression:"isCollapse"}],staticClass:"ri-arrow-up-s-fill"}),_c('span',[_vm._v(_vm._s(_vm.option? _vm.$t('projectSurvey.options') : _vm.$t('projectSurvey.options1'))+"（"+_vm._s(_vm.itemObj.message_entity.options.length)+"）")])]),(_vm.itemObj.message_entity.settings[0].options.randomized)?_c('i',{staticClass:"ri-shuffle-line"}):_vm._e()])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapse),expression:"isCollapse"}],staticClass:"option-box"},[_c('li',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.videoUploadInfo.loading),expression:"videoUploadInfo.loading"}],staticClass:"option-image"},[_vm._l((_vm.optionObj.videos),function(item,index){return _c('div',{key:index,staticClass:"image-row"},[_c('video',{attrs:{"id":'videoRef-'+item.id,"src":item.link,"controls":""},on:{"canplay":function($event){return _vm.onVideoCanplay(item.id)}}}),_c('el-input',{attrs:{"id":("optionInputRef" + (item.link))},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}}),_c('div',{staticClass:"operate"},[_c('el-upload',{attrs:{"action":"","data":{
                            type: 'edit',
                            index: index
                        },"accept":_vm.videoTypes.toString(),"show-file-list":false,"http-request":_vm.videoUploadInfo.request,"before-upload":_vm.videoUploadInfo.before}},[_c('i',{staticClass:"ri-image-edit-fill"})]),(_vm.itemObj.message_entity.options.length < _vm.upLoadNumber)?_c('el-upload',{attrs:{"action":"","data":{
                            type: 'add',
                            index: index
                        },"accept":_vm.videoTypes.toString(),"show-file-list":false,"http-request":_vm.videoUploadInfo.request,"before-upload":_vm.videoUploadInfo.before}},[_c('i',{staticClass:"ri-add-circle-fill"})]):_vm._e(),_c('i',{staticClass:"ri-indeterminate-circle-fill",on:{"click":function($event){return _vm.onDeleteOption(item,index)}}})],1)],1)}),(_vm.itemObj.message_entity.options.length < _vm.upLoadNumber)?_c('div',{staticClass:"image-add"},[_c('el-upload',{attrs:{"action":"","data":{
                        type: 'add',
                        index: -1
                    },"accept":_vm.videoTypes.toString(),"show-file-list":false,"http-request":_vm.videoUploadInfo.request,"before-upload":_vm.videoUploadInfo.before}},[_c('div',{staticClass:"add-box"},[_c('i',{staticClass:"ri-add-circle-fill"}),_c('span',[_vm._v(_vm._s(_vm.$t('projectSurvey.uploadVideoTip')))])])])],1):_vm._e()],2),_vm._l((_vm.optionObj.systemOptions),function(item,index){return _c('li',{key:'text-'+index,staticClass:"other-row"},[_c('div',[_vm._v(_vm._s(item.text))])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }